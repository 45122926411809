import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import Api from "../../../../../../../../api/Api";
import { ChangePasswordDto } from "../../../../../../../../api/auth/auth.dto";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { ToastsStore } from "react-toasts";

interface SignInFormProps { }
interface FormDto extends ChangePasswordDto {
  repeatPassword: string;
}

const ResetPasswordForm: React.FC<SignInFormProps> = (props) => {
  const { call, status } = useQuery<void, ChangePasswordDto>('auth/change-password', [Api.Auth.ChangePassword], { callOnLoad: false });
  const { register, watch, handleSubmit, formState: { errors }, reset } = useForm<FormDto>();
  const watch_password = watch("newPassword");

  // const { refetch } = useQueryRefetch();
  // refetch('test');

  const onSubmit = async (dto: FormDto) => {
    call(dto);
  };

  useEffect(() => {
    if (status === 'finished') {
      reset();
      ToastsStore.success("Hasło zostało zmienione");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])


  return (
    <Form onSubmit={handleSubmit(onSubmit)}  cols={3}>
      <TextInput
        id="oldPassword"
        label="Stare hasło"
        validation_msg={errors?.oldPassword?.message}
        inputProps={{
          ...register("oldPassword", Validators.required),
          type: "password",
        }}
      />
      <TextInput
        id="newPassword"
        label="Nowa hasło"
        validation_msg={errors?.newPassword?.message}
        inputProps={{
          ...register("newPassword", Validators.password),
          type: "password",
        }}
      />
      <TextInput
        id="repeatPassword"
        label="Powtórz hasło"
        validation_msg={errors?.repeatPassword?.message}
        inputProps={{
          ...register("repeatPassword", Validators.repeatPassword(watch_password)),
          type: "password",
        }}
      />
      <br />
      <LoadingBtn type="submit" status={status} className="ml-auto">Zmień hasło</LoadingBtn>
    </Form>
  );
};

export default ResetPasswordForm;
