import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ResetPasswordDto } from "../../../../../api/auth/auth.dto";
import LoadingBtn from "../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../libs/forms/validators/validators";
import { useResetPassword } from "./ResetPasswordDataSlice";

interface SignInFormProps { }
interface FormDto {
  password: string,
  repeatPassword: string;
}

const ResetPasswordForm: React.FC<SignInFormProps> = (props) => {
  const { tempPassword } = useParams<{ tempPassword: string }>();

  const { resetPassword, resetPassword_status } = useResetPassword();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormDto>();

  const watch_password = watch("password");

  const onSubmit = (data: FormDto) => {
    resetPassword({ tempPassword: tempPassword, newPassword: data.repeatPassword } as ResetPasswordDto);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        id="password"
        label="Nowa hasło"
        validation_msg={errors?.password?.message}
        inputProps={{
          ...register("password", Validators.password),
          type: "password",
        }}
      />
      <TextInput
        id="repeatPassword"
        label="Powtórz hasło"
        validation_msg={errors?.repeatPassword?.message}
        inputProps={{
          ...register("repeatPassword", Validators.repeatPassword(watch_password)),
          type: "password",
        }}
      />
      <LoadingBtn type="submit" status={resetPassword_status}>Resetuj</LoadingBtn>
    </Form>
  );
};

export default ResetPasswordForm;
