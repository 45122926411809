/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ToastsStore } from "react-toasts";
import Api from "../../../../../../../../api/Api";
import { OfficeDto, PromotionDto, PromotionValueType_Enum, PromotionValueType_Enum_Parser } from "../../../../../../../../api/office/office.dto";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import DateHelper from "../../../../../../../../features/date-helper";
import { mapEnum } from "../../../../../../../../features/enumMap";
import LoadingBtn from "../../../../../../../../libs/buttons/Button/LoadingBtn";
import InputBuilder, { AdvancedInputField } from "../../../../../../../../libs/filters/InputBuilder";
import CheckBoxInput from "../../../../../../../../libs/forms/CheckBox/CheckBox";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../libs/forms/validators/validators";

import "./project-promotion-edit-form.scss";
import { SafeProjectDto } from "../../../../../../../../api/project/project.dto";
import Col from "../../../../../../../../libs/grid/col";
import Row from "../../../../../../../../libs/grid/row";
import TextBlock from "../../../../../../../../libs/text-block/text-block";

interface ProjectPromotionEditFormProps {
  project: SafeProjectDto;
}

const ProjectPromotionEditForm: React.FC<ProjectPromotionEditFormProps> = (props) => {
  const { project } = props;
  const { projectId = "" } = useParams<{ projectId: string }>();
  const { data } = useQuery<PromotionDto, string>("project/get-promotion", [Api.Project.GetPromotion, projectId], { statusResetDelay: 1000 });
  const { data: officeData } = useQuery<OfficeDto, string>("office/get", [Api.Office.Get, project.officeId?.toString()], { callOnLoad: true });

  const { call: call_update, status: updateStatus } = useQuery<void, PromotionDto>("project/update-promotion", [Api.Project.UpdatePromotion], {
    callOnLoad: false,
  });

  const {
    register,
    watch,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<PromotionDto>({ defaultValues: data });

  const onSubmit = async (dto: PromotionDto) => {
    if (window.confirm("Czy na pewno chcesz zapisać ustawienia promocji?")) {
      call_update({
        ...data,
        ...dto,
        activeFrom: DateHelper.ConvertZone__UI_to_API(dto.activeFrom),
        activeTo: DateHelper.ConvertZone__UI_to_API(dto.activeTo),
        projectId: data?.projectId ?? Number(projectId),
      });
    }
  };

  useEffect(() => {
    if (data) reset({ ...data, activeFrom: DateHelper.ConvertZone__API_to_UI(data.activeFrom), activeTo: DateHelper.ConvertZone__API_to_UI(data.activeTo) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) reset({ ...data, activeFrom: DateHelper.ConvertZone__API_to_UI(data.activeFrom), activeTo: DateHelper.ConvertZone__API_to_UI(data.activeTo) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Dane zostały zmienione");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      {officeData?.promotion && officeData.promotion.isActive ? (
        <Row gap={8}>
          <Col size={12}>
            <TextBlock className="border-primary">
              <p className="px-3">
                ⚠️ Uwaga Ten projekt jest objęty promocją "<b className="bg-gray-label px-1">{officeData?.promotion.name}</b>" o zasięgu pracowni. Aktualnie
                cena jest{" "}
                <b>
                  obniżona już o {officeData?.promotion.value}
                  {officeData.promotion.valueType === PromotionValueType_Enum.Percent ? "%" : "zł"}
                </b>{" "}
                dla wszystkich projeków z tej pracowni. Jeżeli utworzysz nową promocję za pomocą poniższego formularza, to <b>promocje nałożą się</b>. ⚠️
                <br />
                <br />
                Promocja pracowni będzie aktywna od {DateHelper.ConvertZone__API_to_UI__formatedDate(officeData.promotion.activeFrom?.toString())} do{" "}
                {DateHelper.ConvertZone__API_to_UI__formatedDate(officeData.promotion.activeTo?.toString())}
              </p>
            </TextBlock>
          </Col>
        </Row>
      ) : null}

      <Form onSubmit={handleSubmit(onSubmit)} cols={[2, 2, 2, 1]} direction="row">
        <div className="form-child form-child-wrapper">
          <TextInput
            id="name"
            label="Nazwa promocji"
            validation_msg={errors?.name?.message}
            inputProps={{
              ...register("name", Validators.required),
            }}
          />
          <TextInput
            id="description"
            label="Opis promocji"
            validation_msg={errors?.description?.message}
            inputProps={{
              type: "textarea",
              ...register("description", Validators.required),
            }}
          />
        </div>
        <div className="form-child form-child-wrapper date-pickers">
          <CheckBoxInput
            id="isActive"
            label="Status"
            description="Aktywna"
            validation_msg={errors?.isActive?.message}
            inputProps={{
              ...register("isActive"),
            }}
          />
          {(
            [
              {
                field: "activeFrom",
                component: "SelectDatatimeBox",
                maxDetail: "minute", //'hour',
                format: "dd.MM.yyyy HH:mm", //:00',
                label: "Aktywna od",
                validators: Validators.required,
              },
              {
                field: "activeTo",
                component: "SelectDatatimeBox",
                maxDetail: "minute", //'hour',
                format: "dd.MM.yyyy HH:mm", //00',
                label: "Aktywna do",
                validators: Validators.required,
              },
              {
                field: "valueType",
                component: "SelectBox",
                label: "Typ zmiany",
                validators: Validators.required,
                options: [
                  ...mapEnum(PromotionValueType_Enum, (x: PromotionValueType_Enum) => {
                    return { id: x, label: PromotionValueType_Enum_Parser(x) };
                  }),
                ],
                conditionalFields: [
                  {
                    basedOn: "valueType",
                    condition: (x) => x == PromotionValueType_Enum.Percent,
                    fields: [
                      {
                        field: "value",
                        type: "number",
                        component: "TextBox",
                        placeholder: "-5",
                        label: "Wartość (%)",
                        validators: Validators.promotionPerecent,
                      },
                    ],
                  },
                  {
                    basedOn: "valueType",
                    condition: (x: PromotionValueType_Enum) => x == PromotionValueType_Enum.Raw,
                    fields: [
                      { field: "value", type: "number", component: "TextBox", placeholder: "-123", label: "Wartość (zł)", validators: Validators.promotionRaw },
                    ],
                  },
                ],
              },
            ] as Array<AdvancedInputField>
          ).map((x: any, index: number) => {
            return (
              <InputBuilder
                key={index}
                advancedInputField={x}
                formHook={{
                  control: control,
                  getValues: getValues,
                  register: register,
                  watch: watch,
                  errors: errors,
                }}
              />
            );
          })}
        </div>
        <br />
        <div className="ml-auto">
          <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
            Zapisz
          </LoadingBtn>
        </div>
      </Form>
    </>
  );
};

export default ProjectPromotionEditForm;
