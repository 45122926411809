import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "../../../../../../../../api/Api";
import { BenefitDto } from "../../../../../../../../api/benefit/benefit.dto";
import { SystemScope_Enum } from "../../../../../../../../api/shared/System.dto";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../features/confirm";
import GenericList from "../../../../../../../../libs/generic-list/generic-list";
import { useTrigger } from "../../../../../../../components/GlobalTrigger/GlobalTriggerSlice";
import Menu from "../../../../../../../components/Menu/menu";
import Modal from "../../../../../../../components/Modal/Modal";
import BenefitAddEditModal from "../../../offices/edit/benefit-addEdit-modal";
import BenefitAddModal from "../../../offices/edit/benefit-addEdit-modal";

interface BenefitsListProps {}

const BenefitsList: React.FC<BenefitsListProps> = (props) => {
  const { projectId = "" } = useParams<{ projectId: string }>();
  const { setTrigger, triggerId } = useTrigger();

  const {
    call,
    status,
    data: benefitsData,
  } = useQuery<Array<BenefitDto>, number>("benefits/listFromProject", [Api.Benefit.ListFromProject, Number(projectId)], { callOnLoad: true });
  const { call: deleteBenefit, status: deleteStatus } = useQuery<void, number>("benefits/delete", [Api.Benefit.Delete], { callOnLoad: false });

  useEffect(() => {
    if (deleteStatus === "finished") call(Number(projectId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  return (
    <>
      <div className="d-flex pb-3">
        <h2 className="title mr-auto">Pakiet korzyści</h2>
        <Modal
          title={`Dodaj nowy pakiet korzyści`}
          disableStandardButtons={true}
          button={{
            title: `+ Dodaj nowy`,
            variant: "like-input-primary",
          }}
          confirm={() => call(Number(projectId))}
        >
          <div style={{ maxWidth: "1000px" }}>
            <BenefitAddModal projectId={Number(projectId)} scope={SystemScope_Enum.Project} />
          </div>
        </Modal>
      </div>
      <hr />
      <GenericList
        status={status}
        data={benefitsData}
        cols={[
          ["Id", "id", 0],
          ["Nazwa", "name", 3],
          ["Opis", "description", 8],
          [
            "",
            "",
            0,
            (x) => {
              return (
                <Modal
                  title={`Edycja korzyści`}
                  button={{
                    hidden: true,
                  }}
                  disableStandardButtons={true}
                  openTrigger={triggerId === "benefits_addEdit_modal--" + x.id}
                  confirm={() => call(Number(projectId))}
                >
                  <div style={{ maxWidth: "1000px" }}>
                  <BenefitAddEditModal projectId={Number(projectId)} item={x} scope={SystemScope_Enum.Project} />
                  </div>
                </Modal>
              );
            },
          ],
        ]}
        actions={[
          "",
          "",
          1,
          (item: BenefitDto) => (
            <Menu
              header="Opcje"
              items={[
                {
                  label: "Zarządzaj",
                  onAction: () => setTrigger("benefits_addEdit_modal--" + item.id),
                },
                { label: "Usuń", onAction: () => Confirm(`Czy na pewno chcesz usunąć ${item.name}?`, () => deleteBenefit(item.id)) },
              ]}
            />
          ),
        ]}
      />
    </>
  );
};

export default BenefitsList;
