import React from "react";
import { IndexRouteProps, LayoutRouteProps, Navigate, PathRouteProps } from "react-router-dom";
import { RoutePath } from "../../route-paths";
import { useSession } from "../../api/auth/sessionSlice";

interface AuthProps {
  path: string;
  element?: React.ReactNode | null;
  children?: React.ReactNode;
  roles?: Array<string>;
  routeProps?: Omit<IndexRouteProps | LayoutRouteProps | PathRouteProps, "element" | "path">;
}

const AuthRoute: React.FC<AuthProps> = (props) => {
  return <></>;
};

const NoAuthRoute: React.FC<IndexRouteProps | LayoutRouteProps | PathRouteProps> = (props) => {
  return <></>;
};

interface AuthProviderProps {
  roles?: Array<string>;
  children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = (props) => {
  const { session } = useSession();
  return <> {session ? props.children : <Navigate replace to={RoutePath.SIGN_IN} />}</>;
};

const NoAuthProvider: React.FC<AuthProviderProps> = (props) => {
  const { session } = useSession();
  return <> {session ? <Navigate replace to={RoutePath.PANEL} /> : props.children}</>;
};

export { AuthRoute, NoAuthRoute, AuthProvider, NoAuthProvider };
