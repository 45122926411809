import React, { useState } from "react";
import { generatePath } from "react-router-dom";
import Api from "../../../../../../../../api/Api";
import { ArrayResult } from "../../../../../../../../api/client";
import { OfficeDto, OfficeForListDto } from "../../../../../../../../api/office/office.dto";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../features/confirm";
import useEffectAfterMounted from "../../../../../../../../features/useEffectAfterMounted";
import { BasicFilterDto, StandardFilterDto } from "../../../../../../../../libs/filters/filters.dto";
import Filters from "../../../../../../../../libs/filters/FiltersForm";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import GenericList from "../../../../../../../../libs/generic-list/generic-list";
import { useAppDispatch } from "../../../../../../../../redux/store";
import { RoutePath } from "../../../../../../../../route-paths";
import Menu from "../../../../../../../components/Menu/menu";
import Pagination from "../../../../../../../components/Pagination/Pagination";
import { PaginationFilterDto } from "../../../../../../../components/Pagination/pagination.dto";
import { showSlideOverlay } from "../../../../../../../components/router-transition/routerTransitionSlice";
import ProjectsCounter from "./ProjectsCounter";
import FileDownloadButton from "./DownloadXMLButton";

interface OfficesListProps {}

const OfficesList: React.FC<OfficesListProps> = (props) => {
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState<StandardFilterDto>({});
  const [pagination, setPagination] = useState<PaginationFilterDto>({ take: 7, skip: 0 });
  const { data, status, call } = useQuery<ArrayResult<OfficeForListDto>, BasicFilterDto>("office/list", [Api.Office.List, { ...filters, ...pagination }]);

  const deleteItem = async (id: number) => {
    await Api.Office.Delete(id).then(() => {
      call({ ...filters, ...pagination });
    });
  };

  useEffectAfterMounted(() => {
    call({ ...filters, ...pagination });
  }, [filters, pagination]);

  return (
    <>
      <Filters
        onSubmit={setFilters}
        setPagination={setPagination}
        status={status}
        fields={[
          {
            field: "searchByColumn",
            component: "SelectBox",
            label: "Kolumna",
            options: [{ id: "name", label: "Nazwa" }],
            conditionalFields: [
              {
                basedOn: "searchByColumn",
                condition: (x: any) => x === "name",
                fields: [{ field: "searchText", component: "TextBox", placeholder: "Wyszukaj", label: "Zawartość kolumny", validators: Validators.required }],
              },
            ],
          },
        ]}
      />
      <GenericList
        status={status}
        data={data?.list}
        cols={[
          ["Id", "id", 0],
          ["Nazwa", "name", 2],
          ["Projekty", "", 2, (x) => <ProjectsCounter id={x.id} />],
          ["Opis", "description", 7],
        ]}
        actions={[
          "",
          "",
          1,
          (item: OfficeDto) => (
            <Menu
              header="Opcje"
              items={[
                { label: "Zarządzaj", onAction: () => dispatch(showSlideOverlay(generatePath(RoutePath.PANEL_OFFICES_EDIT, { id: item.id.toString() }))) },
                {
                  customRender: <FileDownloadButton id={item.id.toString()} />,
                },
                {
                  label: "Usuń",
                  onAction: () =>
                    Confirm(`Czy na pewno chcesz usunąć ${item.name}? Ta operacja usunie również wszystkie projekty należące do pracowni.`, () =>
                      deleteItem(item.id)
                    ),
                },
              ]}
            />
          ),
        ]}
      />
      <Pagination
        disableScroll
        totalCount={data?.totalCount}
        itemsPerPage={7}
        take={pagination.take!}
        setNewSkip={(newSkip: number) => setPagination({ ...pagination, skip: newSkip })}
      />
    </>
  );
};

export default OfficesList;
