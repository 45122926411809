import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../libs/forms/validators/validators";
import Api from "../../../../../../../api/Api";
import { useQuery, useQueryRefetch } from "../../../../../../../api/useQuerySlice";
import { ToastsStore } from "react-toasts";
import { AddOfficeDto } from "../../../../../../../api/office/office.dto";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../../../../../route-paths";
interface OfficeAddFormProps {
}

const OfficeAddForm: React.FC<OfficeAddFormProps> = (props) => {
  const { call, status: updateStatus } = useQuery<void, AddOfficeDto>('office/add', [Api.Office.Add], { callOnLoad: false });
  const { register, handleSubmit, formState: { errors }, reset } = useForm<AddOfficeDto>();
  const { refetch } = useQueryRefetch();
  const navigate = useNavigate();
  const onSubmit = async (dto: AddOfficeDto) => {
    call({ ...dto });
  };


  useEffect(() => {
    if (updateStatus === 'finished') {
      ToastsStore.success("Pracownia została dodana");
      reset();
      refetch("office/list");
      navigate(RoutePath.PANEL_OFFICES_LIST as string);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus])


  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
        <TextInput
          id="name"
          label="Nazwa"
          validation_msg={errors?.name?.message}
          inputProps={{
            ...register("name", Validators.required)
          }}
        />
        <TextInput
          id="description"
          label="Opis"
          validation_msg={errors?.description?.message}
          inputProps={{
            type:"textarea",
            ...register("description", Validators.required)
          }}
        />
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">Dodaj</LoadingBtn>
      </Form>
    </>
  );
};

export default OfficeAddForm;
