import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import Api from "../../../../../../api/Api";
import { InfoCardDto, InfoCardType_Enum, InfoCardType_Enum_Parser } from "../../../../../../api/infoCard/infoCard.dto";
import { SystemScope_Enum } from "../../../../../../api/shared/System.dto";
import { useQuery } from "../../../../../../api/useQuerySlice";
import { mapEnum } from "../../../../../../features/enumMap";
import LoadingBtn from "../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../libs/forms/Form/Form";
import SelectBox from "../../../../../../libs/forms/SelectBox/SelectBox";
import TextInput from "../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../libs/forms/validators/validators";
import WYSIWYGEditor from "../../../../../../libs/forms/WysiwigEditor/WysiwigEditor";
import { ModalContext } from "../../../../../components/Modal/ModalContext";

interface InfoCardAddEditModalProps {
  scopeId?: number;
  scope: SystemScope_Enum;
  infoCard?: InfoCardDto;
}

const InfoCardAddEditModal: React.FC<InfoCardAddEditModalProps> = (props) => {
  const { call, status } = useQuery<void, InfoCardDto>("infoCard/add", [Api.InfoCard.Add], { callOnLoad: false });
  const { call: editCall, status: editStatus } = useQuery<void, InfoCardDto>("infoCard/edit", [Api.InfoCard.Update], { callOnLoad: false });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<InfoCardDto>({ mode: "onChange", defaultValues: {} });
  const { handleConfirm } = useContext(ModalContext);
  const { scopeId, scope, infoCard: item } = props;

  const onSubmit = (data: InfoCardDto) => {
    if (item) editCall({ ...data, scopeId: scopeId, scope: scope });
    else call({ ...data, scopeId: scopeId, scope: scope });
  };

  useEffect(() => {
    if (status === "finished" || editStatus === "finished") handleConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, editStatus]);

  useEffect(() => {
    if (item !== null) {
      reset({ ...item });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} cols={[1, 1, 1, 1]} direction="row">
      <div className="form-child form-child-wrapper">
        <TextInput
          id="name"
          label="Nazwa"
          validation_msg={errors?.name?.message}
          required
          inputProps={{
            ...register("name", Validators.required),
          }}
        />
        <SelectBox
          id="type"
          label="Typ karty"
          validation_msg={errors?.type?.message}
          options={mapEnum(InfoCardType_Enum, (x: InfoCardType_Enum) => {
            return { id: x, label: InfoCardType_Enum_Parser(x) };
          })}
          maxHeight
          inputProps={{
            placeholder: "Typ karty",
            ...register("type", Validators.required),
          }}
        />
        {/* cvbcv
        <Controller
          name="body"
          control={control}
          render={(fields) => {
            return <WysiwigBox id={"body"} {...fields} />;
          }}
        /> */}
        <WYSIWYGEditor
          id="body"
          label="Zawartość"
          validation_msg={errors?.body?.message}
          inputProps={{
            control: control,
          }}
        />
      </div>
      <br />
      <div className="ml-auto">
        {item ? (
          <LoadingBtn type="submit" status={editStatus} className="ml-auto">
            Zapisz
          </LoadingBtn>
        ) : (
          <LoadingBtn type="submit" status={status} className="ml-auto">
            Dodaj
          </LoadingBtn>
        )}
      </div>
    </Form>
  );
};

export default InfoCardAddEditModal;
