import { useEffect, useState } from "react";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import TextBlock from "../../../../../../../../libs/text-block/text-block";

const StatusInfo = () => {
  const { call, status } = useQuery<void>("cron/live", [Api.Cron.Live], { callOnLoad: false });
  const [currentDate, setCurrentDate] = useState("🟡 status loading - " + new Date().toLocaleString());
  useEffect(() => {
    setInterval(() => {
      call();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status === "finished") {
      setCurrentDate("🟢 server live - " + new Date().toLocaleString());
    } else if (status === "failed") {
      setCurrentDate("🔴 server down - " + new Date().toLocaleString());
    }
  }, [status]);

  return (
    <>
      <TextBlock>
        <p>{currentDate}</p>
      </TextBlock>
    </>
  );
};

export default StatusInfo;
