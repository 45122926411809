export type EnumType = { [s: number]: string };

export function mapEnum(enumerable: EnumType, fn: Function): any[] {
  // get all the members of the enum
  let enumMembers: any[] = Object.keys(enumerable).map((key) => enumerable[key as any]);

  // we are only interested in the numeric identifiers as these represent the values
  let enumValues: number[] = enumMembers.filter((v) => typeof v === "number");

  // now map through the enum values
  return enumValues.map((m) => fn(m));
}

export function enumsToString<T>(enumList: Array<T>) {
  const wartosciEnum = enumList.map((element: any) => element.valueOf());
  return wartosciEnum.join(",");
}
