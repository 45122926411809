import React from "react";
import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../components/Tile/Tile";
import OfficesList from "./OfficesSyncList/OfficesSyncList";

const OfficesSyncListPage: React.FC = () => {
  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_OFFICES_SYNC_LIST} title="Moduł synchronizacji" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Zarządzanie synchronizacjami</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <div className="d-flex pb-3">
                <h2 className="title mr-auto">Lista synchronizacji</h2>
              </div>
              <hr />
              <OfficesList />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div >
  );
};

export default OfficesSyncListPage;
