import React from "react";
import BoxTile from "../../../../libs/containers/BoxTile/BoxTile";
import ResetPasswordForm from "./ResetPasswordForm/ResetPasswordForm";
import { useResetPassword } from "./ResetPasswordForm/ResetPasswordDataSlice";
import TextBlock from "../../../../libs/text-block/text-block";
import { RoutePath } from "../../../../route-paths";
import TopLink from "../../../components/top-link/top-link";
import Button from "../../../../libs/buttons/Button/Button";

const ResetPasswordPage: React.FC = () => {
  const { resetPassword_status } = useResetPassword();

  return (
    resetPassword_status === 'finished' ?
      <BoxTile maxW={440} title={<h1>Udało się!</h1>}>
        <TextBlock>
          <p className="text-center">
            Hasło zostało zmienione, możesz teraz zalogować się na swoje konto.
          </p>
        </TextBlock>
        <TextBlock className="text-center">
          <TopLink to={RoutePath.SIGN_IN} className="inline-flex ml-auto mt-4 mb-0">
            <Button>
              Przejdź do ekranu logowania
            </Button>
          </TopLink>
        </TextBlock>
      </BoxTile> :
      <BoxTile maxW={440} title={<h1>Zresetuj hasło</h1>}>
        <ResetPasswordForm />
      </BoxTile>
  );
};

export default ResetPasswordPage;
