import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { ArticleContentDto, ArticleDto } from "../../../../../../../../../api/blog/article/article.dto";
import Api from "../../../../../../../../../api/Api";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import { ToastsStore } from "react-toasts";
import EditorJsInput from "../../../../../../../../../libs/forms/EditorJsBox/EditorJsInput";

interface ArticleContentEditFormProps {
  data: ArticleDto | undefined;
}

interface FormDto {
  content: string;
}

const ArticleContentEditForm = (props: ArticleContentEditFormProps) => {
  const { data } = props;
  // const { refetch } = useQueryRefetch();
  const { call, status: updateStatus } = useQuery<void, ArticleContentDto>("article/updateContent", [Api.Blog.Article.UpdateContent], { callOnLoad: false });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormDto>({ defaultValues: { content: data?.content } });

  const onSubmit = async (form: FormDto) => {
    call({ id: data?.id, content: form.content } as ArticleContentDto);
  };

  useEffect(() => {
    reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Zapisano");
      // refetch(Number(id));
      // refetch("article/get");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
        {/* <WYSIWYGEditor
          id="description"
          label="Opis ogólny"
          validation_msg={errors?.description?.message}
          inputProps={{
            control: control,
            defaultValue: articleLang?.description,
          }}
        /> */}
        {/* <EditorJsInput */}
        <EditorJsInput
          id="content"
          label="Content"
          validation_msg={errors?.content?.message}
          inputProps={{
            type: "textarea",
            ...register("content"),
          }}
        />
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
          Zapisz
        </LoadingBtn>
      </Form>
    </>
  );
};

export default ArticleContentEditForm;
