import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import Api from "../../../../../../../../../api/Api";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import { ProjectCategoryDto } from "../../../../../../../../../api/projectCategory/projectCategory.dto";
import { ToastsStore } from "react-toasts";
import BreakTitle from "../../../../../../../../components/BreakTitle/BreakTitle";
import CheckBoxInput from "../../../../../../../../../libs/forms/CheckBox/CheckBox";

interface ProjectCategoryEditFormProps {
  data: ProjectCategoryDto | undefined;
}

const ProjectCategoryEditForm = (props: ProjectCategoryEditFormProps) => {
  const { data } = props;
  // const { refetch } = useQueryRefetch();
  const { call, status: updateStatus } = useQuery<void, ProjectCategoryDto>("project-category/update-" + data?.id, [Api.ProjectCategory.Update], {
    callOnLoad: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ProjectCategoryDto>({ defaultValues: { ...data } });

  const onSubmit = async (dto: ProjectCategoryDto) => {
    call({ ...dto, id: data?.id } as ProjectCategoryDto);
  };

  useEffect(() => {
    reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Zapisano");
      // refetch(Number(id));
      // refetch("project-category/get");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
        <TextInput
          id="title"
          label="Nazwa (h1)"
          validation_msg={errors?.title?.message}
          required
          inputProps={{
            ...register("title", Validators.required),
          }}
        />
        <TextInput
          id="slug"
          label="Slug"
          validation_msg={errors?.slug?.message}
          required
          inputProps={{
            ...register("slug", Validators.required),
          }}
        />
        <CheckBoxInput
          id="isFavourite"
          label="Kategoria główna"
          description="Widoczność: navbar, footer, home-page"
          validation_msg={errors?.isFavourite?.message}
          inputProps={{
            ...register("isFavourite"),
          }}
        />
        <TextInput
          id="description_top"
          label="Krótki opis ogólny (górny nad listą projektów)"
          validation_msg={errors?.description_top?.message}
          inputProps={{
            rows: 20,
            type: "textarea",
            ...register("description_top"),
          }}
        />
        <TextInput
          id="tile_title"
          label="Tytuł kafelka (h3)"
          validation_msg={errors?.tile_title?.message}
          required
          inputProps={{
            ...register("tile_title"),
          }}
        />
        <TextInput
          id="tile_description"
          label="Opis kafelka"
          validation_msg={errors?.tile_description?.message}
          inputProps={{
            type: "textarea",
            ...register("tile_description"),
          }}
        />
        <BreakTitle className="pl-0">SEO Settings</BreakTitle>
        <TextInput
          id="meta_title"
          label="[SEO] meta <title>"
          showCharCount
          validation_msg={errors?.meta_title?.message}
          inputProps={{
            ...register("meta_title"),
          }}
        />
        <TextInput
          id="meta_description"
          label="[SEO] meta <description>"
          showCharCount
          validation_msg={errors?.meta_description?.message}
          inputProps={{
            type: "textarea",
            ...register("meta_description"),
          }}
        />
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
          Zapisz
        </LoadingBtn>
      </Form>
    </>
  );
};

export default ProjectCategoryEditForm;
