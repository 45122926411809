import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../libs/forms/Form/Form";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { ToastsStore } from "react-toasts";
import { generatePath, useParams } from "react-router-dom";
import { ProjectDescriptionDto, ProjectDescriptionDto__, SafeProjectDto } from "../../../../../../../../api/project/project.dto";
import InputPolicyController, { frstLettUp } from "../../../../../../../components/InputPolicyController/inputPolicyController";
import WYSIWYGEditor from "../../../../../../../../libs/forms/WysiwigEditor/WysiwigEditor";
import Mapper from "../../../../../../../../features/auto-mapper";
import TopLink from "../../../../../../../components/top-link/top-link";
import { RoutePath } from "../../../../../../../../route-paths";
import TextBlock from "../../../../../../../../libs/text-block/text-block";

interface ProjectDescriptionEditFormProps {}

const ProjectDescriptionEditForm: React.FC<ProjectDescriptionEditFormProps> = (props) => {
  const { projectId = "" } = useParams<{ projectId: string }>();
  const { data } = useQuery<SafeProjectDto, string>("project/get", [Api.Project.Get, projectId], {
    statusResetDelay: 1000,
  });
  const { call, status: updateStatus } = useQuery<void, ProjectDescriptionDto>("project/updateDescription", [Api.Project.UpdateDescription], {
    callOnLoad: false,
  });
  const [unverifiedProps, setUnverifiedProps] = useState<Array<string>>([]);
  const [overwritten_params, setOverwritten_params] = useState<Array<string>>([]);
  const [id_fromXml, setId_fromXml] = useState<string>("");

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<ProjectDescriptionDto>({ defaultValues: {} });

  const onSubmit = async (dto: ProjectDescriptionDto) => {
    call({ id: dto.id, opis: dto.opis, unverifiedProps: unverifiedProps, overwritten_params: overwritten_params });
  };

  useEffect(() => {
    if (data) {
      var mappedData = new Mapper<ProjectDescriptionDto>(ProjectDescriptionDto__).Map(data);
      reset(mappedData);
      setId_fromXml(data?.id_fromXml ?? "");
      setUnverifiedProps(mappedData?.unverifiedProps ?? []);
      setOverwritten_params(mappedData?.overwritten_params ?? []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Dane zostały zmienione");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-child form-child-wrapper">
          {id_fromXml ? (
            <InputPolicyController
              propName="opis"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <WYSIWYGEditor
            id="opis"
            label="Opis"
            validation_msg={errors?.opis?.message}
            inputProps={{
              control: control,
              defaultValue: data?.opis,
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Opis")) === -1,
            }}
          />
          <TextBlock>
            <p>Uwaga, na koniec opisu projektu zostanie automatycznie dodany opis biura projektowego, który można ustawić tutaj:</p>
            <TopLink target="_blank" className="primary" to={generatePath(RoutePath.PANEL_OFFICES_EDIT, { id: data?.officeId?.toString() ?? "" })}>
              Formularz edycji opisu biura
            </TopLink>
          </TextBlock>
        </div>
        <br />
        <div className="ml-auto">
          <LoadingBtn type="submit" status={updateStatus}>
            Zapisz
          </LoadingBtn>
        </div>
      </Form>
    </>
  );
};

export default ProjectDescriptionEditForm;
