import { useEffect } from "react";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../features/confirm";
import GenericList from "../../../../../../../../libs/generic-list/generic-list";
import Menu from "../../../../../../../components/Menu/menu";
import Modal from "../../../../../../../components/Modal/Modal";
import { ToastsStore } from "react-toasts";
import { ArrayResult } from "../../../../../../../../api/client";
import { ProjectCategoryDto, RelatedProjectCategoryRelationDto } from "../../../../../../../../api/projectCategory/projectCategory.dto";
import { BasicColumnFilterDto } from "../../../../../../../../libs/filters/filters.dto";
import RelatedProjectsAddModal from "./related-projects-categories-add-modal";

interface Props {
  projectId: number;
}

const RelatedProjectCategories = (props: Props) => {
  const { projectId } = props;
  const {
    data: projectCategoriesList, //
    call: callList,
    status: listStatus,
  } = useQuery<ArrayResult<ProjectCategoryDto>, BasicColumnFilterDto>("ProjectCategories/List-all", [
    Api.ProjectCategory.List,
    {
      searchByColumn: "projectId",
      searchId: projectId,
    },
  ]);
  const {
    call: callDeleteRelatedTag, //
    status: deleteRelatedTagStatus,
  } = useQuery<void, RelatedProjectCategoryRelationDto>("ProjectCategory/DeleteProjectRelationCategory", [Api.ProjectCategory.DeleteProjectRelationCategory], {
    callOnLoad: false,
  });

  useEffect(() => {
    if (deleteRelatedTagStatus === "finished") {
      callList({
        searchByColumn: "projectId",
        searchId: projectId,
      });
      ToastsStore.success("Usunięto");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRelatedTagStatus]);

  return (
    <>
      <div className="d-flex pb-3">
        <div className="mr-auto">
          <h2 className="title pr-4">🔗 Powiązane kategorie</h2>
        </div>
        <Modal
          title={`Dodaj nowe powiązanie`}
          disableStandardButtons={true}
          button={{
            title: `+ Dodaj nowy`,
            variant: "like-input-primary",
          }}
          maxWidth={1200}
          confirm={() =>
            callList({
              searchByColumn: "projectId",
              searchId: projectId,
            })
          }
          className={"RelatedTagAddEditModal"}
        >
          <RelatedProjectsAddModal projectId={projectId} />
        </Modal>
      </div>
      <hr />
      <GenericList
        status={listStatus}
        data={projectCategoriesList?.list}
        cols={[
          ["Id", "id", 2],
          ["Nazwa", "title", 9],
        ]}
        actions={[
          "",
          "",
          1,
          (item: ProjectCategoryDto) => (
            <Menu
              header="Opcje"
              items={[
                {
                  label: "Usuń relację",
                  onAction: () =>
                    Confirm(`Czy na pewno chcesz usunąć powiązanie z ${item.title}?`, () =>
                      callDeleteRelatedTag({ projectId: projectId, categoryId: item.id! })
                    ),
                },
              ]}
            />
          ),
        ]}
      />
    </>
  );
};

export default RelatedProjectCategories;
