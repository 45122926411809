import React from "react";
import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../components/Tile/Tile";
import ServerResetForm from "./ServerResetForm/ServerResetForm";
import StatusInfo from "./StatusInfo/StatusInfo";

const ServerPage: React.FC = () => {
  return (
    <div className="server-page">
      <BreadcrumbsItem to={RoutePath.PANEL_SETTINGS_SERVER} title="Serwer" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Ustawienia serwera</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={[4, 6, 8, 12]}>
            <Tile>
              <h2 className="title">Resetowanie serwera</h2>
              <ServerResetForm />
            </Tile>
          </Col>
          <Col size={[4, 6, 8, 12]}>
            <Tile>
              <h2 className="title">Status serwera</h2>
              <StatusInfo />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default ServerPage;
