import { generatePath } from "react-router-dom";
import Api from "../../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../../features/confirm";
import useEffectAfterMounted from "../../../../../../../../../features/useEffectAfterMounted";
import GenericList, { NumberStringTuple } from "../../../../../../../../../libs/generic-list/generic-list";
import { useAppDispatch } from "../../../../../../../../../redux/store";
import { RoutePath } from "../../../../../../../../../route-paths";
import Menu, { MenuItemType } from "../../../../../../../../components/Menu/menu";
import { showSlideOverlay } from "../../../../../../../../components/router-transition/routerTransitionSlice";
import { ProjectCategoryDto } from "../../../../../../../../../api/projectCategory/projectCategory.dto";
import { ToastsStore } from "react-toasts";
import TopLink from "../../../../../../../../components/top-link/top-link";
import { ArrayResult } from "../../../../../../../../../api/client";

interface Props {}

const ProjectCategoriesList = (props: Props) => {
  const { data, status, call } = useQuery<ArrayResult<ProjectCategoryDto>>("project-category/List", [Api.ProjectCategory.List]);
  const { status: delStatus, call: callDelete } = useQuery<void, number>("project-category/delete", [Api.ProjectCategory.Delete], { callOnLoad: false });

  const dispatch = useAppDispatch();

  useEffectAfterMounted(() => {
    if (delStatus === "finished") {
      ToastsStore.success("Usunięto");
      call();
    }
  }, [delStatus]);

  const handleDelete = (projectCategory: ProjectCategoryDto) => {
    Confirm(`Czy na pewno chcesz usunąć ${projectCategory.title}?`, () => callDelete(projectCategory.id));
  };

  const standardCols: NumberStringTuple[] = [
    [
      "Nazwa",
      "",
      9,
      (x: ProjectCategoryDto) => (
        <TopLink to={generatePath(RoutePath.PANEL_PROJECTCATEGORIES_EDIT, { categoryId: x.id?.toString() })} className="primary">
          {x.title}
        </TopLink>
      ),
    ],
    ["Główna", "", 2, (x: ProjectCategoryDto) => <span className="ml-3" style={{ filter: !x.isFavourite ? "grayscale(1)" : undefined }}>⭐</span>],
  ];

  const standardActions = (actionsItems: (projectCategory: ProjectCategoryDto) => Array<MenuItemType>): NumberStringTuple => [
    "",
    "",
    1,
    (projectCategory: ProjectCategoryDto) => <Menu header="Opcje" items={actionsItems(projectCategory)} />,
  ];

  const actionsItems__edit = (projectCategory: ProjectCategoryDto) => {
    return {
      label: "Edytuj",
      onAction: () => dispatch(showSlideOverlay(generatePath(RoutePath.PANEL_PROJECTCATEGORIES_EDIT, { categoryId: projectCategory.id?.toString() }))),
    };
  };
  const actionsItems__delete = (projectCategory: ProjectCategoryDto) => {
    return { label: "Usuń", onAction: () => handleDelete(projectCategory) };
  };

  const standardActionsItems = (projectCategory: ProjectCategoryDto): Array<MenuItemType> => {
    return [actionsItems__edit(projectCategory), actionsItems__delete(projectCategory)];
  };
  return <GenericList status={status} data={data?.list ?? []} cols={[...standardCols]} actions={standardActions(standardActionsItems)} />;
};

export default ProjectCategoriesList;
