import "./drop-zone.scss";
import React, { useState, useEffect } from "react";
import { getBase64 } from "../../../features/toBase64";

interface FileUploadDto {
  file?: any;
  previewUrl?: any;
}

interface Props {
  onChange: any;
  onFileDelete?: any;
  disabled?: boolean;
  clear?: boolean;
  id: string;
  value: FileUploadDto;
  label?: string;
  name?: string;
  validation_msg?: string;
  required?: boolean;
}

const DropzoneCore: React.FC<Props> = (props: Props) => {
  const { label, id, validation_msg, required, disabled, onChange, value } = props;
  const [coreValue, setCoreValue] = useState<FileUploadDto>({ file: undefined, previewUrl: undefined });

  const [tempImg, setTempImg] = useState();

  useEffect(() => {}, [props.clear]);

  const handleDeleteImage = () => {
    props.onFileDelete();
  };

  // const handleImageChange = (file: File) => {
  //   getBase64(file, (result: any) => {
  //     setTempImg(result);
  //   });

  //   var hiddenInput: any = document.getElementById(id) as any;
  //   // hiddenInput.value = file;
  //   let reader = new FileReader();
  //   reader.onloadend = () => {
  //     hiddenInput.value = reader.result;

  //     // setTempImg(reader.result);
  //   };
  //   reader.readAsDataURL(file);
  // };

  const setPreviewImage = (file: File) => {
    getBase64(file, (result: any) => {
      setTempImg(result);
    });
  };

  const handleFileAdd = (e: any) => {
    if (props.disabled) return;
    const items: Array<any> = e.target.files;
    if (items.length === 0 && !items[0]) return;

    setPreviewImage(items[0]);
    setCoreValue((state) => {
      return { ...state, file: items[0] };
    });
  };

  useEffect(() => {
    document.addEventListener("paste", handlePaste);

    return () => {
      document.removeEventListener("paste", handlePaste);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaste = (e: any) => {
    if (props.disabled) return;
    const clipboardItems = e.clipboardData.items;
    const items: Array<any> = [].slice.call(clipboardItems).filter(function (item: any) {
      return item.type.indexOf("image") !== -1;
    });
    if (items.length === 0 && !items[0]) return;

    const blob = items[0].getAsFile();
    if (blob) {
      setPreviewImage(blob);
      setCoreValue((state) => {
        return { ...state, file: blob };
      });
    }
  };

  useEffect(() => {
    if (value.previewUrl) {
      setCoreValue({ previewUrl: value.previewUrl });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange(coreValue.file);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coreValue]);

  return (
    <div className={`form-child DropZone`} onPasteCapture={handlePaste}>
      <label htmlFor={id}>
        <span className="label">{label + (required ? "*" : "")}</span>{" "}
        <span className={`validation-messages ${validation_msg ? "active" : ""}`}>{validation_msg ? <>({validation_msg})</> : <>&nbsp;</>}</span>
      </label>
      <div className="dropzone-wrapper form-control-alternative">
        {coreValue.previewUrl ? <i color="danger" onClick={handleDeleteImage} className="deleteImage fa fa-times" aria-hidden="true"></i> : null}
        <div className={`dropzone`} style={{ cursor: disabled ? "default" : "pointer" }}>
          {tempImg ? (
            <div className="temp-img" style={{ backgroundImage: `url(${tempImg})` }} />
          ) : (
            <>{coreValue.previewUrl ? <div className="temp-img" style={{ backgroundImage: `url(${coreValue.previewUrl})` }} /> : null}</>
          )}
          {coreValue.previewUrl || tempImg ? null : (
            <svg className="placeholder fa fa-file-image-o" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M12.37 5.379l-5.64 5.64c-.655.655-1.515.982-2.374.982-1.855 0-3.356-1.498-3.356-3.356 0-.86.327-1.721.981-2.375l5.54-5.539c.487-.487 1.125-.731 1.765-.731 2.206 0 3.338 2.686 1.765 4.259l-4.919 4.919c-.634.634-1.665.634-2.298 0-.634-.633-.634-1.664 0-2.298l3.97-3.97.828.828-3.97 3.97c-.178.177-.178.465 0 .642.177.178.465.178.642 0l4.919-4.918c1.239-1.243-.636-3.112-1.873-1.874l-5.54 5.54c-.853.853-.853 2.24 0 3.094.854.852 2.24.852 3.093 0l5.64-5.64.827.827zm.637-5.379c.409.609.635 1.17.729 2h7.264v11.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-7.362v-8.062c-.63.075-1 .13-2-.133v10.195h10.189c3.163 0 9.811-7.223 9.811-9.614v-14.386h-9.993zm4.993 6h-3.423l-.793.793-.207.207h4.423v-1zm0 3h-6.423l-1 1h7.423v-1zm0 3h-9.423l-.433.433c-.212.213-.449.395-.689.567h10.545v-1z" />
            </svg>
          )}
          <input id={id} className="fileInput" type="file" name={id} multiple={false} onChange={handleFileAdd} />
        </div>
      </div>
    </div>
  );
};

export default DropzoneCore;
