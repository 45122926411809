import { generatePath, useParams } from "react-router-dom";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import Col from "../../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../../components/Tile/Tile";
import TagEditForm from "./forms/TagEditForm";
import { TagDto } from "../../../../../../../../api/blog/tag/tag.dto";
import MediaList from "./media-list/media-list";
import TagContentEditForm from "./forms/TagContentEditForm";

const TagEditPage = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { data: tag } = useQuery<TagDto, number>("tag/get", [Api.Blog.Tag.Get, Number(id)], {
    statusResetDelay: 1000,
  });

  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_KNOWLEDGEBASE_TAGS_LIST} title="Lista" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_KNOWLEDGEBASE_TAGS_EDIT, { id: id })} title="Edytuj kategorie" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Edytuj kategorię</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={[7, 12, 12, 12]}>
            <Tile>
              <h2 className="title">Treść kategorii</h2>
              <TagContentEditForm data={tag} />
            </Tile>
          </Col>
          <Col size={[5, 12, 12, 12]} vif={tag?.id}>
            <Tile>
              <h2 className="title">Podstawowe informacje</h2>
              <TagEditForm data={tag} />
            </Tile>
            <br />
            <Tile>
              <MediaList scopeId={Number(id)} />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default TagEditPage;
