import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../libs/forms/validators/validators";
import Api from "../../../../../../../api/Api";
import { useQuery, useQueryRefetch } from "../../../../../../../api/useQuerySlice";
import { ToastsStore } from "react-toasts";
import { OfficeNameDto } from "../../../../../../../api/office/office.dto";
import { generatePath, useNavigate } from "react-router-dom";
import { RoutePath } from "../../../../../../../route-paths";
import SelectBox, { SelectBoxOption } from "../../../../../../../libs/forms/SelectBox/SelectBox";
import { AddProjectDto, Bud__RodzajZabudowy__Enum, Bud__RodzajZabudowy__Enum_Parser, SafeProjectDto } from "../../../../../../../api/project/project.dto";
import { mapEnum } from "../../../../../../../features/enumMap";
import { IdNameDto } from "../../../../../../../api/shared/System.dto";
interface ProjectAddFormProps {}

const ProjectAddForm: React.FC<ProjectAddFormProps> = (props) => {
  const {
    data: addedProject,
    call: callAdd,
    status: addStatus,
  } = useQuery<SafeProjectDto, AddProjectDto>("project/add", [Api.Project.Add], { callOnLoad: false });
  const { data: officesNames = [] } = useQuery<Array<OfficeNameDto>>("office/listNames", [Api.Office.ListNames], { callOnLoad: true });
  const { data: projectCategoriesNames = [] } = useQuery<Array<IdNameDto>>("ProjectCategory/listNames", [Api.ProjectCategory.ListNames], { callOnLoad: true });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddProjectDto>();

  const { refetch } = useQueryRefetch();
  const navigate = useNavigate();

  const onSubmit = async (dto: AddProjectDto) => {
    callAdd({ ...dto });
  };

  useEffect(() => {
    if (addStatus === "finished") {
      ToastsStore.success("Pracownia została dodana");
      reset();
      refetch("office/list");
      if (addedProject && addedProject.id) {
        navigate(generatePath(RoutePath.PANEL_PROJECTS_EDIT, { projectId: addedProject.id.toString() }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} cols={[3, 3, 2, 1]} direction={"row"}>
        <div className="form-child form-child-wrapper">
          <h3 className="title form-child">Informacje elementarne</h3>
          <SelectBox
            id="officeId"
            label="Pracownia"
            validation_msg={errors?.officeId?.message}
            options={officesNames.map((x) => {
              return {
                id: x.id,
                label: x.name,
              } as SelectBoxOption;
            })}
            maxHeight
            inputProps={{
              placeholder: "Pracownia",
              ...register("officeId", Validators.required),
            }}
          />
          <SelectBox
            id="canonicalCategoryId"
            label="Kategoria kanoniczna"
            validation_msg={errors?.canonicalCategoryId?.message}
            options={projectCategoriesNames.map((x) => {
              return { id: x.id, label: x.name } as SelectBoxOption;
            })}
            maxHeight
            required
            inputProps={{
              placeholder: "Kategoria kanoniczna",
              ...register("canonicalCategoryId", Validators.required),
            }}
          />
          <TextInput
            id="nazwa"
            label="Nazwa"
            required={true}
            validation_msg={errors?.nazwa?.message}
            inputProps={{
              ...register("nazwa", Validators.required),
            }}
          />
          <TextInput
            id="cena__raw_brutto"
            label="Cena brutto [zł]"
            required={true}
            validation_msg={errors?.cena__raw_brutto?.message}
            inputProps={{
              type: "number",
              step: "0.01",
              ...register("cena__raw_brutto", Validators.required),
            }}
          />
          <SelectBox
            id="bud__rodz_zabudowy"
            label="Rodzaj zabudowy"
            validation_msg={errors?.bud__rodz_zabudowy?.message}
            options={mapEnum(Bud__RodzajZabudowy__Enum, (x: Bud__RodzajZabudowy__Enum) => {
              return { id: x, label: Bud__RodzajZabudowy__Enum_Parser(x) };
            })}
            required={true}
            maxHeight
            inputProps={{
              placeholder: "Rodzaj zabudowy",
              ...register("bud__rodz_zabudowy", Validators.required),
            }}
          />
        </div>
        <div className="form-child form-child-wrapper">
          <h3 className="title form-child">Wymiary budynku</h3>
          <TextInput
            id="bud__powierzch_uzytk"
            label="Powierzchnia użytkowa [m2]"
            required={true}
            validation_msg={errors?.bud__powierzch_uzytk?.message}
            inputProps={{
              type: "number",
              step: "0.01",
              min: "0",
              ...register("bud__powierzch_uzytk", Validators.required),
            }}
          />
        </div>
        <div className="form-child form-child-wrapper">
          <h3 className="title form-child">Działka</h3>
          <TextInput
            id="dzialka__min_dlugosc"
            label="Minimalna długość"
            required={true}
            validation_msg={errors?.dzialka__min_dlugosc?.message}
            inputProps={{
              type: "number",
              step: "0.01",
              min: "0",
              ...register("dzialka__min_dlugosc", Validators.required),
            }}
          />
          <TextInput
            id="dzialka__min_szerokosc"
            label="Minimalna szerokość"
            required={true}
            validation_msg={errors?.dzialka__min_szerokosc?.message}
            inputProps={{
              type: "number",
              step: "0.01",
              min: "0",
              ...register("dzialka__min_szerokosc", Validators.required),
            }}
          />
        </div>
        <br />
        <div className="ml-auto">
          <LoadingBtn type="submit" status={addStatus}>
            Zapisz
          </LoadingBtn>
        </div>
      </Form>
    </>
  );
};

export default ProjectAddForm;
