import React, { useEffect } from "react";
import Api from "../../../../../../api/Api";
import { useQuery } from "../../../../../../api/useQuerySlice";

import GenericList from "../../../../../../libs/generic-list/generic-list";
import Menu from "../../../../../components/Menu/menu";
import { Confirm } from "../../../../../../features/confirm";
import { InfoCardDto, InfoCardType_Enum, InfoCardType_Enum_Parser } from "../../../../../../api/infoCard/infoCard.dto";
import Modal from "../../../../../components/Modal/Modal";
import InfoCardAddEditModal from "./infoCard-addEdit-modal";
import { SystemScope_Enum } from "../../../../../../api/shared/System.dto";
import { useTrigger } from "../../../../../components/GlobalTrigger/GlobalTriggerSlice";
import { html } from "../../../../../../features/html";

interface InfoCardsListProps {
  scopeId?: number; // project_id or office_id
  scope: SystemScope_Enum;
}

const InfoCardsList: React.FC<InfoCardsListProps> = (props) => {
  const { scopeId, scope } = props;
  const { setTrigger, triggerId } = useTrigger();

  const scopeConfig = (scope: SystemScope_Enum) => {
    if (scope === SystemScope_Enum.Project) return { key: "infoCard/listFromProject" };
    else if (scope === SystemScope_Enum.Office) return { key: "infoCard/listFromOffice" };
    else return { key: "infoCard/listGlobal" };
  };

  const {
    call,
    status,
    data: infoCardsData,
  } = useQuery<Array<InfoCardDto>, { scope: SystemScope_Enum; scopeId?: number }>(
    scopeConfig(scope).key,
    [Api.InfoCard.List, { scope: scope, scopeId: scopeId }],
    {
      callOnLoad: true,
    }
  );

  const { call: deleteInfoCard, status: deleteStatus } = useQuery<void, number>("infoCard/delete", [Api.InfoCard.Delete], { callOnLoad: false });

  useEffect(() => {
    if (deleteStatus === "finished") call({ scope: scope, scopeId: scopeId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  return (
    <>
      <div className="d-flex pb-3">
        <h2 className="title mr-auto">Karty informacyjne</h2>
        <Modal
          title={`Dodaj nową kartę`}
          disableStandardButtons={true}
          button={{
            title: `+ Dodaj nową`,
            variant: "like-input-primary",
          }}
          confirm={() => call({ scope: scope, scopeId: scopeId })}
        >
          <div style={{ maxWidth: "1000px" }}>
            <InfoCardAddEditModal scopeId={scopeId} scope={scope} />
          </div>
        </Modal>
      </div>
      <hr />
      <GenericList
        status={status}
        data={infoCardsData}
        cols={[
          ["Id", "id", 1],
          ["Nazwa", "name", 2],
          ["Typ karty", "type", 3, (x: InfoCardType_Enum) => InfoCardType_Enum_Parser(x)],
          ["Zawartość", "body", 7, (x: string) => <>{html(x)}</>],
          [
            "",
            "",
            0,
            (x) => {
              return (
                <Modal
                  title={`Edycja karty`}
                  button={{
                    hidden: true,
                  }}
                  disableStandardButtons={true}
                  openTrigger={triggerId === "infoCard_addEdit_modal--" + x.id}
                  confirm={() => call({ scope: scope, scopeId: scopeId })}
                >
                  <div style={{ maxWidth: "1000px" }}>
                    <InfoCardAddEditModal scopeId={scopeId} infoCard={x} scope={scope} />
                  </div>
                </Modal>
              );
            },
          ],
        ]}
        actions={[
          "",
          "",
          1,
          (item: InfoCardDto) => (
            <Menu
              header="Opcje"
              items={[
                {
                  label: "Zarządzaj",
                  onAction: () => setTrigger("infoCard_addEdit_modal--" + item.id),
                },
                { label: "Usuń", onAction: () => Confirm(`Czy na pewno chcesz usunąć ${item.name}?`, () => deleteInfoCard(item.id)) },
              ]}
            />
          ),
        ]}
      />
    </>
  );
};

export default InfoCardsList;
