import Article from "./article/Article";
import Tag from "./tag/Tag";
import Image from "./image/Image";

class Blog {
  static Tag = Tag;
  static Article = Article;
  static Image = Image;
}

export default Blog;
