import React, { useEffect } from "react";
import { generatePath, useParams } from "react-router-dom";
import Api from "../../../../../../../api/Api";
import { OfficeSyncDto, SyncStatus__Enum, SyncStatus__Enum_Parser } from "../../../../../../../api/officeSync/officeSync.dto";
import { useQuery } from "../../../../../../../api/useQuerySlice";
import { html } from "../../../../../../../features/html";
import LoadingBtn from "../../../../../../../libs/buttons/Button/LoadingBtn";
import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import RefresherNew from "../../../../../../../libs/refresher/refresherNew";
import TextBlock from "../../../../../../../libs/text-block/text-block";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../components/Tile/Tile";
import OfficeSyncEditForm from "./sync-edit-form";
import OfficeSyncLog from "./sync-log";

const OfficeSyncEditPage: React.FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { data, status, silentCall } = useQuery<OfficeSyncDto, string>("officeSync/get", [Api.OfficeSync.Get, id], { callOnLoad: true });
  const {
    data: bridgeData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    status: bridgeStatus,
    silentCall: bridgeSilentCall,
  } = useQuery<OfficeSyncDto, string>("officeSync/get-bridge", [Api.OfficeSync.SilentGet, id], { callOnLoad: true, noToast: true });

  const { call: CallRunSync } = useQuery<void, number>("sync/runSync", [Api.Sync.RunSync], { callOnLoad: false });
  const { call: callStopSync } = useQuery<void, number>("sync/stopSync", [Api.Sync.StopSync], { callOnLoad: false });

  const runManualSync = () => {
    CallRunSync(Number(id));
    setTimeout(() => {
      getData();
    }, 100);
    setTimeout(() => {
      getData();
    }, 500);
  };

  const stopManualSync = () => {
    bridgeSilentCall(id);
    setTimeout(() => {
      callStopSync(Number(id));
    }, 500);
  };
  // const runSync = () => {
  //   CallRunSync(Number(id));
  // }

  const getData = () => {
    silentCall(id);
  };

  useEffect(() => {
    setInterval(() => {
      bridgeSilentCall(id);
    }, 1000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {/* <RefresherNew
        call={runSync}
        activeCondition={data?.syncStatus === SyncStatus__Enum.Waiting}
        activeFields={[data?.syncStatus]}
      /> */}
      <RefresherNew
        call={getData}
        activeCondition={data?.syncStatus === SyncStatus__Enum.Running || data?.syncStatus === SyncStatus__Enum.Waiting}
        activeFields={[data?.syncStatus]}
        intervalTime={1500}
      />
      {/* <Refresher
        id="sync/runSync"
        call={runSync}
        // setCallActive={setCallActive}
        data={data}
        activeCondition={data?.syncStatus === SyncStatus__Enum.Waiting}
      />
      <Refresher
        id="officeSync/get"
        call={getData}
        // setCallActive={setCallActive}
        data={data}
        activeCondition={data?.syncStatus === SyncStatus__Enum.Running || data?.syncStatus === SyncStatus__Enum.Waiting}
      /> */}

      <BreadcrumbsItem to={RoutePath.PANEL_OFFICES_SYNC_LIST} title="Moduł synchronizacji" />
      {data ? <BreadcrumbsItem to={generatePath(RoutePath.PANEL_OFFICES_SYNC_EDIT, { id: id })} title={`Edycja - ${data.name}`} /> : null}
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Ustawienia synchronizacji pracowni</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={[4, 6, 6, 12]}>
            <Tile>
              <h2 className="title">Parametry podstawowe</h2>
              {data ? <OfficeSyncEditForm data={data} status={status} /> : null}
            </Tile>
          </Col>
          <Col size={[6, 6, 6, 12]}>
            <Tile>
              <div>
                <h2 className="title mr-auto">Synchronizacja</h2>
              </div>
              <div className="flex">
                <TextBlock className="mr-auto">
                  <p>Status operacji:</p>
                  <p className="flex center-y">
                    {html(SyncStatus__Enum_Parser(data?.syncStatus))}
                    {/* <div className="ml-2 pt-0">
                      <LoadingSpinner
                        className="mt-1"
                        invert={true}
                        active={data?.syncStatus === SyncStatus__Enum.Running || data?.syncStatus === SyncStatus__Enum.Waiting}
                      />
                    </div> */}
                  </p>
                </TextBlock>
                {bridgeData?.syncStatus === SyncStatus__Enum.Waiting ? (
                  <LoadingBtn
                    onClick={stopManualSync}
                    className="mt-auto"
                    // variant="like-input-primary"
                    disabled={data?.syncStatus !== SyncStatus__Enum.Waiting}
                  >
                    Zatrzymaj
                  </LoadingBtn>
                ) : (
                  <LoadingBtn
                    onClick={runManualSync}
                    className="mt-auto"
                    status={data?.syncStatus === SyncStatus__Enum.Running || data?.syncStatus === SyncStatus__Enum.Waiting ? "loading" : "default"}
                    // variant="like-input-primary"
                    disabled={data?.syncStatus === SyncStatus__Enum.Running || data?.syncStatus === SyncStatus__Enum.Waiting}
                  >
                    {data?.syncStatus === SyncStatus__Enum.Running || data?.syncStatus === SyncStatus__Enum.Waiting ? "Czekaj" : "Uruchom"}
                  </LoadingBtn>
                )}
              </div>
              <br />
              {data ? <OfficeSyncLog data={data} status={status} /> : null}
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default OfficeSyncEditPage;
