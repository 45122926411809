import React, { useEffect, useState } from "react";
import Button, { ButtonProps } from "../../../libs/buttons/Button/Button";
import "./Modal.scss";
import { ModalContext } from "./ModalContext";

interface ModalProps {
  title: string | React.ReactNode;
  openTrigger?: boolean;
  button: {
    hidden?: boolean;
    disabled?: boolean;
    customButton?: React.ReactNode;
    customButtonId?: string;
    title?: string;
  } & Pick<ButtonProps, "variant">;
  disableStandardButtons?: boolean;
  confirm: (data: any) => void;
  children: React.ReactNode;
  maxWidth?: number;
  className?: string;
}

const Modal: React.FC<ModalProps> = (props) => {
  const { button, className = "", openTrigger, title, children, disableStandardButtons, confirm } = props;
  const [isOpen, setIsOpen] = useState<boolean>();
  const [data, setData] = useState<any>(null);

  const handleOpen = (e: React.MouseEvent<any, MouseEvent>) => {
    setIsOpen(true);
  };
  const preventClose = (e: React.MouseEvent<any, MouseEvent>) => {
    e.stopPropagation();
  };
  const handleClose = (e: React.MouseEvent<any, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen(false);
  };
  const handleOK = (e: React.MouseEvent<any, MouseEvent>) => {
    e.stopPropagation();
    handleConfirm();
  };
  const handleConfirm = () => {
    setIsOpen(false);
    confirm(data);
    setData(null);
  };
  useEffect(() => {
    if (openTrigger === true) setIsOpen(true);
  }, [openTrigger]);

  return (
    <>
      <ModalContext.Provider value={{ data: data, setData: setData, handleConfirm: handleConfirm }}>
        {button.hidden !== true ? (
          button.customButton ? (
            <div onClick={handleOpen}>{button.customButton}</div>
          ) : (
            <Button className="open-modal-button" disabled={button.disabled} variant={button.variant} type="button" onClick={handleOpen}>
              {button.title}
            </Button>
          )
        ) : null}
        {isOpen ? (
          <div className={`ModalWrapper ${className} ${isOpen ? " --isOpen" : ""}`} onClick={preventClose} onMouseDown={handleClose}>
            <div className="Modal" onClick={preventClose} onMouseDown={preventClose} style={{ maxWidth: props.maxWidth ? props.maxWidth + "px" : undefined }}>
              <div className="modal-header">
                <div className="Modal_title">{title}</div>
                <div className="cancel-icon" onClick={handleClose} />
              </div>
              <div className="modal-body">{isOpen ? <>{children}</> : null}</div>
              {disableStandardButtons ? null : (
                <div className="modal-footer-wrapper">
                  <div className="modal-footer">
                    <Button variant="light" type="button" onClick={handleClose}>
                      Anuluj
                    </Button>
                    <Button type="button" disabled={button.disabled} onClick={handleOK}>
                      OK
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </ModalContext.Provider>
    </>
  );
};

export default Modal;