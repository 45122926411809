import { Navigate, Outlet } from "react-router-dom";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import ExactPathSwitch from "../../../../../../components/ExactPathSwitch/ExactPathSwitch";

const TagsLayout = () => {

  return (
    <>
      <BreadcrumbsItem to={RoutePath.PANEL_KNOWLEDGEBASE_TAGS} title="Kategorie" isNotLink={true} />
      <ExactPathSwitch
        path={RoutePath.PANEL_KNOWLEDGEBASE_TAGS}
        if_exact={<Navigate to={RoutePath.PANEL_KNOWLEDGEBASE_TAGS_LIST} />}
        else={<Outlet />}
      />
    </>
  );
};

export default TagsLayout;
