import { generatePath, useParams } from "react-router-dom";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import Col from "../../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../../components/Tile/Tile";
import ArticleEditForm from "./forms/ArticleEditForm";
import RelatedTags from "./related-tags/related-tags-list";
import { ArticleDto } from "../../../../../../../../api/blog/article/article.dto";
import MediaList from "./media-list/media-list";
import ArticleContentEditForm from "./forms/ArticleContentEditForm";

const ArticleEditPage = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { data: article } = useQuery<ArticleDto, number>("article/get", [Api.Blog.Article.Get, Number(id)], {
    statusResetDelay: 1000,
  });

  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES_LIST} title="Lista" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES_EDIT, { id: id })} title="Edytuj artykuł" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Edytuj artykuł</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={[7, 12, 12, 12]}>
            <Tile>
              <h2 className="title">Treść artykułu</h2>
              <ArticleContentEditForm data={article} />
            </Tile>
          </Col>
          <Col size={[5, 12, 12, 12]} vif={article?.id}>
            <Tile>
              <h2 className="title">Podstawowe informacje</h2>
              <ArticleEditForm data={article} />
            </Tile>
            <br />
            <Tile>
              <MediaList scopeId={Number(id)} />
            </Tile>
            <br />
            <Tile>
              <RelatedTags articleId={Number(id)} />
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={[8, 10, 12, 12]} offset={[2, 1, 0]}>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default ArticleEditPage;
