import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { RoutePath } from "../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../components/Breadcrumbs/BreadcrumbsItem";
import ExactPathSwitch from "../../../../../components/ExactPathSwitch/ExactPathSwitch";

const SettingsLayout: React.FC = () => {

  return (
    <>
      <BreadcrumbsItem to={RoutePath.PANEL_SETTINGS} title="Ustawienia" isNotLink={true} />
      <ExactPathSwitch
        path={RoutePath.PANEL}
        if_exact={<Navigate to={RoutePath.PANEL_SETTINGS_ACCOUNT} />}
        else={<Outlet />}
      />
    </>
  );
};

export default SettingsLayout;
