import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { ProjectCategoryContentDto, ProjectCategoryDto } from "../../../../../../../../../api/projectCategory/projectCategory.dto";
import Api from "../../../../../../../../../api/Api";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import { ToastsStore } from "react-toasts";
import EditorJsInput from "../../../../../../../../../libs/forms/EditorJsBox/EditorJsInput";

interface ProjectCategoryContentEditFormProps {
  data: ProjectCategoryDto | undefined;
}

interface FormDto {
  content: string;
}

const ProjectCategoryContentEditForm = (props: ProjectCategoryContentEditFormProps) => {
  const { data } = props;
  // const { refetch } = useQueryRefetch();
  const { call, status: updateStatus } = useQuery<void, ProjectCategoryContentDto>("project-category/updateContent-" + data?.id, [Api.ProjectCategory.UpdateProjectCategoryDescriptionBottom], { callOnLoad: false });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormDto>({ defaultValues: { content: data?.description_bottom } });

  const onSubmit = async (form: FormDto) => {
    call({ id: data?.id, content: form.content } as ProjectCategoryContentDto);
  };

  useEffect(() => {
    reset({content: data?.description_bottom});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Zapisano");
      // refetch(Number(id));
      // refetch("project-category/get");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
        {/* <WYSIWYGEditor
          id="description"
          label="Opis ogólny"
          validation_msg={errors?.description?.message}
          inputProps={{
            control: control,
            defaultValue: project-categoryLang?.description,
          }}
        /> */}
        {/* <EditorJsInput */}
        <EditorJsInput
          id="content"
          label="Content"
          validation_msg={errors?.content?.message}
          inputProps={{
            type: "textarea",
            ...register("content"),
          }}
        />
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
          Zapisz
        </LoadingBtn>
      </Form>
    </>
  );
};

export default ProjectCategoryContentEditForm;
