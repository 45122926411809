import React, { useEffect, useRef, useState } from "react";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./WysiwigEditor.scss";
import { Controller } from "react-hook-form";

interface WYSIWYGEditorProps {
  id: string;
  label?: string;
  name?: string;
  validation_msg?: string;
  required?: boolean;
  className?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement> & {
    disabled?: boolean;
    control?: any;
    rules?: any;
    placeholder?: string;
  };
}

const WYSIWYGEditor: React.FC<WYSIWYGEditorProps> = (props) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const refWrapper = useRef<any>();
  const { id, label, className, name, validation_msg, required, inputProps = { rules: undefined, disabled: undefined } } = props;

  useEffect(() => {
    if ((refWrapper as any).value) {
      var blocksFromHtml = convertFromHTML((refWrapper as any).value ?? "<p></p>");
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      var editorStatee = EditorState.createWithContent(contentState);
      editorStatee = EditorState.moveSelectionToEnd(editorStatee);
      setEditorState(editorStatee);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(refWrapper as any).value]);

  return (
    <div ref={refWrapper} className={`form-child wysiwig-editor ${className} ${inputProps.disabled ? " wysiwig-editor--disabled" : ""}`} title={name}>
      <Controller
        name={id}
        control={inputProps.control}
        render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, isTouched, isDirty, error }, formState }) => {
          if (((refWrapper as any).value === undefined || (refWrapper as any).value === null) || (refWrapper as any).value ==="<p></p>") {
            // && (value !== null || value !== undefined)
            (refWrapper as any).value = value ?? "<p></p>";
          }
          return (
            <>
              <label htmlFor={id}>
                <span className="label">{label + (required ? "*" : "")}</span>{" "}
                <span className={`validation-messages ${validation_msg ? "active" : ""}`}>{validation_msg ? <>({validation_msg})</> : <>&nbsp;</>}</span>
              </label>
              <Editor
                editorState={editorState}
                readOnly={inputProps.disabled}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                defaultEditorState={editorState}
                toolbar={{
                  options: ["inline", "blockType", "fontSize", "list", "textAlign", "colorPicker", "link"],
                  inline: {
                    options: ["bold", "italic", "underline"],
                  },
                }}
                onEditorStateChange={(editorState: any) => {
                  setEditorState(editorState);
                  onChange(editorState ? draftToHtml(convertToRaw(editorState.getCurrentContent())) : null);
                }}
              />
            </>
          );
        }}
      />
    </div>
  );
};

export default WYSIWYGEditor;
