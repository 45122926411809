import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../libs/forms/validators/validators";
import Api from "../../../../../../../api/Api";
import { useQuery, useQueryRefetch } from "../../../../../../../api/useQuerySlice";
import { ToastsStore } from "react-toasts";
import SelectBox from "../../../../../../../libs/forms/SelectBox/SelectBox";
import { mapEnum } from "../../../../../../../features/enumMap";
import {
  SyncVariant__Enum,
  SyncVariant__Enum_Parser,
  SyncPeriod__Enum,
  SyncPeriod__Enum_Parser,
  OfficeSyncDto,
  SyncImagesPolicy__Enum,
  SyncImagesPolicy__Enum_Parser,
} from "../../../../../../../api/officeSync/officeSync.dto";
import SelectDataTimeBox from "../../../../../../../libs/forms/SelectDataTimeBox/SelectDataTimeBox";
import DateHelper from "../../../../../../../features/date-helper";

interface OfficeSyncEditFormProps {
  data: OfficeSyncDto;
  status: "default" | "loading" | "finished" | "failed";
}

const OfficeSyncEditForm: React.FC<OfficeSyncEditFormProps> = (props) => {
  const { data } = props;
  const { call, status: updateStatus } = useQuery<void, OfficeSyncDto>("officeSync/update", [Api.OfficeSync.Update], { callOnLoad: false });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm<OfficeSyncDto>({ defaultValues: data });
  const { refetch } = useQueryRefetch();

  const onSubmit = async (dto: OfficeSyncDto) => {
    call({ ...data, ...dto, lastSync_At: null });
  };

  useEffect(() => {
    console.log("data?.lastSync_At: ", data?.lastSync_At);
    if (data?.lastSync_At) {
      reset({ ...data, lastSync_At: DateHelper.ConvertZone__API_to_UI(data?.lastSync_At ?? undefined).toString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Dane zostały zmienione");
      refetch("office/get");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
        <TextInput
          id="name"
          label="Nazwa parsera"
          validation_msg={errors?.name?.message}
          inputProps={{
            disabled: true,
            ...register("name"),
          }}
        />
        <SelectDataTimeBox
          id="lastSync_At"
          label="Data ostatniej synchronizacji"
          validation_msg={errors?.lastSync_At?.message}
          format={"dd.MM.yyyy HH:mm:ss"}
          inputProps={{
            disabled: true,
            control: control
          }}
        />
        <SelectBox
          id="syncVariant"
          label="Wariant"
          validation_msg={errors?.syncVariant?.message}
          options={mapEnum(SyncVariant__Enum, (x: SyncVariant__Enum) => {
            return { id: x, label: SyncVariant__Enum_Parser(x) };
          })}
          required={true}
          inputProps={{
            placeholder: "Wariant",
            ...register("syncVariant", Validators.required),
          }}
        />
        <TextInput
          id="syncPath"
          label="Źródło danych"
          validation_msg={errors?.syncPath?.message}
          required={true}
          inputProps={{
            ...register("syncPath", Validators.required),
          }}
        />
        <SelectBox
          id="syncPeriod"
          label="Cykl"
          validation_msg={errors?.syncPeriod?.message}
          options={mapEnum(SyncPeriod__Enum, (x: SyncPeriod__Enum) => {
            return { id: x, label: SyncPeriod__Enum_Parser(x) };
          })}
          required={true}
          inputProps={{
            placeholder: "Wariant",
            ...register("syncPeriod", Validators.required),
          }}
        />
        <SelectBox
          id="syncImagesPolicy"
          label="Synchronizacja obrazów"
          validation_msg={errors?.syncImagesPolicy?.message}
          options={mapEnum(SyncImagesPolicy__Enum, (x: SyncImagesPolicy__Enum) => {
            return { id: x, label: SyncImagesPolicy__Enum_Parser(x) };
          })}
          required={true}
          inputProps={{
            placeholder: "Synchronizacja grafik",
            ...register("syncImagesPolicy", Validators.required),
          }}
        />
        <TextInput
          id="lastSync_Checksum"
          label="XML Checksum"
          validation_msg={errors?.lastSync_Checksum?.message}
          inputProps={{
            ...register("lastSync_Checksum"),
          }}
        />
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
          Zapisz
        </LoadingBtn>
      </Form>
    </>
  );
};

export default OfficeSyncEditForm;
