import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { ToastsStore } from "react-toasts";
import { SafeUserDto } from "../../../../../../../../api/user/user.dto";
import { useParams } from "react-router-dom";

interface UserEditFormProps { }

const UserEditForm: React.FC<UserEditFormProps> = (props) => {
  const { id = "" } = useParams<{ id: string }>();
  const { call: refetch, data } = useQuery<SafeUserDto, string>('user/get', [Api.User.Get, id], { statusResetDelay: 1000 });
  const { call, status: updateStatus } = useQuery<void, SafeUserDto>('user/update', [Api.User.Update], { callOnLoad: false });
  const { register, handleSubmit, formState: { errors }, reset } = useForm<SafeUserDto>({ defaultValues: {} });

  const onSubmit = async (dto: SafeUserDto) => {
    call({ ...data, ...dto });
  };

  useEffect(() => {
    reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (updateStatus === 'finished') {
      ToastsStore.success("Dane zostały zmienione");
      refetch(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus])


  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
        <TextInput
          id="name"
          label="Nazwa"
          validation_msg={errors?.name?.message}
          inputProps={{
            ...register("name", Validators.required)
          }}
        />
        <TextInput
          id="email"
          label="Email"
          validation_msg={errors?.email?.message}
          inputProps={{
            ...register("email", Validators.required),
            type: "email"
          }}
        />
        <TextInput
          id="phone"
          label="Telefon"
          validation_msg={errors?.phone?.message}
          inputProps={{
            ...register("phone", Validators.required)
          }}
        />
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">Zapisz</LoadingBtn>
      </Form>
    </>
  );
};

export default UserEditForm;
