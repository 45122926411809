import React from "react";
import { generatePath, useParams } from "react-router-dom";
import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../components/Tile/Tile";
import UserEditForm from "./user-edit-form/UserEditForm";
import UserEditPasswordForm from "./user-edit-form/UserEditPasswordForm";
import UserRolesEditForm from "./user-edit-form/UserRolesEditForm";

const UserEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_USERS_LIST} title="Lista" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_USERS_EDIT, { id: id })} title="Edycja użytkownika" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Edycja użytkownika</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={4}>
            <Tile>
              <h2 className="title">Edycja Danych</h2>
              <UserEditForm />
            </Tile>
          </Col>
          <Col size={4}>
            <Tile>
              <h2 className="title">Zmiana hasła</h2>
              <UserEditPasswordForm />
            </Tile>
          </Col>
          <Col size={4}>
            <Tile>
              <h2 className="title">Edycja uprawnień</h2>
              <UserRolesEditForm />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div >
  );
};

export default UserEditPage;
