import React from "react";
import BoxTile from "../../../../libs/containers/BoxTile/BoxTile";
import SignInForm from "./SignInForm/SignInForm";

const SignInPage: React.FC = () => {
  return (
    <BoxTile maxW={440} title={<h1>Zaloguj się</h1>}>
      <SignInForm />
    </BoxTile>
  );
};

export default SignInPage;
