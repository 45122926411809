export enum SystemScopeEnum {
  Global = 1,
  Tag = 2,
  Article = 3
}

export enum ImageFieldEnum {
  MainPicture = 1,
  Icon = 2,
  MainPictureGallery = 3
}

export const ImageFieldEnum_Parser = (x: ImageFieldEnum) => {
  switch (x) {
    case ImageFieldEnum.MainPicture:
      return "Główny obraz";
    case ImageFieldEnum.Icon:
      return "Ikona";
    case ImageFieldEnum.MainPictureGallery:
      return "Standardowy obraz";
    default:
      return "";
  }
};

export interface BlogImageDto {
  id: number;
  path: string;
  alt?: string;
  field?: ImageFieldEnum;
  description?: string;
}

export interface UploadImageDto {
  id?: number;
  alt?: string;
  file: any;
  field?: ImageFieldEnum;
  scopeId?: number;
  scope: SystemScopeEnum;
  description?: string;
}