import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Api from "../../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import { RoutePath } from "../../../../../../../../../route-paths";
import { TagDto } from "../../../../../../../../../api/blog/tag/tag.dto";
import { ToastsStore } from "react-toasts";

const TagAddForm = () => {
  const { call, status: updateStatus } = useQuery<void, TagDto>("tag/add", [Api.Blog.Tag.Add], { callOnLoad: false });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<TagDto>({ defaultValues: {} });
  const navigate = useNavigate();

  const onSubmit = async (dto: TagDto) => {
    call({
      ...dto,
      description: "",
      meta_description: "",
      meta_title: "",
    });
  };

  useEffect(() => {
    if (updateStatus === "finished") {
      reset();
      ToastsStore.success("Dodano");
      navigate(RoutePath.PANEL_KNOWLEDGEBASE_TAGS_LIST as string);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
      <TextInput
        id="name"
        label="Nazwa"
        validation_msg={errors?.name?.message}
        required
        inputProps={{
          ...register("name", Validators.required),
        }}
      />
      <TextInput
        id="slug"
        label="Slug"
        validation_msg={errors?.slug?.message}
        required
        inputProps={{
          ...register("slug", Validators.required),
        }}
      />
      <br />
      <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
        Zapisz
      </LoadingBtn>
    </Form>
  );
};

export default TagAddForm;
