import { generatePath, useParams } from "react-router-dom";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import Col from "../../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../../components/Tile/Tile";
import ProjectCategoryEditForm from "./forms/ProjectCategoryEditForm";
import { ProjectCategoryDto } from "../../../../../../../../api/projectCategory/projectCategory.dto";
import MediaList from "./media-list/media-list";
import ProjectCategoryContentEditForm from "./forms/ProjectCategoryContentEditForm";
import RelatedProjects from "./related-projects/related-projects-list";

const ProjectCategoryEditPage = () => {
  const { categoryId = "" } = useParams<{ categoryId: string }>();
  const { data: projectCategory } = useQuery<ProjectCategoryDto, number>("project-category/get", [Api.ProjectCategory.Get, Number(categoryId)], {
    statusResetDelay: 1000,
  });

  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_PROJECTCATEGORIES_LIST} title="Lista" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_PROJECTCATEGORIES_EDIT, { categoryId: categoryId })} title="Edytuj kategorie" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Edytuj kategorię</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={[7, 12, 12, 12]}>
            <Tile>
              <h2 className="title">Treść kategorii</h2>
              <ProjectCategoryContentEditForm data={projectCategory} />
            </Tile>
          </Col>
          <Col size={[5, 12, 12, 12]} vif={projectCategory?.id}>
            <Tile>
              <h2 className="title">Podstawowe informacje</h2>
              <ProjectCategoryEditForm data={projectCategory} />
            </Tile>
            <br />
            <Tile>
              <MediaList scopeId={Number(categoryId)} />
            </Tile>
            <br />
            <Tile>
              <RelatedProjects categoryId={Number(categoryId)} />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default ProjectCategoryEditPage;
