import React from "react";
import { generatePath, useParams } from "react-router-dom";
import Api from "../../../../../../../api/Api";
import { OfficeDto } from "../../../../../../../api/office/office.dto";
import { SystemScope_Enum } from "../../../../../../../api/shared/System.dto";
import { useQuery } from "../../../../../../../api/useQuerySlice";
import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../components/Tile/Tile";
import AddonsList from "../../../panel-shares/addons-list/addons-list";
import InfoCardsList from "../../../panel-shares/infoCards-list/infoCards-list";
import BenefitsList from "./benefits-list";
import OfficeEditForm from "./edit-form";
import MassOperations from "./mass-operations";
import PriceModificatorEditForm from "./priceModificator-form";
import PromotionEditForm from "./promotion-form";

const OfficeEditPage: React.FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { data, status } = useQuery<OfficeDto, string>("office/get", [Api.Office.Get, id], { callOnLoad: true });

  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_OFFICES_LIST} title="Lista" />
      {data ? <BreadcrumbsItem to={generatePath(RoutePath.PANEL_OFFICES_EDIT, { id: id })} title={`Edycja - ${data.name}`} /> : null}
      <PanelGrid>
        {data ? (
          <>
            <PanelRow>
              <Col size={12}>
                <Tile>
                  <h1>Ustawienia pracowni - {data.name}</h1>
                </Tile>
              </Col>
            </PanelRow>
            <PanelRow>
              <Col size={[8, 8, 8, 12]}>
                <Tile>
                  <h2 className="title">Parametry podstawowe</h2>
                  <hr />
                  <OfficeEditForm data={data} status={status} />
                </Tile>
              </Col>
            </PanelRow>
            <PanelRow>
              {/* {data.promotion ?
                  : null} */}
              <Col size={[8, 8, 8, 12]}>
                <Tile>
                  <div className="d-flex">
                    <h2 className="title mr-auto">Modyfikator cen promocyjnych</h2>
                  </div>
                  <hr />
                  <PromotionEditForm data={data} status={status} />
                </Tile>
              </Col>
              <Col size={[4, 4, 8, 12]}>
                <Tile>
                  <div className="d-flex">
                    <h2 className="title mr-auto">Modyfikator cen bazowych</h2>
                  </div>
                  <hr />
                  <PriceModificatorEditForm data={data} status={status} />
                </Tile>
              </Col>
            </PanelRow>
            <PanelRow>
              <Col size={12}>
                <Tile>
                  <InfoCardsList scopeId={data.id} scope={SystemScope_Enum.Office} />
                </Tile>
              </Col>
            </PanelRow>
            <PanelRow>
              <Col size={12}>
                <Tile>
                  <BenefitsList data={data} status={status} />
                </Tile>
              </Col>
            </PanelRow>
            {data && !Number.isNaN(data.id) && data.id != null ? (
              <PanelRow>
                <Col size={12}>
                  <Tile>
                    <AddonsList scopeId={data.id} scope={SystemScope_Enum.Office} />
                  </Tile>
                </Col>
              </PanelRow>
            ) : null}
            <PanelRow>
              <Col size={[6, 8, 12, 12]}>
                <Tile>
                  <MassOperations data={data} status={status} />
                </Tile>
              </Col>
            </PanelRow>
          </>
        ) : null}
      </PanelGrid>
    </div>
  );
};

export default OfficeEditPage;
