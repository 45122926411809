import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import { OrderDto } from "../../../../../../../../api/order/order.dto";
import DateHelper from "../../../../../../../../features/date-helper";
import TextBlock from "../../../../../../../../libs/text-block/text-block";

interface BasicInfoFormProps {
  order: OrderDto;
}

interface BasicOrderInfo {
  id: number;
  createdDate: string;
  totalPrice: string;
  delivery?: string;
  payment?: string;
}

const BasicInfoForm: React.FC<BasicInfoFormProps> = (props) => {
  const { order } = props;
  const { register, reset } = useForm<BasicOrderInfo>({ defaultValues: {} });

  const capitalize = (str: string) => {
    if (str && str.length > 1) return str.charAt(0).toUpperCase() + str.slice(1);
    else return "-";
  };

  useEffect(() => {
    const orderBasicInfo = {
      id: order.id,
      createdDate: DateHelper.ConvertZone__API_to_UI__formated(order.createdDate.toString()),
      totalPrice: order.totalPrice.toFixed(2) + " zł",
    } as BasicOrderInfo;
    try {
      var cart = JSON.parse(order.cart);
      orderBasicInfo.delivery = capitalize(cart?.Selected__delivery?.replace("-", " "));
      orderBasicInfo.payment = capitalize(cart?.Selected__payment?.replace("-", " "));
    } catch (error) {
      console.log(error);
    }
    reset(orderBasicInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <>
      <Form>
        <div className="form-child form-child-wrapper">
          <h3 className="title form-child">Informacje podstawowe</h3>
          <TextInput
            id="id"
            label="Id"
            inputProps={{
              ...register("id"),
              disabled: true,
            }}
          />
          <TextInput
            id="createdDate"
            label="Data zamówienia"
            inputProps={{
              ...register("createdDate"),
              disabled: true,
            }}
          />
          <TextInput
            id="totalPrice"
            label="Wartość zamówienia brutto"
            inputProps={{
              ...register("totalPrice"),
              disabled: true,
            }}
          />
          <TextInput
            id="payment"
            label="Metoda płatności"
            inputProps={{
              ...register("payment"),
              disabled: true,
            }}
          />
          <TextInput
            id="delivery"
            label="Metoda obioru"
            inputProps={{
              ...register("delivery"),
              disabled: true,
            }}
          />
          {order.projectCopy?.cena__raw_brutto === null ? (
            <TextBlock>
              <p>
                <b className="red">+ indywidualna wycena projektu</b>
              </p>
            </TextBlock>
          ) : null}
        </div>
      </Form>
    </>
  );
};

export default BasicInfoForm;
