export enum SystemScope_Enum {
  Project = 1,
  Office = 2,
  Global = 3,
}

export interface IdNameDto {
  id: number;
  name: string;
  metadata: { [key: string]: string };
}

export interface MediaAddDto {
  field: ImgFieldEnum;
  scopeId: number;
  alt: string;
  description: string;
  files: any;
}

export interface MediaUpdateDto extends MediaAddDto {
  path: string;
}

export interface MediaDeleteDto {
  scopeId: number;
  path: string;
}

export enum ImgFieldEnum {
  MainPicture = 1,
  Icon = 2,
  MainPictureGallery = 3
}

export const ImgFieldEnum_Parser = (x: ImgFieldEnum) => {
  switch (x) {
    case ImgFieldEnum.MainPicture:
      return "Główny obraz";
    case ImgFieldEnum.Icon:
      return "Ikona";
    case ImgFieldEnum.MainPictureGallery:
      return "Standardowy obraz";
    default:
      return "";
  }
};