// tools.js
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
// import Paragraph from '@editorjs/paragraph'
import List from "@editorjs/list";
import Warning from "@editorjs/warning";
// import Code from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Image from "@editorjs/image";
// import Paragraph from "editorjs-paragraph-with-alignment";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Api from "../../../api/Api";
import { apiURL } from "../../../api/client";
import { IconWarning } from "@codexteam/icons";

// import Quote from '@editorjs/quote'
// import Marker from '@editorjs/marker'
// import CheckList from '@editorjs/checklist'
// import Delimiter from '@editorjs/delimiter'
// import InlineCode from '@editorjs/inline-code'
// import SimpleImage from '@editorjs/simple-image'
// const aaa = Warning.toolbox();
class CustomComponent extends Warning {
  static get toolbox() {
    return {
      icon: IconWarning,
      title: "Component",
    };
  }
}

export const EDITOR_JS_TOOLS = {
  // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
  //   paragraph: Paragraph,
  // paragraph: {
  //   class: Paragraph,
  //   inlineToolbar: true,
  // },
  linkTool: LinkTool,
  header: {
    class: Header,
    inlineToolbar: true,
    config: {
      placeholder: "Enter a header",
      levels: [2, 3, 4, 5, 6],
      defaultLevel: 2,
    },
  },
  list: {
    class: List,
    inlineToolbar: true,
  },
  table: Table,
  customComponent: {
    class: CustomComponent,
    config: {
      titlePlaceholder: "Component",
      messagePlaceholder: "Props",
    },
  },
  // code: Code,
  embed: Embed, 
  image: {
    class: Image,
    inlineToolbar: true,
    config: {
      uploader: {
        async uploadByFile(file: any) {
          const result = await Api.Blog.Image.UploadFile({ file: file });
          return {
            success: 1,
            file: {
              url: apiURL + result.imageUrl,
            },
          };
        },
      },
    },
  },
  raw: Raw,
  //   quote: Quote,
  //   marker: Marker,
  //   checklist: CheckList,
  //   delimiter: Delimiter,
  //   inlineCode: InlineCode,
  //   simpleImage: SimpleImage,
};
