import React from "react";
import { generatePath } from "react-router-dom";
import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import TextBlock from "../../../../../../../libs/text-block/text-block";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../components/Tile/Tile";
import ProjectAddForm from "./add-form";

const ProjectAddPage: React.FC = () => {
  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_PROJECTS_LIST} title="Lista" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_PROJECTS_ADD)} title="Dodawanie projektu" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Dodawanie projektu</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h2 className="title">Parametry podstawowe</h2>
              <hr />
              <TextBlock>
                <p>Formularz zawiera tylko niezbędne informacje potrzebne do utworzenia projektu. Pozostałe dane będą możliwe do uzpełnienia dalej.</p>
              </TextBlock>
              <ProjectAddForm />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default ProjectAddPage;
