import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Form from "../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../libs/forms/TextBox/TextInput";
import { OfficeSyncDto } from "../../../../../../../api/officeSync/officeSync.dto";

interface OfficeSyncLogProps {
  data: OfficeSyncDto;
  status: "default" | "loading" | "finished" | "failed";
}

const OfficeSyncLog: React.FC<OfficeSyncLogProps> = (props) => {
  const { data } = props;
  const { register, reset } = useForm<OfficeSyncDto>({ defaultValues: data });

  useEffect(() => {
    reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset(data);
    // scrollDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const scrollDown = () => {
    var textarea = document.getElementById("lastSync_Log");
    if (textarea) textarea.scrollTop = textarea.scrollHeight;
  };

  return (
    <>
      <Form cols={2}>
        <TextInput
          id="lastSync_Log"
          label="Zapis ostatniej synchronizacji"
          inputProps={{
            type: "textarea",
            rows: 30,
            disabled: true,
            ...register("lastSync_Log"),
          }}
        />
      </Form>
    </>
  );
};

export default OfficeSyncLog;
