import React, { useContext, useEffect, useState } from "react";
import Api from "../../../../../../../../../api/Api";
import { ArticleDto, RelatedTagRelationDto } from "../../../../../../../../../api/blog/article/article.dto";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import GenericList from "../../../../../../../../../libs/generic-list/generic-list";
import { ModalContext } from "../../../../../../../../components/Modal/ModalContext";
import "./related-tags-add-modal.scss";
import { IdNameDto } from "../../../../../../../../../api/shared/System.dto";

interface MediaAddEditModalProps {
  articleId: number;
}

const RelatedArticleAddModal: React.FC<MediaAddEditModalProps> = (props) => {
  const { articleId } = props;
  const { handleConfirm } = useContext(ModalContext);
  const [selectedItem, setSelectedItem] = useState<ArticleDto>();

  const { data: tagNames, call: callList, status: listStatus } = useQuery<Array<IdNameDto>>("tag/list", [Api.Blog.Tag.ListIdNames]);
  const { call, status } = useQuery<void, RelatedTagRelationDto>("article/AddRelatedTag", [Api.Blog.Article.AddRelatedTag], { callOnLoad: false });

  useEffect(() => {
    callList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    if (selectedItem && selectedItem?.id) {
      var addDto = {
        articleId: articleId,
        relatedTagId: selectedItem.id,
      } as RelatedTagRelationDto;
      call(addDto);
      callList();
    }
  };

  useEffect(() => {
    if (status === "finished") handleConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className="related-tags-add-modal">
      {tagNames ? (
        <>
          <div className="list-wrapper">
            <GenericList
              status={listStatus}
              data={tagNames}
              cols={[
                ["Id", "id", 1],
                ["Nazwa", "name", 11],
              ]}
              inputProps={{
                multiselect: false,
                onChange: (x: any) => setSelectedItem(x),
              }}
            />
          </div>
        </>
      ) : null}

      <div className="ml-auto pt-3">
        <LoadingBtn onClick={() => onSubmit()} status={status} className="ml-auto">
          Dodaj
        </LoadingBtn>
      </div>
    </div>
  );
};

export default RelatedArticleAddModal;
