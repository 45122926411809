import "./related-projects-add-modal.scss";
import { useContext, useEffect, useState } from "react";
import Api from "../../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import GenericList from "../../../../../../../../../libs/generic-list/generic-list";
import { ModalContext } from "../../../../../../../../components/Modal/ModalContext";
import { RelatedProjectCategoryRelationDto } from "../../../../../../../../../api/projectCategory/projectCategory.dto";
import { apiURL, ArrayResult } from "../../../../../../../../../api/client";
import { BasicColumnFilterDto } from "../../../../../../../../../libs/filters/filters.dto";
import { PaginationFilterDto } from "../../../../../../../../components/Pagination/pagination.dto";
import { Bud__RodzajZabudowy__Enum, Bud__RodzajZabudowy__Enum_Parser, SafeProjectDto } from "../../../../../../../../../api/project/project.dto";
import { OfficeNameDto } from "../../../../../../../../../api/office/office.dto";
import useEffectAfterMounted from "../../../../../../../../../features/useEffectAfterMounted";
import Pagination from "../../../../../../../../components/Pagination/Pagination";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import { mapEnum } from "../../../../../../../../../features/enumMap";
import Filters from "../../../../../../../../../libs/filters/FiltersForm";
import { ToastsStore } from "react-toasts";

interface Props {
  categoryId: number;
}

const RelatedProjectsAddModal = (props: Props) => {
  const { categoryId } = props;
  const { handleConfirm } = useContext(ModalContext);
  const [selectedItems, setSelectedItems] = useState<Array<SafeProjectDto>>();
  const [filters, setFilters] = useState<BasicColumnFilterDto>({
    otherFilters: [
      {
        searchByColumn: "notCategoryId",
        searchId: categoryId,
      },
    ],
  });
  const [pagination, setPagination] = useState<PaginationFilterDto>({ take: 10, skip: 0 });
  const {
    data: officesNames = [], //
  } = useQuery<Array<OfficeNameDto>>("office/listNames", [Api.Office.ListNames], { callOnLoad: true });
  const {
    data: projectsList, //
    call: callList,
    status: listStatus,
  } = useQuery<ArrayResult<SafeProjectDto>, BasicColumnFilterDto>("Project/List-all", [
    Api.Project.List,
    {
      ...filters,
      ...pagination,
      otherFilters: [
        {
          searchByColumn: "notCategoryId",
          searchId: categoryId,
        },
      ],
    },
  ]);
  const { call } = useQuery<void, RelatedProjectCategoryRelationDto>(
    "ProjectCategory/AddRelatedProjectCategory",
    [Api.ProjectCategory.AddProjectRelationCategory],
    {
      callOnLoad: false,
      noToast: true,
    }
  );

  const { call: callLast, status: statusLast } = useQuery<void, RelatedProjectCategoryRelationDto>(
    "ProjectCategory/AddRelatedProjectCategoryLast",
    [Api.ProjectCategory.AddProjectRelationCategory],
    {
      callOnLoad: false,
    }
  );

  useEffectAfterMounted(() => {
    callList({
      ...filters,
      ...pagination,
      otherFilters: [
        {
          searchByColumn: "notCategoryId",
          searchId: categoryId,
        },
      ],
    });
  }, [filters, pagination]);

  const onSubmit = () => {
    if (selectedItems && selectedItems?.length) {
      for (let i = 0; i < selectedItems.length; i++) {
        const selectedItem = selectedItems[i];
        const addDto = {
          categoryId: categoryId,
          projectId: selectedItem.id,
        } as RelatedProjectCategoryRelationDto;

        if (i < selectedItems.length - 1) {
          call(addDto);
        } else {
          callLast(addDto);
        }
      }
    }
  };

  useEffect(() => {
    if (statusLast === "finished") {
      ToastsStore.success("Powiązano");
      handleConfirm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusLast]);

  return (
    <div className="related-projects-categories-add-modal">
      {projectsList ? (
        <>
          <div className="list-wrapper">
            <Filters
              onSubmit={setFilters}
              setPagination={setPagination}
              status={listStatus}
              fields={[
                {
                  field: "searchByColumn",
                  component: "SelectBox",
                  label: "Kolumna",
                  options: [
                    { id: "nazwa", label: "Nazwa" },
                    { id: "bud__rodz_zabudowy", label: "Rodzaj zabudowy" },
                    { id: "officeId", label: "Pracownia" },
                  ],
                  conditionalFields: [
                    {
                      basedOn: "searchByColumn",
                      condition: (x: any) => x === "nazwa",
                      fields: [
                        { field: "searchText", component: "TextBox", placeholder: "Wyszukaj", label: "Zawartość kolumny", validators: Validators.required },
                      ],
                    },
                    {
                      basedOn: "searchByColumn",
                      condition: (x: any) => x === "bud__rodz_zabudowy",
                      fields: [
                        {
                          field: "searchEnum",
                          component: "SelectBox",
                          label: "Wyszukaj",
                          validators: Validators.required,
                          options: mapEnum(Bud__RodzajZabudowy__Enum, (key: any) => {
                            return {
                              id: key,
                              label: Bud__RodzajZabudowy__Enum_Parser(key),
                            };
                          }),
                        },
                      ],
                    },
                    {
                      basedOn: "searchByColumn",
                      condition: (x: any) => x === "officeId",
                      fields: [
                        {
                          field: "searchId",
                          component: "SelectBox",
                          label: "Wyszukaj",
                          validators: Validators.required,
                          options: officesNames.map((x) => {
                            return {
                              id: x.id,
                              label: x.name,
                            };
                          }),
                        },
                      ],
                    },
                  ],
                },
              ]}
            />
            <GenericList
              status={listStatus}
              data={projectsList.list}
              cols={[
                [
                  "Podgląd",
                  "img",
                  2,
                  (x) => (
                    <div
                      style={{
                        backgroundColor: "#f7f7f7",
                        backgroundImage: `url(${apiURL + x})`,
                        width: 48,
                        height: 32,
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                  ),
                ],
                ["Id", "id", 2],
                ["Nazwa", "nazwa", 8],
              ]}
              inputProps={{
                multiselect: true,
                onChange: (x: any) => setSelectedItems(x),
              }}
            />
            <Pagination
              disableScroll
              totalCount={projectsList?.totalCount}
              itemsPerPage={10}
              take={pagination.take!}
              skip={pagination.skip}
              setNewSkip={(newSkip: number) => setPagination({ ...pagination, skip: newSkip })}
            />
          </div>
        </>
      ) : null}

      <div className="ml-auto pt-3">
        <LoadingBtn onClick={() => onSubmit()} status={statusLast} className="ml-auto">
          Dodaj
        </LoadingBtn>
      </div>
    </div>
  );
};

export default RelatedProjectsAddModal;
