import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../../../../../../../../api/Api";
import {
  ProjectDeleteMediaDto,
  ImageDto,
  ProjectMediaTypeEnum,
  SafeProjectDto,
  ProjectOverwrittenParamUpdateDto,
} from "../../../../../../../../api/project/project.dto";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../features/confirm";
import GenericList from "../../../../../../../../libs/generic-list/generic-list";
import GenericListIcon from "../../../../../../../../libs/generic-list/generic-list-icon";
import { useTrigger } from "../../../../../../../components/GlobalTrigger/GlobalTriggerSlice";
import InputPolicyController from "../../../../../../../components/InputPolicyController/inputPolicyController";
import Menu from "../../../../../../../components/Menu/menu";
import Modal from "../../../../../../../components/Modal/Modal";
import MediaAddEditModal from "./media-addEdit-modal";

interface PlansListProps {
  mediaType: ProjectMediaTypeEnum;
}

const MediaList: React.FC<PlansListProps> = (props) => {
  const { projectId = "" } = useParams<{ projectId: string }>();
  const { mediaType } = props;
  const [overwritten_params, setOverwritten_params] = useState<Array<string>>([]);
  const [id_fromXml, setId_fromXml] = useState<string>("");
  const { setTrigger, triggerId } = useTrigger();

  const { call, status, data } = useQuery<SafeProjectDto, string>("project/get", [Api.Project.Get, projectId], { callOnLoad: true });
  const { call: deleteMedia, status: deleteMediaStatus } = useQuery<void, ProjectDeleteMediaDto>("project/deleteMedia", [Api.Project.DeleteMedia], {
    callOnLoad: false,
  });

  const { call: updateOverwrittenParam, status: updateOverwrittenParamStatus } = useQuery<void, ProjectOverwrittenParamUpdateDto>(
    "project/deleteMedia",
    [Api.Project.UpdateOverwrittenParam],
    {
      callOnLoad: false,
    }
  );

  useEffect(() => {
    if (deleteMediaStatus === "finished" || updateOverwrittenParamStatus === "finished") call(projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMediaStatus, updateOverwrittenParamStatus]);

  useEffect(() => {
    setId_fromXml(data?.id_fromXml ?? "");
    setOverwritten_params(data?.overwritten_params ?? []);
  }, [data]);

  useEffect(() => {}, [overwritten_params]);

  const handleSetOverwritten_params = () => {
    var dto: ProjectOverwrittenParamUpdateDto = {
      isOverwritten: overwritten_params.findIndex((x) => x === ProjectMediaTypeEnum[mediaType]) === -1,
      projectId: projectId,
      param: ProjectMediaTypeEnum[mediaType],
    };
    updateOverwrittenParam(dto);
  };

  return (
    <>
      <div className="d-flex pb-3">
        <div className="mr-auto">
          {id_fromXml ? (
            <InputPolicyController
              className="aboveTileTitle"
              propName={ProjectMediaTypeEnum[mediaType]}
              setOverwritten_params={handleSetOverwritten_params}
              overwritten_params={overwritten_params}
            />
          ) : null}
          <h2 className="title pr-4">
            {mediaType === ProjectMediaTypeEnum.Visualizations ? "Wizualizacje" : null}
            {mediaType === ProjectMediaTypeEnum.Plans ? "Rzuty" : null}
            {mediaType === ProjectMediaTypeEnum.Elevations ? "Elewacje" : null}
          </h2>
        </div>
        <Modal
          title={`Dodaj nowy`}
          disableStandardButtons={true}
          button={{
            disabled: overwritten_params.findIndex((x) => x === ProjectMediaTypeEnum[mediaType]) === -1,
            title: `+ Dodaj nowy`,
            variant: "like-input-primary",
          }}
          maxWidth={600}
          confirm={() => call(projectId)}
        >
          <MediaAddEditModal projectId={Number(projectId)} projectMediaType={mediaType} />
        </Modal>
      </div>
      <hr />
      {
        <GenericList
          status={status}
          data={
            data
              ? [
                  ...(mediaType === ProjectMediaTypeEnum.Plans ? data.plans : []),
                  ...(mediaType === ProjectMediaTypeEnum.Visualizations ? data.visualizations : []),
                  ...(mediaType === ProjectMediaTypeEnum.Elevations ? data.elevations : []),
                ]
              : undefined
          }
          cols={[
            ["Obraz", "path", 2, (x) => <GenericListIcon img={process.env.REACT_APP_API_URL + x} size="xl" openOnClick={true} />],
            ["Alt", "alt", 2],
            ["Ścieżka", "path", 7],
            [
              "",
              "",
              0,
              (x) => {
                return (
                  <Modal
                    title={`Edycja obrazu`}
                    maxWidth={600}
                    button={{
                      hidden: true,
                    }}
                    disableStandardButtons={true}
                    openTrigger={triggerId === "media" + ProjectMediaTypeEnum[mediaType] + "_addEdit_modal--" + x.path}
                    confirm={() => call(projectId)}
                  >
                    <MediaAddEditModal projectId={Number(projectId)} item={x} projectMediaType={mediaType} />
                  </Modal>
                );
              },
            ],
          ]}
          actions={[
            "",
            "",
            1,
            (item: ImageDto) => (
              <Menu
                header="Opcje"
                disabled={overwritten_params.findIndex((x) => x === ProjectMediaTypeEnum[mediaType]) === -1}
                items={[
                  {
                    label: "Zarządzaj",
                    onAction: () => setTrigger("media" + ProjectMediaTypeEnum[mediaType] + "_addEdit_modal--" + item.path),
                  },
                  {
                    label: "Usuń",
                    onAction: () =>
                      Confirm(`Czy na pewno chcesz usunąć ${item.alt}?`, () =>
                        deleteMedia({
                          path: item.path,
                          projectId: projectId,
                          projectMediaType: ProjectMediaTypeEnum[mediaType],
                        })
                      ),
                  },
                ]}
              />
            ),
          ]}
        />
      }
    </>
  );
};

export default MediaList;
