import { apiURL } from "../../../../../../../../api/client";
import { store } from "../../../../../../../../redux/store";

const FileDownloadButton = ({ id }: { id: string }) => {
  const token = store.getState().session.session?.token;

  const handleDownload = () => {
    fetch(`${apiURL}api/office/CreateXML?officeId=${id}`, {
      method: "GET",
      headers: {
        Authorization: `${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = `pracownia-${id}.xml`;
        downloadLink.click();
      })
      .catch((error) => {
        console.error("Błąd pobierania pliku:", error);
      });
  };

  return (
    <div onClick={handleDownload} className="select-menu__modal__item">
      <div>Eksportuj XML</div>
    </div>
  );
};

export default FileDownloadButton;
