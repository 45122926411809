/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../libs/forms/validators/validators";
import Api from "../../../../../../../api/Api";
import { useQuery } from "../../../../../../../api/useQuerySlice";
import { ToastsStore } from "react-toasts";
import { OfficeDto, PromotionDto, PromotionValueType_Enum, PromotionValueType_Enum_Parser } from "../../../../../../../api/office/office.dto";
import { enumsToString, mapEnum } from "../../../../../../../features/enumMap";
import InputBuilder, { AdvancedInputField } from "../../../../../../../libs/filters/InputBuilder";
import CheckBoxInput from "../../../../../../../libs/forms/CheckBox/CheckBox";
import "./promotion-form.scss";
import TextBlock from "../../../../../../../libs/text-block/text-block";
import DateHelper from "../../../../../../../features/date-helper";
import { Bud__RodzajZabudowy__Enum } from "../../../../../../../api/project/project.dto";

interface OfficeEditFormProps {
  data: OfficeDto;
  status: "default" | "loading" | "finished" | "failed";
}

const PromotionEditForm: React.FC<OfficeEditFormProps> = (props) => {
  const { data } = props;
  const { call, status: updateStatus } = useQuery<void, PromotionDto>("office/update-promotion", [Api.Office.UpdatePromotion], { callOnLoad: false });
  const {
    register,
    watch,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<PromotionDto>({ defaultValues: data.promotion });

  const onSubmit = async (dto: PromotionDto) => {
    if (window.confirm("Czy na pewno chcesz zapisać ustawienia promocji? Ta zmiana może wpłynąć na wszystkie projekty przypisane do tej pracowni z wyłączeniem garaży, gospodarczych i saun.")) {
      call({
        ...data.promotion,
        ...dto,
        activeFrom: DateHelper.ConvertZone__UI_to_API(dto.activeFrom),
        activeTo: DateHelper.ConvertZone__UI_to_API(dto.activeTo),
        officeId: data.id,
        excludedBudRodzZabudowyEnums: enumsToString<Bud__RodzajZabudowy__Enum>([
          Bud__RodzajZabudowy__Enum.Garaz,
          Bud__RodzajZabudowy__Enum.Gospodarczy,
          Bud__RodzajZabudowy__Enum.Sauna,
        ]),
      });
    }
  };

  useEffect(() => {
    reset({
      ...data.promotion,
      activeFrom: DateHelper.ConvertZone__API_to_UI(data.promotion?.activeFrom),
      activeTo: DateHelper.ConvertZone__API_to_UI(data.promotion?.activeTo),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      ...data.promotion,
      activeFrom: DateHelper.ConvertZone__API_to_UI(data.promotion?.activeFrom),
      activeTo: DateHelper.ConvertZone__API_to_UI(data.promotion?.activeTo),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Dane zostały zmienione");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      <TextBlock>
        <p>Operacja wpłynie na wszystkie projekty tej pracowni, należy zachować szczególną ostrożność.</p>
        <p>Uwaga, z promocji zostaną wykluczone projekty z kategorii: garaże, gospodarcze, sauny</p>
      </TextBlock>
      <Form onSubmit={handleSubmit(onSubmit)} cols={[2, 2, 2, 1]} direction="row">
        <div className="form-child form-child-wrapper">
          <TextInput
            id="name"
            label="Nazwa promocji"
            validation_msg={errors?.name?.message}
            inputProps={{
              ...register("name", Validators.required),
            }}
          />
          <TextInput
            id="description"
            label="Opis promocji"
            validation_msg={errors?.description?.message}
            inputProps={{
              type: "textarea",
              ...register("description", Validators.required),
            }}
          />
        </div>
        <div className="form-child form-child-wrapper date-pickers">
          <CheckBoxInput
            id="isActive"
            label="Status"
            description="Aktywna"
            validation_msg={errors?.isActive?.message}
            inputProps={{
              ...register("isActive"),
            }}
          />
          {(
            [
              {
                field: "activeFrom",
                component: "SelectDatatimeBox",
                maxDetail: "minute", //'hour',
                format: "dd.MM.yyyy HH:mm", //:00',
                label: "Aktywna od",
                validators: Validators.required,
              },
              {
                field: "activeTo",
                component: "SelectDatatimeBox",
                maxDetail: "minute", //'hour',
                format: "dd.MM.yyyy HH:mm", //00',
                label: "Aktywna do",
                validators: Validators.required,
              },
              {
                field: "valueType",
                component: "SelectBox",
                label: "Typ zmiany",
                validators: Validators.required,
                options: [
                  ...mapEnum(PromotionValueType_Enum, (x: PromotionValueType_Enum) => {
                    return { id: x, label: PromotionValueType_Enum_Parser(x) };
                  }),
                ],
                conditionalFields: [
                  {
                    basedOn: "valueType",
                    condition: (x) => x == PromotionValueType_Enum.Percent,
                    fields: [
                      {
                        field: "value",
                        type: "number",
                        component: "TextBox",
                        placeholder: "-5",
                        label: "Wartość (%)",
                        validators: Validators.promotionPerecent,
                      },
                    ],
                  },
                  {
                    basedOn: "valueType",
                    condition: (x: PromotionValueType_Enum) => x == PromotionValueType_Enum.Raw,
                    fields: [
                      { field: "value", type: "number", component: "TextBox", placeholder: "-123", label: "Wartość (zł)", validators: Validators.promotionRaw },
                    ],
                  },
                ],
              },
            ] as Array<AdvancedInputField>
          ).map((x: any, index: number) => {
            return (
              <InputBuilder
                key={index}
                advancedInputField={x}
                formHook={{
                  control: control,
                  getValues: getValues,
                  register: register,
                  watch: watch,
                  errors: errors,
                }}
              />
            );
          })}
        </div>
        <div className="ml-auto">
          <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
            Zapisz
          </LoadingBtn>
        </div>
      </Form>
    </>
  );
};

export default PromotionEditForm;
