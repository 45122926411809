import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import Api from "../../../../../../../../api/Api";
import { AddMediaDto, ImageDto, ProjectMediaTypeEnum, ProjectMediaUpdateDto } from "../../../../../../../../api/project/project.dto";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../../../libs/buttons/Button/LoadingBtn";
import DropzoneGlu from "../../../../../../../../libs/forms/DropZone/drop-zone-glu";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import WYSIWYGEditor from "../../../../../../../../libs/forms/WysiwigEditor/WysiwigEditor";
import { ModalContext } from "../../../../../../../components/Modal/ModalContext";

interface SpotsAddProps {
  projectId: number;
  projectMediaType: ProjectMediaTypeEnum;
  item?: ImageDto;
}

const MediaAddEditModal: React.FC<SpotsAddProps> = (props) => {
  const { projectId, item, projectMediaType } = props;
  const { call, status } = useQuery<void, AddMediaDto>("project/addMedia", [Api.Project.AddMedia], { callOnLoad: false });
  const { call: editCall, status: editStatus } = useQuery<void, ProjectMediaUpdateDto>("project/updateMedia", [Api.Project.UpdateMedia], { callOnLoad: false });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<any>({ defaultValues: {} });
  const { handleConfirm } = useContext(ModalContext);

  const onSubmit = (data: any) => {
    var file = null;
    if (data.file) file = data.file;

    if (item) {
      var updateDto = {
        file: file,
        alt: data.alt,
        projectId: projectId.toString(),
        projectMediaType: ProjectMediaTypeEnum[projectMediaType],
        path: item.path,
        description: data.description,
      } as ProjectMediaUpdateDto;
      editCall(updateDto);
    } else {
      var addDto = {
        file: file,
        alt: data.alt,
        projectId: projectId.toString(),
        projectMediaType: ProjectMediaTypeEnum[projectMediaType],
        description: data.description,
      } as AddMediaDto;
      call(addDto);
    }
  };

  useEffect(() => {
    if (status === "finished" || editStatus === "finished") handleConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, editStatus]);

  useEffect(() => {
    if (item !== null) {
      reset(item);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} direction="row" cols={1}>
      <DropzoneGlu
        id="file"
        label="file"
        validation_msg={errors?.file?.message?.toString()}
        onFileAdded={undefined}
        imagePreviewUrl={item ? process.env.REACT_APP_API_URL + item?.path : null}
        inputProps={{
          control: control,
        }}
      />
      <TextInput
        id="alt"
        label="Alt"
        validation_msg={errors?.alt?.message?.toString()}
        required
        inputProps={{
          ...register("alt", Validators.required),
        }}
      />
      <WYSIWYGEditor
        id="description"
        label="Opis"
        validation_msg={errors?.description?.message?.toString()}
        inputProps={{
          control: control,
          defaultValue: item?.description,
        }}
      />
      <br />
      <div className="ml-auto">
        {item ? (
          <LoadingBtn type="submit" status={editStatus} className="ml-auto">
            Zapisz
          </LoadingBtn>
        ) : (
          <LoadingBtn type="submit" status={status} className="ml-auto">
            Dodaj
          </LoadingBtn>
        )}
      </div>
    </Form>
  );
};

export default MediaAddEditModal;
