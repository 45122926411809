import React from "react";
import { generatePath, useParams } from "react-router-dom";
import { SystemScope_Enum } from "../../../../../../../api/shared/System.dto";
import { ProjectMediaTypeEnum, SafeProjectDto } from "../../../../../../../api/project/project.dto";
import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../components/Tile/Tile";
import AddonsList from "../../../panel-shares/addons-list/addons-list";
import BenefitsList from "./benefits-list/benefits-list";
import MediaList from "./media-list/media-list";
import ProjectDescriptionEditForm from "./project-description-form/ProjectDescriptionEditForm";
import ProjectBasicInfoEditForm from "./project-edit-form/ProjectBasicInfoEditForm";
import ProjectPromotionEditForm from "./project-promotion-edit-form/project-promotion-edit-form";
import NotFoundPage from "../../not-found-page";
import InfoCardsList from "../../../panel-shares/infoCards-list/infoCards-list";
import Button from "../../../../../../../libs/buttons/Button/Button";
import { publicURL } from "../../../../../../../api/client";
import Api from "../../../../../../../api/Api";
import { useQuery } from "../../../../../../../api/useQuerySlice";
import LoadingSpinner from "../../../../../../components/LoadingSpinner/LoadingSpinner";
import RelatedProjectCategories from "./related-categories/related-projects-categories-list";

const ProjectEditPage: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { data: project, status } = useQuery<SafeProjectDto, string>("project/get", [Api.Project.Get, projectId ?? ""], {
    callOnLoad: true,
    statusResetDelay: 1000,
  });

  return !(projectId && !Number.isNaN(projectId) && Number(projectId) > 0) ? (
    <NotFoundPage />
  ) : (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_PROJECTS_LIST} title="Lista" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_PROJECTS_EDIT, { projectId: projectId })} title="Edycja projektu" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <div className="d-flex">
                <h1 className="mr-auto">Ustawienia projektu - {projectId}</h1>
                {project?.isActive ? (
                  <a href={`${publicURL}gotowe-projekty/wszystkie/${projectId}`} target="_blank" rel="noreferrer">
                    <Button variant="like-input-primary">Podgląd projektu</Button>
                  </a>
                ) : (
                  <Button
                    variant="like-input-primary"
                    onClick={() =>
                      alert(
                        "Nie można włączyć podglądu projektu, który nie został aktywowany. Przycisk aktywacji znajduje się na liście projektów. W przyszłości zostanie dodany tutaj."
                      )
                    }
                  >
                    Podgląd projektu
                  </Button>
                )}
              </div>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h2 className="title">Parametry podstawowe</h2>
              <hr />
              <ProjectBasicInfoEditForm />
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={6}>
            <Tile>
              <h2 className="title">Opis projektu</h2>
              <hr />
              <ProjectDescriptionEditForm />
            </Tile>
          </Col>
          <Col size={6} className="promo-settings-edit-projekt">
            <Tile>
              <h2 className="title">Ustawienia promocji</h2>
              <hr />
              {project ? <ProjectPromotionEditForm project={project} /> : <LoadingSpinner active={status === "loading"} />}
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <InfoCardsList scopeId={Number(projectId)} scope={SystemScope_Enum.Project} />
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <MediaList mediaType={ProjectMediaTypeEnum.Visualizations} />
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <MediaList mediaType={ProjectMediaTypeEnum.Plans} />
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <MediaList mediaType={ProjectMediaTypeEnum.Elevations} />
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <BenefitsList />
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <AddonsList scopeId={Number(projectId)} scope={SystemScope_Enum.Project} />
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={6}>
            <Tile>
              <RelatedProjectCategories projectId={Number(projectId)} />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default ProjectEditPage;
