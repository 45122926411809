import { generatePath } from "react-router-dom";
import Api from "../../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../../features/confirm";
import useEffectAfterMounted from "../../../../../../../../../features/useEffectAfterMounted";
import GenericList, { NumberStringTuple } from "../../../../../../../../../libs/generic-list/generic-list";
import { useAppDispatch } from "../../../../../../../../../redux/store";
import { RoutePath } from "../../../../../../../../../route-paths";
import Menu, { MenuItemType } from "../../../../../../../../components/Menu/menu";
import { showSlideOverlay } from "../../../../../../../../components/router-transition/routerTransitionSlice";
import { TagDto } from "../../../../../../../../../api/blog/tag/tag.dto";
import { ToastsStore } from "react-toasts";
import TopLink from "../../../../../../../../components/top-link/top-link";

interface CategoriesListProps {}

const CategoriesList = (props: CategoriesListProps) => {
  const { data, status, call } = useQuery<Array<TagDto>>("tag/List", [Api.Blog.Tag.List]);
  const { status: delStatus, call: callDelete } = useQuery<void, number>("tag/delete", [Api.Blog.Tag.Delete], { callOnLoad: false });

  const dispatch = useAppDispatch();

  useEffectAfterMounted(() => {
    if (delStatus === "finished") {
      ToastsStore.success("Usunięto");
      call();
    }
  }, [delStatus]);

  const handleDelete = (tag: TagDto) => {
    Confirm(`Czy na pewno chcesz usunąć ${tag.name}?`, () => callDelete(tag.id));
  };

  const standardCols: NumberStringTuple[] = [
    [
      "Nazwa",
      "",
      11,
      (x: TagDto) => (
        <TopLink to={generatePath(RoutePath.PANEL_KNOWLEDGEBASE_TAGS_EDIT, { id: x.id?.toString() })} className="primary">
          {x.name}
        </TopLink>
      ),
    ],
  ];

  const standardActions = (actionsItems: (tag: TagDto) => Array<MenuItemType>): NumberStringTuple => [
    "",
    "",
    1,
    (tag: TagDto) => <Menu header="Opcje" items={actionsItems(tag)} />,
  ];

  const actionsItems__edit = (tag: TagDto) => {
    return {
      label: "Edytuj",
      onAction: () => dispatch(showSlideOverlay(generatePath(RoutePath.PANEL_KNOWLEDGEBASE_TAGS_EDIT, { id: tag.id?.toString() }))),
    };
  };
  const actionsItems__delete = (tag: TagDto) => {
    return { label: "Usuń", onAction: () => handleDelete(tag) };
  };

  const standardActionsItems = (tag: TagDto): Array<MenuItemType> => {
    return [actionsItems__edit(tag), actionsItems__delete(tag)];
  };
  return <GenericList status={status} data={data} cols={[...standardCols]} actions={standardActions(standardActionsItems)} />;
};

export default CategoriesList;
