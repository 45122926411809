import Button from "../../../../../../../../libs/buttons/Button/Button";
import Col from "../../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../../components/Tile/Tile";
import TopLink from "../../../../../../../components/top-link/top-link";
import ProjectCategoriesList from "./ProjectCategoriesList/ProjectCategoriesList";


const ProjectCategoriesListPage = () => {
  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_PROJECTCATEGORIES_LIST} title="Lista" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Zarządzanie kategoriami</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={[6, 8, 10, 12]}>
            <Tile>
              <div className="d-flex pb-3">
                <h2 className="title mr-auto">Lista kategorii</h2>
                <TopLink to={RoutePath.PANEL_PROJECTCATEGORIES_ADD}>
                  <Button variant="like-input-primary">+ dodaj</Button>
                </TopLink>
              </div>
              <hr />
              <ProjectCategoriesList />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default ProjectCategoriesListPage;
