import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { ArticleDto } from "../../../../../../../../../api/blog/article/article.dto";
import Api from "../../../../../../../../../api/Api";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import { ToastsStore } from "react-toasts";
import { IdNameDto } from "../../../../../../../../../api/shared/System.dto";
import SelectBox, { SelectBoxOption } from "../../../../../../../../../libs/forms/SelectBox/SelectBox";
import BreakTitle from "../../../../../../../../components/BreakTitle/BreakTitle";
import CheckBoxInput from "../../../../../../../../../libs/forms/CheckBox/CheckBox";

interface ArticleEditFormProps {
  data: ArticleDto | undefined;
}

const ArticleEditForm = (props: ArticleEditFormProps) => {
  const { data } = props;
  // const { refetch } = useQueryRefetch();
  const { call, status: updateStatus } = useQuery<void, ArticleDto>("article/update", [Api.Blog.Article.Update], { callOnLoad: false });
  const { data: tagNames } = useQuery<Array<IdNameDto>>("tag/ListIdNames", [Api.Blog.Tag.ListIdNames]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ArticleDto>({ defaultValues: { ...data } });

  const onSubmit = async (dto: ArticleDto) => {
    call({ ...dto, id: data?.id, mainTagId: dto.mainTagId } as ArticleDto);
  };

  useEffect(() => {
    reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Zapisano");
      // refetch(Number(id));
      // refetch("article/get");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-child form-child-wrapper">
          <TextInput
            id="name"
            label="Nazwa"
            validation_msg={errors?.name?.message}
            required
            inputProps={{
              type: "textarea",
              rows: 2,
              ...register("name", Validators.required),
            }}
          />
          <TextInput
            id="slug"
            label="Slug"
            validation_msg={errors?.slug?.message}
            required
            inputProps={{
              ...register("slug", Validators.required),
            }}
          />
          <CheckBoxInput
            id="isPublic"
            label="Widoczność"
            description="Publiczny"
            validation_msg={errors?.isPublic?.message}
            inputProps={{
              ...register("isPublic"),
            }}
          />
          <SelectBox
            id="mainTagId"
            label="Kategoria kanoniczna"
            validation_msg={errors?.mainTagId?.message}
            // cancelable={true}
            maxHeight="xl"
            inputProps={{
              ...register("mainTagId"),
            }}
            options={
              tagNames?.map((x: IdNameDto) => {
                return {
                  id: x.id,
                  label: x.name,
                } as SelectBoxOption;
              }) ?? []
            }
          />
          <TextInput
            id="description"
            label="Krótki opis ogólny"
            validation_msg={errors?.description?.message}
            inputProps={{
              type: "textarea",
              rows: 12,
              ...register("description"),
            }}
          />
          <TextInput
            id="tileDescription"
            label="Opis kafelka"
            validation_msg={errors?.tileDescription?.message}
            showCharCount
            inputProps={{
              type: "textarea",
              rows: 5,
              ...register("tileDescription", Validators.maxLength(300)),
            }}
          />
          {/* <SelectBox
            id="articleType"
            label="Typ artykułu"
            validation_msg={errors?.articleType?.message}
            required
            maxHeight="xl"
            inputProps={{
              ...register("articleType", Validators.required),
            }}
            options={
              mapEnum(BlogArticleType, (articleType: any) => {
                return {
                  id: articleType,
                  label: BlogArticleType_Parser(articleType),
                };
              }) ?? []
            }
          /> */}
        </div>
        <BreakTitle className="pl-0">SEO Articles</BreakTitle>
        <TextInput
          id="meta_description"
          label="[SEO] Tag <description>"
          showCharCount
          validation_msg={errors?.meta_description?.message}
          inputProps={{
            type: "textarea",
            rows: 5,
            ...register("meta_description", Validators.maxLength(160)),
          }}
        />
        <TextInput
          id="meta_title"
          label="[SEO] Tag <title>"
          validation_msg={errors?.meta_title?.message}
          showCharCount
          inputProps={{
            ...register("meta_title", Validators.maxLength(60)),
          }}
        />
        <TextInput
          id="redirectUrl"
          label="[SEO] Przekierowanie 308 (url)"
          validation_msg={errors?.redirectUrl?.message}
          inputProps={{
            placeholder:"https://wybieramprojekt.pl/...",
            ...register("redirectUrl"),
          }}
        />
        <br />
        <div className="ml-auto form-child-wrapper">
          <LoadingBtn type="submit" status={updateStatus}>
            Zapisz
          </LoadingBtn>
        </div>
      </Form>
    </>
  );
};

export default ArticleEditForm;
