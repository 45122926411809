import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import Api from "../../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import DropzoneGlu from "../../../../../../../../../libs/forms/DropZone/drop-zone-glu";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import SelectBox, { SelectBoxOption } from "../../../../../../../../../libs/forms/SelectBox/SelectBox";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import { ModalContext } from "../../../../../../../../components/Modal/ModalContext";
import { BlogImageDto } from "../../../../../../../../../api/blog/image/blogImage.dto";
import { apiURL } from "../../../../../../../../../api/client";
import { ImgFieldEnum, ImgFieldEnum_Parser, MediaAddDto, MediaUpdateDto } from "../../../../../../../../../api/shared/System.dto";

interface MediaAddEditModalProps {
  itemId: number;
  media?: BlogImageDto;
}

const MediaAddEditModal: React.FC<MediaAddEditModalProps> = (props) => {
  const { itemId, media } = props;
  const { call, status } = useQuery<void, MediaAddDto>("project-category/AddMedia", [Api.ProjectCategory.AddMedia], { callOnLoad: false });
  const { call: editCall, status: editStatus } = useQuery<void, MediaUpdateDto>("project-category/UpdateMedia", [Api.ProjectCategory.UpdateMedia], {
    callOnLoad: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<any>({ defaultValues: {} });
  const { handleConfirm } = useContext(ModalContext);

  const onSubmit = (data: any) => {
    var file = null;
    console.log("onSubmit");
    if (data.file) file = data.file;

    if (media) {
      var updateDto = {
        files: file,
        alt: data.alt,
        scopeId: itemId,
        field: data.field,
        path: data.path,
      } as MediaUpdateDto;
      editCall(updateDto);
    } else {
      var addDto = {
        scopeId: itemId,
        files: file,
        alt: data.alt,
        field: data.field,
      } as MediaAddDto;
      console.log("call(addDto)");
      call(addDto);
    }
  };

  useEffect(() => {
    if (status === "finished" || editStatus === "finished") handleConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, editStatus]);

  useEffect(() => {
    if (media !== null) {
      reset(media);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} direction="row" cols={1}>
      <DropzoneGlu
        id="file"
        label="Plik"
        validation_msg={errors?.file?.message?.toString()}
        onFileAdded={undefined}
        imagePreviewUrl={media && media.path ? apiURL + media.path : null}
        inputProps={{
          control: control,
        }}
      />
      <SelectBox
        id="field"
        label="Funkcja"
        validation_msg={errors?.field?.message?.toString()}
        required
        options={[
          {
            id: ImgFieldEnum.MainPicture,
            label: ImgFieldEnum_Parser(ImgFieldEnum.MainPicture),
          } as SelectBoxOption,
        ]}
        inputProps={{
          ...register("field", Validators.required),
        }}
      />
      <TextInput
        id="alt"
        label="Alt"
        validation_msg={errors?.alt?.message?.toString()}
        required
        inputProps={{
          ...register("alt", Validators.required),
        }}
      />
      {/* <WYSIWYGEditor
        id="description"
        label="Opis"
        validation_msg={errors?.description?.message}
        inputProps={{
          control: control,
          defaultValue: media?.description,
        }}
      /> */}
      <br />
      <div className="ml-auto">
        {media ? (
          <LoadingBtn type="submit" status={editStatus} className="ml-auto">
            Zapisz
          </LoadingBtn>
        ) : (
          <LoadingBtn type="submit" status={status} className="ml-auto">
            Dodaj
          </LoadingBtn>
        )}
      </div>
    </Form>
  );
};

export default MediaAddEditModal;
