import React, { useEffect } from "react";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import LoadingSpinner from "../../../../../../../components/LoadingSpinner/LoadingSpinner";

interface Props {
  id: number;
}

const ProjectsCounter: React.FC<Props> = (props) => {
  const { id } = props;
  const { data, status, call } = useQuery<{ count: number }, number>("office/CountProjectsByOfficeId" + props.id, [Api.Office.CountProjectsByOfficeId, id], {
    callOnLoad: false,
  });

  useEffect(() => {
    call(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return <>{data && status === "finished" ? data.count : <LoadingSpinner active={status === "loading"} size="xs" invert />}</>;
};

export default ProjectsCounter;
