import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { useParams } from "react-router-dom";
import { CustomerDto, OrderDto } from "../../../../../../../../api/order/order.dto";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import Col from "../../../../../../../../libs/grid/col";
import Row from "../../../../../../../../libs/grid/row";

interface CustomerFormProps {}

const CustomerForm: React.FC<CustomerFormProps> = (props) => {
  const { orderId = "" } = useParams<{ orderId: string }>();
  const { data } = useQuery<OrderDto, string>("order/get-customer", [Api.Order.Get, orderId], { callOnLoad: true });

  const {
    register,
    reset,
    formState: { errors },
  } = useForm<CustomerDto>({ defaultValues: {} });

  useEffect(() => {
    if (data && data.customer) reset({ ...data.customer } as CustomerDto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Form>
        <div className="form-child form-child-wrapper">
          <h3 className="title form-child">Dane zamawiającego</h3>
          <Row gap={0} align="center">
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="name"
                label="Imię"
                validation_msg={errors?.name?.message}
                required
                inputProps={{
                  disabled:true,
                  ...register("name", Validators.required),
                }}
              />
              <TextInput
                id="surname"
                label="Nazwisko"
                required
                validation_msg={errors?.surname?.message}
                inputProps={{
                  disabled:true,
                  ...register("surname", Validators.required),
                }}
              />
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="email"
                label="Email"
                required
                validation_msg={errors?.email?.message}
                inputProps={{
                  type: "email",
                  disabled:true,
                  ...register("email", Validators.email),
                }}
              />
              <TextInput
                id="phone"
                label="Telefon"
                required
                validation_msg={errors?.phone?.message}
                inputProps={{
                  maxLength: 20,
                  disabled:true,
                  ...register("phone", Validators.required),
                }}
              />
            </Col>
          </Row>
          <h3 className="title form-child">Dane do wysyłki</h3>
          <Row gap={0}>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="street"
                label="Ulica"
                required
                validation_msg={errors?.street?.message}
                inputProps={{
                  disabled:true,
                  ...register("street", Validators.required),
                }}
              />
              <TextInput
                id="homenumber"
                label="Numer domu/mieszkania"
                validation_msg={errors?.homenumber?.message}
                required
                inputProps={{
                  disabled:true,
                  ...register("homenumber", Validators.required),
                }}
              />
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="city"
                label="Miejscowość"
                required
                validation_msg={errors?.city?.message}
                inputProps={{
                  disabled:true,
                  ...register("city", Validators.required),
                }}
              />

              <TextInput
                id="postalcode"
                label="Kod pocztowy"
                required
                validation_msg={errors?.postalcode?.message}
                inputProps={{
                  maxLength: 6,
                  disabled:true,
                  ...register("postalcode", Validators.required),
                }}
              />
            </Col>
          </Row>
          <h3 className="title form-child">Dane do faktury</h3>
          <Row gap={0}>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="company"
                label="Nazwa firmy"
                validation_msg={errors?.company?.message}
                inputProps={{
                  disabled:true,
                  ...register("company"),
                }}
              />
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="nip"
                label="NIP"
                validation_msg={errors?.nip?.message}
                inputProps={{
                  maxLength: 10,
                  disabled:true,
                  ...register("nip"),
                }}
              />
            </Col>
          </Row>
          <h3 className="title form-child">Dodatkowe informacje</h3>
          <Row gap={0}>
            <Col size={12}>
              <TextInput
                id="message"
                label="Wiadomość dla sprzedawcy"
                inputProps={{
                  type: "textarea",
                  disabled:true,
                  ...register("message"),
                }}
              />
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default CustomerForm;
