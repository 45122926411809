import { useEffect } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { ToastsStore } from "react-toasts";

const ServerResetForm = () => {
  const { call, status, data } = useQuery<string, string>("auth/update-accont-info", [Api.Cron.Reset], { callOnLoad: false });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{ password: string }>({ defaultValues: {} });
  const onSubmit = async (dto: any) => {
    call(dto.password);
  };

  useEffect(() => {
    if (status === "finished") {
      ToastsStore.success("Reset wykonany, poczekaj kilkanaście sekund i odśwież stronę.");
      reset({ password: "" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <>
      {data ? <p>{data}</p> : ""}
      <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
        <TextInput
          id="password"
          label="Hasło resetu"
          validation_msg={errors?.password?.message}
          inputProps={{
            ...register("password", Validators.required),
          }}
        />
        <br />
        <LoadingBtn type="submit" status={status} className="ml-auto">
          Resetuj
        </LoadingBtn>
      </Form>
    </>
  );
};

export default ServerResetForm;
