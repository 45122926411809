import React, { useEffect } from "react";
import Api from "../../../../../../api/Api";
import { useQuery } from "../../../../../../api/useQuerySlice";

import GenericList from "../../../../../../libs/generic-list/generic-list";
import Menu from "../../../../../components/Menu/menu";
import { Confirm } from "../../../../../../features/confirm";
import { AddonDto } from "../../../../../../api/addon/addon.dto";
import Modal from "../../../../../components/Modal/Modal";
import AddonAddEditModal from "./addon-addEdit-modal";
import { SystemScope_Enum } from "../../../../../../api/shared/System.dto";
import { useTrigger } from "../../../../../components/GlobalTrigger/GlobalTriggerSlice";

interface AddonsListProps {
  scopeId?: number; // project_id or office_id
  scope: SystemScope_Enum;
}

const AddonsList: React.FC<AddonsListProps> = (props) => {
  const { scopeId, scope } = props;
  const { setTrigger, triggerId } = useTrigger();

  const scopeConfig = (scope: SystemScope_Enum) => {
    if (scope === SystemScope_Enum.Project) return { key: "addon/listFromProject" };
    else if (scope === SystemScope_Enum.Office) return { key: "addon/listFromOffice" };
    else return { key: "addon/listGlobal" };
  };

  const {
    call,
    status,
    data: addonsData,
  } = useQuery<Array<AddonDto>, { scope: SystemScope_Enum; scopeId?: number }>(scopeConfig(scope).key, [Api.Addon.List, { scope: scope, scopeId: scopeId }], {
    callOnLoad: true,
  });

  const { call: deleteAddon, status: deleteStatus } = useQuery<void, number>("addon/delete", [Api.Addon.Delete], { callOnLoad: false });

  useEffect(() => {
    if (deleteStatus === "finished") call({ scope: scope, scopeId: scopeId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  return (
    <>
      <div className="d-flex pb-3">
        <h2 className="title mr-auto">Dodatki przy zakupie</h2>
        <Modal
          title={`Dodaj nowy dodatek`}
          disableStandardButtons={true}
          button={{
            title: `+ Dodaj nowy`,
            variant: "like-input-primary",
          }}
          confirm={() => call({ scope: scope, scopeId: scopeId })}
        >
          <div style={{ maxWidth: "1000px" }}>
            <AddonAddEditModal scopeId={scopeId} scope={scope} />
          </div>
        </Modal>
      </div>
      <hr />
      <GenericList
        status={status}
        data={addonsData}
        cols={[
          ["Id", "id", 0],
          ["Nazwa", "name", 2],
          ["Cena", "cena__raw_brutto", 2],
          ["Cena prom", "cena__promotion_brutto", 2],
          ["Opis", "description", 5],
          [
            "",
            "",
            0,
            (x) => {
              return (
                <Modal
                  title={`Edycja dodatku`}
                  button={{
                    hidden: true,
                  }}
                  disableStandardButtons={true}
                  openTrigger={triggerId === "addons_addEdit_modal--" + x.id}
                  confirm={() => call({ scope: scope, scopeId: scopeId })}
                >
                  <div style={{ maxWidth: "1000px" }}>
                    <AddonAddEditModal scopeId={scopeId} addon={x} scope={scope} />
                  </div>
                </Modal>
              );
            },
          ],
        ]}
        actions={[
          "",
          "",
          1,
          (item: AddonDto) => (
            <Menu
              header="Opcje"
              items={[
                {
                  label: "Zarządzaj",
                  onAction: () => setTrigger("addons_addEdit_modal--" + item.id),
                },
                { label: "Usuń", onAction: () => Confirm(`Czy na pewno chcesz usunąć ${item.name}?`, () => deleteAddon(item.id)) },
              ]}
            />
          ),
        ]}
      />
    </>
  );
};

export default AddonsList;
