import "./drop-zone.scss";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import DropzoneCore from "./drop-zone-core";

interface Props {
  onFileAdded: any;
  onFileDelete?: any;
  disabled?: boolean;
  clear?: boolean;
  imagePreviewUrl?: string | null;
  id: string;
  label?: string;
  name?: string;
  validation_msg?: string;
  required?: boolean;

  inputProps: React.InputHTMLAttributes<HTMLInputElement> & {
    control: any;
  };
}

const DropzoneGlu: React.FC<Props> = (props: Props) => {
  useEffect(() => {}, [props.clear]);
  const { imagePreviewUrl, label, id, validation_msg, required, disabled, inputProps } = props;

  return (
    <Controller
      control={inputProps!.control}
      name={id}
      render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, isTouched, isDirty, error }, formState }) => {
        return (
          <DropzoneCore
            onChange={onChange}
            value={{ file: undefined, previewUrl: imagePreviewUrl }}
            onFileDelete={() => props.onFileDelete()}
            name={name}
            required={required}
            disabled={disabled}
            validation_msg={validation_msg}
            id={id}
            label={label}
          />
        );
      }}
    />
  );
};

export default DropzoneGlu;
