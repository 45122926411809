import { generatePath } from "react-router-dom";
import Api from "../../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../../features/confirm";
import useEffectAfterMounted from "../../../../../../../../../features/useEffectAfterMounted";
import GenericList, { NumberStringTuple } from "../../../../../../../../../libs/generic-list/generic-list";
import { useAppDispatch } from "../../../../../../../../../redux/store";
import { RoutePath } from "../../../../../../../../../route-paths";
import Menu, { MenuItemType } from "../../../../../../../../components/Menu/menu";
import { showSlideOverlay } from "../../../../../../../../components/router-transition/routerTransitionSlice";
import { useState } from "react";
import { PaginationFilterDto } from "../../../../../../../../components/Pagination/pagination.dto";
import { ArrayResult } from "../../../../../../../../../api/client";
import Filters from "../../../../../../../../../libs/filters/FiltersForm";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import TopLink from "../../../../../../../../components/top-link/top-link";
import Pagination from "../../../../../../../../components/Pagination/Pagination";
import { BasicColumnFilterDto, StandardFilterDto } from "../../../../../../../../../libs/filters/filters.dto";
import { ToastsStore } from "react-toasts";
import { ArticleDto } from "../../../../../../../../../api/blog/article/article.dto";

interface CategoriesListProps {}

const CategoriesList = (props: CategoriesListProps) => {
  const [filters, setFilters] = useState<StandardFilterDto>({});
  const [pagination, setPagination] = useState<PaginationFilterDto>({ take: 10, skip: 0 });

  const { data, status, call } = useQuery<ArrayResult<ArticleDto>, BasicColumnFilterDto>("article/list", [
    Api.Blog.Article.ListAdvanced,
    { ...filters, ...pagination },
  ]);
  const { status: delStatus, call: callDelete } = useQuery<void, number>("article/delete", [Api.Blog.Article.Delete], { callOnLoad: false });

  const dispatch = useAppDispatch();

  useEffectAfterMounted(() => {
    call({ ...filters, ...pagination });
  }, [filters, pagination]);

  useEffectAfterMounted(() => {
    if (delStatus === "finished") {
      ToastsStore.success("Usunięto");
      call();
    }
  }, [delStatus]);

  const handleDelete = (article: ArticleDto) => {
    Confirm(`Czy na pewno chcesz usunąć ${article.name}?`, () => callDelete(article.id));
  };

  const standardCols: NumberStringTuple[] = [
    ["Id", "id", 1],
    [
      "Nazwa",
      "",
      10,
      (x) => (
        <TopLink to={generatePath(RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES_EDIT, { id: x.id?.toString() })} className="primary">
          {x.name}
        </TopLink>
      ),
    ],
  ];

  const standardActions = (actionsItems: (article: ArticleDto) => Array<MenuItemType>): NumberStringTuple => [
    "",
    "",
    1,
    (article: ArticleDto) => <Menu header="Opcje" items={actionsItems(article)} />,
  ];

  const actionsItems__edit = (article: ArticleDto) => {
    return {
      label: "Edytuj",
      onAction: () => dispatch(showSlideOverlay(generatePath(RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES_EDIT, { id: article.id?.toString() }))),
    };
  };
  const actionsItems__delete = (article: ArticleDto) => {
    return { label: "Usuń", onAction: () => handleDelete(article) };
  };

  const standardActionsItems = (article: ArticleDto): Array<MenuItemType> => {
    return [actionsItems__edit(article), actionsItems__delete(article)];
  };
  return (
    <>
      <Filters
        onSubmit={setFilters}
        setPagination={setPagination}
        status={status}
        fields={[
          {
            field: "searchByColumn",
            component: "SelectBox",
            label: "Kolumna",
            options: [
              { id: "name", label: "Nazwa" },
              // { id: "role", label: "Rola" },
            ],
            conditionalFields: [
              {
                basedOn: "searchByColumn",
                condition: (x: any) => x === "name",
                fields: [{ field: "searchText", component: "TextBox", placeholder: "Wyszukaj", label: "Column contents", validators: Validators.required }],
              },
              // {
              //   basedOn: "searchByColumn",
              //   condition: (x: any) => x === "role",
              //   fields: [
              //     {
              //       field: "searchEnum",
              //       component: "SelectBox",
              //       label: "Wyszukaj",
              //       validators: Validators.required,
              //       options: mapEnum(UserRoleEnum, (key: any) => {
              //         return {
              //           id: key,
              //           label: UserRoleEnum_Parser(key),
              //         };
              //       }),
              //     },
              //   ],
              // },
            ],
          },
        ]}
      />

      <GenericList //
        status={status}
        data={data?.list}
        cols={[...standardCols]}
        actions={standardActions(standardActionsItems)}
      />
      <Pagination
        disableScroll
        totalCount={data?.totalCount}
        itemsPerPage={10}
        take={pagination.take!}
        setNewSkip={(newSkip: number) => setPagination({ ...pagination, skip: newSkip })}
      />
    </>
  );
};

export default CategoriesList;
