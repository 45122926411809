import { useEffect, useState } from "react";
import { OrderDto } from "../../../../../../../../api/order/order.dto";
import Button from "../../../../../../../../libs/buttons/Button/Button";
import TextBlock from "../../../../../../../../libs/text-block/text-block";

const OrderCopy = ({ order }: { order: OrderDto }) => {
  const [showCopy, setShowCopy] = useState(false);
  const [formatedJson, setFormatedJson] = useState("");

  useEffect(() => {
    try {
      var orderCopyJson = JSON.parse(order.cart);
      var formatedOrderCopy = JSON.stringify(orderCopyJson, null, 4);
      if (formatedOrderCopy) {
        setFormatedJson(formatedOrderCopy);
      }
    } catch (error) {}
  }, [order]);

  return (
    <>
      {showCopy ? (
        <TextBlock>
          <pre>
            <code style={{ wordWrap: "break-word", whiteSpace: "pre-line", fontSize: "1.6rem", lineHeight: "1.5" }}>{formatedJson}</code>
          </pre>
        </TextBlock>
      ) : (
        <Button variant="like-input-primary" onClick={() => setShowCopy(true)}>
          Pokaż kopię obiektu
        </Button>
      )}
    </>
  );
};

export default OrderCopy;
