import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import Api from "../../../../../../api/Api";
import { AddonDto } from "../../../../../../api/addon/addon.dto";
import { SystemScope_Enum } from "../../../../../../api/shared/System.dto";
import { useQuery } from "../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../libs/forms/validators/validators";
import { ModalContext } from "../../../../../components/Modal/ModalContext";

interface AddonAddEditModalProps {
  scopeId?: number;
  scope: SystemScope_Enum;
  addon?: AddonDto;
}

const AddonAddEditModal: React.FC<AddonAddEditModalProps> = (props) => {
  const { call, status } = useQuery<void, AddonDto>("addon/add", [Api.Addon.Add], { callOnLoad: false });
  const { call: editCall, status: editStatus } = useQuery<void, AddonDto>("addon/edit", [Api.Addon.Update], { callOnLoad: false });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddonDto>({ defaultValues: {} });
  const { handleConfirm } = useContext(ModalContext);
  const { scopeId, scope, addon: item } = props;

  const onSubmit = (data: AddonDto) => {
    if (item) editCall({ ...data, scopeId: scopeId, scope: scope });
    else call({ ...data, scopeId: scopeId, scope: scope });
  };

  useEffect(() => {
    if (status === "finished" || editStatus === "finished") handleConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, editStatus]);

  useEffect(() => {
    if (item !== null) {
      reset({ ...item });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} cols={[1, 1, 1, 1]} direction="row">
      <div className="form-child form-child-wrapper">
        <TextInput
          id="name"
          label="Nazwa"
          validation_msg={errors?.name?.message}
          required
          inputProps={{
            ...register("name", Validators.required),
          }}
        />
        <TextInput
          id="cena__raw_brutto"
          label="Cena"
          validation_msg={errors?.cena__raw_brutto?.message}
          required
          inputProps={{
            type: "number",
            ...register("cena__raw_brutto", Validators.required),
          }}
        />
        <TextInput
          id="cena__promotion_brutto"
          label="Cena prom"
          validation_msg={errors?.cena__promotion_brutto?.message}
          inputProps={{
            type: "number",
            ...register("cena__promotion_brutto"),
          }}
        />
        <TextInput
          id="description"
          label="Opis"
          validation_msg={errors?.description?.message}
          inputProps={{
            type: "textarea",
            ...register("description"),
          }}
        />
      </div>
      <br />
      <div className="ml-auto">
        {item ? (
          <LoadingBtn type="submit" status={editStatus} className="ml-auto">
            Zapisz
          </LoadingBtn>
        ) : (
          <LoadingBtn type="submit" status={status} className="ml-auto">
            Dodaj
          </LoadingBtn>
        )}
      </div>
    </Form>
  );
};

export default AddonAddEditModal;
