import React, { useState } from "react";
import { generatePath } from "react-router-dom";
import Api from "../../../../../../../../api/Api";
import { ArrayResult } from "../../../../../../../../api/client";
import { OfficeSyncDto, SyncPeriod__Enum, SyncPeriod__Enum_Parser, SyncStatus__Enum_Parser, SyncVariant__Enum, SyncVariant__Enum_Parser } from "../../../../../../../../api/officeSync/officeSync.dto";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import DateHelper from "../../../../../../../../features/date-helper";
import { html } from "../../../../../../../../features/html";
import useEffectAfterMounted from "../../../../../../../../features/useEffectAfterMounted";
import {
  BasicFilterDto, StandardFilterDto
} from "../../../../../../../../libs/filters/filters.dto";
import Filters from "../../../../../../../../libs/filters/FiltersForm";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import GenericList from "../../../../../../../../libs/generic-list/generic-list";
import { useAppDispatch } from "../../../../../../../../redux/store";
import { RoutePath } from "../../../../../../../../route-paths";
import Menu from "../../../../../../../components/Menu/menu";
import Pagination from "../../../../../../../components/Pagination/Pagination";
import { PaginationFilterDto } from "../../../../../../../components/Pagination/pagination.dto";
import { showSlideOverlay } from "../../../../../../../components/router-transition/routerTransitionSlice";

interface OfficesSyncListProps { }

const OfficesSyncList: React.FC<OfficesSyncListProps> = (props) => {
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState<StandardFilterDto>({})
  const [pagination, setPagination] = useState<PaginationFilterDto>({ take: 100, skip: 0 })
  const { data, status, call } = useQuery<ArrayResult<OfficeSyncDto>, BasicFilterDto>('officeSync/list', [Api.OfficeSync.List, { ...filters, ...pagination }]);

  // const [, setCallActive] = useState<boolean>(false);

  useEffectAfterMounted(() => {
    call({ ...filters, ...pagination });
  }, [filters, pagination])

  return (
    <>
      <Filters
        onSubmit={setFilters}
        setPagination={setPagination}
        status={status}
        fields={[
          {
            field: 'searchByColumn',
            component: 'SelectBox',
            label: 'Kolumna',
            options: [
              { id: 'name', label: 'Nazwa' },
            ],
            conditionalFields: [
              {
                basedOn: 'searchByColumn',
                condition: (x: any) => x === 'name',
                fields: [
                  { field: 'searchText', component: 'TextBox', placeholder: 'Wyszukaj', label: 'Zawartość kolumny', validators: Validators.required }
                ]
              }
            ]
          },
        ]}
      />
      <GenericList
        status={status}
        data={data?.list}
        cols={[
          ["Id", "id", 0],
          ["Nazwa parsera", "name", 2],
          ["Cykl", "syncPeriod", 1, (x: SyncPeriod__Enum) => SyncPeriod__Enum_Parser(x)],
          ["Wariant", "syncVariant", 1, (x: SyncVariant__Enum) => SyncVariant__Enum_Parser(x)],
          ["Status", "syncStatus", 2, (x: any) => html(SyncStatus__Enum_Parser(x))],
          ["Ostatni sync", "lastSync_At", 2, (x: string) => DateHelper.ConvertZone__API_to_UI__formated(x)],
          ["Źródło danych", "syncPath", 3],
        ]}
        actions={
          ["", "", 1, (x: OfficeSyncDto) =>
            <Menu
              header="Opcje"
              items={[
                { label: "Zarządzaj", onAction: () => dispatch(showSlideOverlay(generatePath(RoutePath.PANEL_OFFICES_SYNC_EDIT, { id: x.id.toString() }))) },
                { label: "Usuń", onAction: () => alert("Opcja usuwania aktualizacji nie jest zaimplementowana (" + x.id + ")") },
              ]}
            />
          ]
        }
      />
      <Pagination
        disableScroll
        totalCount={data?.totalCount}
        itemsPerPage={100}
        take={pagination.take!}
        setNewSkip={(newSkip: number) => setPagination({ ...pagination, skip: newSkip })}
      />
    </>
  );
};

export default OfficesSyncList;