import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Api from "../../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import { RoutePath } from "../../../../../../../../../route-paths";
import { ArticleDto } from "../../../../../../../../../api/blog/article/article.dto";
import SelectBox, { SelectBoxOption } from "../../../../../../../../../libs/forms/SelectBox/SelectBox";
import { ToastsStore } from "react-toasts";
import { IdNameDto } from "../../../../../../../../../api/shared/System.dto";

const ArticleAddForm = () => {
  const { call, status: updateStatus } = useQuery<void, ArticleDto>("article/add", [Api.Blog.Article.Add], { callOnLoad: false });
  const { data: tagNames } = useQuery<Array<IdNameDto>>("tag/ListIdNames", [Api.Blog.Tag.ListIdNames]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ArticleDto>({ defaultValues: {} });
  const navigate = useNavigate();

  const onSubmit = async (dto: ArticleDto) => {
    call({ ...dto, mainTagId: dto.mainTagId });
  };

  useEffect(() => {
    if (updateStatus === "finished") {
      reset();
      ToastsStore.success("Dodano");
      navigate(RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES_LIST as string);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
      <TextInput
        id="name"
        label="Nazwa"
        validation_msg={errors?.name?.message?.toString()}
        required
        inputProps={{
          ...register("name", Validators.required),
        }}
      />
      <TextInput
        id="slug"
        label="Slug"
        validation_msg={errors?.slug?.message}
        required
        inputProps={{
          ...register("slug", Validators.required),
        }}
      />
      <SelectBox
        id="mainTagId"
        label="Kategoria kanoniczna"
        validation_msg={errors?.mainTagId?.message}
        // cancelable={true}
        maxHeight="xl"
        inputProps={{
          ...register("mainTagId"),
        }}
        options={
          tagNames?.map((x: IdNameDto) => {
            return {
              id: x.id,
              label: x.name,
            } as SelectBoxOption;
          }) ?? []
        }
      />
      <br />
      <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
        Zapisz
      </LoadingBtn>
    </Form>
  );
};

export default ArticleAddForm;
