import React, { useState } from "react";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import GenericList from "../../../../../../../../libs/generic-list/generic-list";
import Menu from "../../../../../../../components/Menu/menu";
import { ArrayResult } from "../../../../../../../../api/client";
import Pagination from "../../../../../../../components/Pagination/Pagination";
import useEffectAfterMounted from "../../../../../../../../features/useEffectAfterMounted";
import { generatePath } from "react-router-dom";
import { UserRoleEnum, UserRoleEnum_Parser, MapRoles } from "../../../../../../../../api/auth/auth.dto";
import { SafeUserDto } from "../../../../../../../../api/user/user.dto";
import { mapEnum } from "../../../../../../../../features/enumMap";
import { StandardFilterDto, BasicFilterDto } from "../../../../../../../../libs/filters/filters.dto";
import Filters from "../../../../../../../../libs/filters/FiltersForm";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import { useAppDispatch } from "../../../../../../../../redux/store";
import { RoutePath } from "../../../../../../../../route-paths";
import { PaginationFilterDto } from "../../../../../../../components/Pagination/pagination.dto";
import { showSlideOverlay } from "../../../../../../../components/router-transition/routerTransitionSlice";

interface UsersListProps { }

const UsersList: React.FC<UsersListProps> = (props) => {
  const [filters, setFilters] = useState<StandardFilterDto>({})
  const [pagination, setPagination] = useState<PaginationFilterDto>({ take: 10, skip: 0 })

  const { data, status, call } = useQuery<ArrayResult<SafeUserDto>, BasicFilterDto>('user/list', [Api.User.List, { ...filters, ...pagination }]);
  const dispatch = useAppDispatch();

  useEffectAfterMounted(() => {
    call({ ...filters, ...pagination });
  }, [filters, pagination])

  return (
    <>
      <Filters
        onSubmit={setFilters}
        setPagination={setPagination}
        status={status}
        fields={[
          {
            field: 'searchByColumn',
            component: 'SelectBox',
            label: 'Kolumna',
            options: [
              { id: 'name', label: 'Nazwa' },
              { id: 'role', label: 'Rola' }
            ],
            conditionalFields: [
              {
                basedOn: 'searchByColumn',
                condition: (x: any) => x === 'name',
                fields: [
                  { field: 'searchText', component: 'TextBox', placeholder: 'Wyszukaj', label: 'Zawartość kolumny', validators: Validators.required }
                ]
              },
              {
                basedOn: 'searchByColumn',
                condition: (x: any) => x === 'role',
                fields: [{
                  field: 'searchEnum',
                  component: 'SelectBox',
                  label: 'Wyszukaj',
                  validators: Validators.required,
                  options: mapEnum(UserRoleEnum, (key: any) => {
                    return {
                      id: key,
                      label: UserRoleEnum_Parser(key)
                    }
                  })
                }]
              }
            ]
          },
        ]}
      />
      <GenericList
        status={status}
        data={data?.list}
        cols={[
          ["Nazwa", "name", 2],
          ["Telefon", "phone", 2],
          ["Email", "email", 3],
          ["Rola", "roles", 5, (x: Array<UserRoleEnum>) => MapRoles(x, UserRoleEnum_Parser)],
        ]}
        actions={
          ["", "id", 1, (id: string) =>
            <Menu
              header="Opcje"
              items={[
                { label: "Edycja", onAction: () => dispatch(showSlideOverlay(generatePath(RoutePath.PANEL_USERS_EDIT, { id: id }))) },
                { label: "Usuń", onAction: () => alert(id) },
              ]}
            />
          ]
        }
      />
      <Pagination
        disableScroll
        totalCount={data?.totalCount}
        itemsPerPage={10}
        take={pagination.take!}
        setNewSkip={(newSkip: number) => setPagination({ ...pagination, skip: newSkip })}
      />
    </>
  );
};

export default UsersList;