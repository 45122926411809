/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../libs/forms/Form/Form";
import Validators from "../../../../../../../libs/forms/validators/validators";
import Api from "../../../../../../../api/Api";
import { useQuery } from "../../../../../../../api/useQuerySlice";
import { ToastsStore } from "react-toasts";
import { ModifyPricesDto, OfficeDto, PromotionValueType_Enum, PromotionValueType_Enum_Parser } from "../../../../../../../api/office/office.dto";
import { mapEnum } from "../../../../../../../features/enumMap";
import InputBuilder, { AdvancedInputField } from "../../../../../../../libs/filters/InputBuilder";
import "./promotion-form.scss";
import TextBlock from "../../../../../../../libs/text-block/text-block";
import CheckBoxInput from "../../../../../../../libs/forms/CheckBox/CheckBox";
import { SystemScope_Enum } from "../../../../../../../api/shared/System.dto";

interface OfficeEditFormProps {
  data: OfficeDto;
  status: "default" | "loading" | "finished" | "failed";
}

const PriceModificatorEditForm: React.FC<OfficeEditFormProps> = (props) => {
  const { data } = props;
  const { call, status: updateStatus } = useQuery<void, ModifyPricesDto>("office/modifyPrices", [Api.Office.ModifyPrices], { callOnLoad: false });
  const {
    register,
    watch,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ModifyPricesDto>();
  const [showForm, setShowForm] = useState(false);

  const onSubmit = async (dto: ModifyPricesDto) => {
    if (window.confirm("Czy na pewno chcesz wykonać natychmiastową modyfikację cen wszystkich projektów należących do tej pracowni?")) {
      call({ ...dto, scope: SystemScope_Enum.Office, officeId: data.id });
    }
  };

  useEffect(() => {
    if (showForm === false) {
      reset({ valueType: undefined, value: undefined });
      reset({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForm]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Dane zostały zmienione");
      // reset({ valueType: undefined, value: undefined })
      // reset({});

      var checkbox: any = document.getElementById("PriceModificatorEditForm-showForm");
      if (checkbox) {
        checkbox.checked = false;
        setShowForm(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      <TextBlock>
        <p>
          Ta operacja wpłynie na wszystkie projekty należące do tej pracowni, należy zachować szczególną ostrożność. Wartość dodatnia zwiększy ceny, ujemna
          zmniejszy.
        </p>
      </TextBlock>
      <CheckBoxInput
        description="Rozumiem, pokaż modyfikator cen"
        id="PriceModificatorEditForm-showForm"
        inputProps={{
          onChange: (val: any) => setShowForm(val.target.checked),
        }}
      />
      {showForm ? (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-child form-child-wrapper">
            {(
              [
                {
                  field: "valueType",
                  component: "SelectBox",
                  label: "Typ zmiany",
                  validators: Validators.required,
                  options: [
                    ...mapEnum(PromotionValueType_Enum, (x: PromotionValueType_Enum) => {
                      return { id: x, label: PromotionValueType_Enum_Parser(x) };
                    }),
                  ],
                  conditionalFields: [
                    {
                      basedOn: "valueType",
                      condition: (x) => x == PromotionValueType_Enum.Percent,
                      fields: [
                        { field: "value", type: "number", component: "TextBox", placeholder: "5", label: "Wartość (%)", validators: Validators.required },
                      ],
                    },
                    {
                      basedOn: "valueType",
                      condition: (x: PromotionValueType_Enum) => x == PromotionValueType_Enum.Raw,
                      fields: [
                        { field: "value", type: "number", component: "TextBox", placeholder: "123", label: "Wartość (zł)", validators: Validators.required },
                      ],
                    },
                  ],
                },
              ] as Array<AdvancedInputField>
            ).map((x: any, index: number) => {
              return (
                <InputBuilder
                  key={index}
                  advancedInputField={x}
                  formHook={{
                    control: control,
                    getValues: getValues,
                    register: register,
                    watch: watch,
                    errors: errors,
                  }}
                />
              );
            })}
          </div>
          <br />
          <div className="ml-auto">
            <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
              Wykonaj
            </LoadingBtn>
          </div>
        </Form>
      ) : null}
    </>
  );
};

export default PriceModificatorEditForm;
