import React from "react";
import "./panel-page.scss";

const NotFoundPage: React.FC = () => {
  return (
    <div className="panel-page">
      <h1 style={{ margin: '64px', textAlign: 'center' }}>404 - nieznany adres</h1>
    </div>
  );
};

export default NotFoundPage;
