import "./App.scss";

import { RoutePath } from "../route-paths";
import { Route } from "react-router-dom";

import useTheme from "../features/theme-controller/useTheme";
import { AuthRoute, NoAuthRoute } from "../features/auth-provider/AuthProvider";
import MultiRoutes from "../features/auth-provider/AuthRoutes";
import PanelLayout from "./routes/auth/panel/panel-layout";
import NotFoundPage from "./routes/auth/panel/panel-pages/not-found-page";
import PanelPage from "./routes/auth/panel/panel-pages/panel-page";
import ProjectsListPage from "./routes/auth/panel/panel-pages/projects/list/projects-list";
import ProjectsLayout from "./routes/auth/panel/panel-pages/projects/projects-layout";
import AccountPage from "./routes/auth/panel/panel-pages/settings/account/account-page";
import SettingsLayout from "./routes/auth/panel/panel-pages/settings/settings-layout";
import UserAddPage from "./routes/auth/panel/panel-pages/users/add/user-add";
import UserEditPage from "./routes/auth/panel/panel-pages/users/edit/user-edit";
import UsersListPage from "./routes/auth/panel/panel-pages/users/list/users-list";
import UsersLayout from "./routes/auth/panel/panel-pages/users/users-layout";
import RequestResetPasswordPage from "./routes/no-auth/reset-password-page/request-reset-password-page";
import ResetPasswordPage from "./routes/no-auth/reset-password-page/reset-password-page";
import SignInPage from "./routes/no-auth/sign-in-page/sign-in-page";
import SingleBoxLayout from "./routes/no-auth/SingleBoxLayout";
import OfficesLayout from "./routes/auth/panel/panel-pages/offices/offices-layout";
import OfficesListPage from "./routes/auth/panel/panel-pages/offices/list/offices-list";
import OfficeEditPage from "./routes/auth/panel/panel-pages/offices/edit/edit-page";
import OfficesSyncListPage from "./routes/auth/panel/panel-pages/offices/sync-list/sync-list";
import OfficeSyncEditPage from "./routes/auth/panel/panel-pages/offices/sync-edit/sync-edit-page";
import OfficeAddPage from "./routes/auth/panel/panel-pages/offices/add/add-page";
import ProjectEditPage from "./routes/auth/panel/panel-pages/projects/edit/project-edit";
import ProjectAddPage from "./routes/auth/panel/panel-pages/projects/add/add-page";
import OrdersLayout from "./routes/auth/panel/panel-pages/orders/orders-layout";
import {
  OrdersListAllPage,
  OrdersListArchivedPage,
  OrdersListInprogressPage,
  OrdersListNewsPage,
} from "./routes/auth/panel/panel-pages/orders/list/orders-list";
import OrdersManagePage from "./routes/auth/panel/panel-pages/orders/manage/order-manage";
import ServerPage from "./routes/auth/panel/panel-pages/settings/server/server-page";
import ArticleAddPage from "./routes/auth/panel/panel-pages/posts/articles/add/article-add";
import ArticlesLayout from "./routes/auth/panel/panel-pages/posts/articles/articles-layout";
import ArticleEditPage from "./routes/auth/panel/panel-pages/posts/articles/edit/article-edit";
import ArticlesListPage from "./routes/auth/panel/panel-pages/posts/articles/list/articles-list";
import KnowledgeBaseLayout from "./routes/auth/panel/panel-pages/posts/knowledge-base-layout";
import TagAddPage from "./routes/auth/panel/panel-pages/posts/tags/add/tag-add";
import TagEditPage from "./routes/auth/panel/panel-pages/posts/tags/edit/tag-edit";
import TagsListPage from "./routes/auth/panel/panel-pages/posts/tags/list/tags-list";
import TagsLayout from "./routes/auth/panel/panel-pages/posts/tags/tags-layout";
import ProjectCategoryEditPage from "./routes/auth/panel/panel-pages/project-categories/project-categories/edit/project-categories-edit";
import ProjectCategoryAddPage from "./routes/auth/panel/panel-pages/project-categories/project-categories/add/project-category-add";
import ProjectCategoriesListPage from "./routes/auth/panel/panel-pages/project-categories/project-categories/list/project-categories-list";

function App() {
  const theme = useTheme();

  return (
    <div id="app" className={`theme-${theme}`}>
      {/* <SelfRefresher /> */}
      <MultiRoutes>
        <AuthRoute path={RoutePath.AUTH} element={<PanelLayout />}>
          <AuthRoute path={RoutePath.PANEL} element={<PanelPage />}>
            <AuthRoute path={RoutePath.PANEL_ORDERS} element={<OrdersLayout />}>
              <AuthRoute path={RoutePath.PANEL_ORDERS_MANAGE} element={<OrdersManagePage />} />
              <AuthRoute path={RoutePath.PANEL_ORDERS_LIST} element={<OrdersListAllPage />} />
              <AuthRoute path={RoutePath.PANEL_ORDERS_LIST_NEW} element={<OrdersListNewsPage />} />
              <AuthRoute path={RoutePath.PANEL_ORDERS_LIST_INPROGRESS} element={<OrdersListInprogressPage />} />
              <AuthRoute path={RoutePath.PANEL_ORDERS_LIST_ARCHIVED} element={<OrdersListArchivedPage />} />
              {/* <AuthRoute path={RoutePath.PANEL_ORDERS_ADD} element={<ProjectAddPage />} />
              <AuthRoute path={RoutePath.PANEL_ORDERS_EDIT} element={<ProjectEditPage />} /> */}
            </AuthRoute>
            <AuthRoute path={RoutePath.PANEL_PROJECTS} element={<ProjectsLayout />}>
              <AuthRoute path={RoutePath.PANEL_PROJECTS_LIST} element={<ProjectsListPage />} />
              <AuthRoute path={RoutePath.PANEL_PROJECTS_ADD} element={<ProjectAddPage />} />
              <AuthRoute path={RoutePath.PANEL_PROJECTS_EDIT} element={<ProjectEditPage />} />
            </AuthRoute>
            <AuthRoute path={RoutePath.PANEL_PROJECTCATEGORIES} element={<ProjectsLayout />}>
              <AuthRoute path={RoutePath.PANEL_PROJECTCATEGORIES_LIST} element={<ProjectCategoriesListPage />} />
              <AuthRoute path={RoutePath.PANEL_PROJECTCATEGORIES_ADD} element={<ProjectCategoryAddPage />} />
              <AuthRoute path={RoutePath.PANEL_PROJECTCATEGORIES_EDIT} element={<ProjectCategoryEditPage />} />
            </AuthRoute>
            <AuthRoute path={RoutePath.PANEL_OFFICES} element={<OfficesLayout />}>
              <AuthRoute path={RoutePath.PANEL_OFFICES_LIST} element={<OfficesListPage />} />
              <AuthRoute path={RoutePath.PANEL_OFFICES_EDIT} element={<OfficeEditPage />} />
              <AuthRoute path={RoutePath.PANEL_OFFICES_ADD} element={<OfficeAddPage />} />
              <AuthRoute path={RoutePath.PANEL_OFFICES_SYNC_LIST} element={<OfficesSyncListPage />} />
              <AuthRoute path={RoutePath.PANEL_OFFICES_SYNC_EDIT} element={<OfficeSyncEditPage />} />
            </AuthRoute>

            <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE} element={<KnowledgeBaseLayout />}>
              <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES} element={<ArticlesLayout />}>
                <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES_LIST} element={<ArticlesListPage />} />
                <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES_ADD} element={<ArticleAddPage />} />
                <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES_EDIT} element={<ArticleEditPage />} />
              </AuthRoute>
              <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_TAGS} element={<TagsLayout />}>
                <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_TAGS_LIST} element={<TagsListPage />} />
                <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_TAGS_ADD} element={<TagAddPage />} />
                <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_TAGS_EDIT} element={<TagEditPage />} />
              </AuthRoute>
            </AuthRoute>

            <AuthRoute path={RoutePath.PANEL_SETTINGS} element={<SettingsLayout />}>
              <AuthRoute path={RoutePath.PANEL_SETTINGS_ACCOUNT} element={<AccountPage />} />
              <AuthRoute path={RoutePath.PANEL_SETTINGS_SERVER} element={<ServerPage />} />
            </AuthRoute>
            <AuthRoute path={RoutePath.PANEL_USERS} element={<UsersLayout />}>
              <AuthRoute path={RoutePath.PANEL_USERS_LIST} element={<UsersListPage />} />
              <AuthRoute path={RoutePath.PANEL_USERS_ADD} element={<UserAddPage />} />
              <AuthRoute path={RoutePath.PANEL_USERS_EDIT} element={<UserEditPage />} />
            </AuthRoute>
            <AuthRoute path={"*"} element={<NotFoundPage />} />
          </AuthRoute>
          <AuthRoute path={"*"} element={<NotFoundPage />} />
        </AuthRoute>
        <Route element={<SingleBoxLayout />}>
          <NoAuthRoute path={RoutePath.REQUEST_RESET_PASSWORD} element={<RequestResetPasswordPage />} />
          <NoAuthRoute path={RoutePath.RESET_PASSWORD} element={<ResetPasswordPage />} />
          <NoAuthRoute path={RoutePath.SIGN_IN} element={<SignInPage />} />
          <NoAuthRoute path={RoutePath.HOME} element={<SignInPage />} />
          <Route path={"*"} element={<SignInPage />} />
        </Route>
      </MultiRoutes>
      {/*<CookiePolicyPopup /> 
      <Rect /> */}
    </div>
  );
}

export default App;
