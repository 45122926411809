import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { RoutePath } from "../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../components/Breadcrumbs/BreadcrumbsItem";
import ExactPathSwitch from "../../../../../components/ExactPathSwitch/ExactPathSwitch";

const OfficesLayout: React.FC = () => {

  return (
    <>
      <BreadcrumbsItem to={RoutePath.PANEL_OFFICES} title="Pracownie" isNotLink={true} />
      <ExactPathSwitch
        path={RoutePath.PANEL_OFFICES}
        if_exact={<Navigate to={RoutePath.PANEL_OFFICES_SYNC_LIST} />}
        else={<Outlet />}
      />
    </>
  );
};

export default OfficesLayout;
