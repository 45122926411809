import React from "react";
import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../components/Tile/Tile";
import AccountEditForm from "./AccountEditForm/AccountEditForm";
import ChangePasswordForm from "./ChangePasswordForm/ChangePasswordForm";

const AccountPage: React.FC = () => {
  return (
    <div className="account-page">
      <BreadcrumbsItem to={RoutePath.PANEL_SETTINGS_ACCOUNT} title="Konto" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Ustawienia konta</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={[4, 6, 8, 12]}>
            <Tile>
              <h2 className="title">Edycja konta</h2>
              <AccountEditForm />
            </Tile>
          </Col>
          <Col size={[4, 6, 8, 12]}>
            <Tile>
              <h2 className="title">Zmiana hasła</h2>
              <ChangePasswordForm />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div >
  );
};

export default AccountPage;
