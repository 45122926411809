import "./related-project-categories-add-modal.scss";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { ModalContext } from "../../../../../../../components/Modal/ModalContext";
import { ProjectCategoryDto, RelatedProjectCategoryRelationDto } from "../../../../../../../../api/projectCategory/projectCategory.dto";
import { ArrayResult } from "../../../../../../../../api/client";
import { BasicColumnFilterDto } from "../../../../../../../../libs/filters/filters.dto";
import Api from "../../../../../../../../api/Api";
import LoadingBtn from "../../../../../../../../libs/buttons/Button/LoadingBtn";
import GenericList from "../../../../../../../../libs/generic-list/generic-list";
import useEffectAfterMounted from "../../../../../../../../features/useEffectAfterMounted";
import { ToastsStore } from "react-toasts";

interface Props {
  projectId: number;
}

const RelatedProjectCategoriesAddModal = (props: Props) => {
  const { projectId } = props;
  const { handleConfirm } = useContext(ModalContext);
  const [selectedItem, setSelectedItem] = useState<ProjectCategoryDto>();
  const {
    data: projectCategoriesList, //
    call: callList,
    status: listStatus,
  } = useQuery<ArrayResult<ProjectCategoryDto>, BasicColumnFilterDto>("ProjectCategories/List-all", [
    Api.ProjectCategory.List,
    {
      searchByColumn: "notProjectId",
      searchId: projectId,
    },
  ]);
  const {
    call, //
    status,
  } = useQuery<void, RelatedProjectCategoryRelationDto>("ProjectCategory/AddRelatedProjectCategory", [Api.ProjectCategory.AddProjectRelationCategory], {
    callOnLoad: false,
  });

  useEffectAfterMounted(() => {
    callList({
      otherFilters: [
        {
          searchByColumn: "notProjectId",
          searchId: projectId,
        },
      ],
    });
  }, []);

  const onSubmit = () => {
    if (selectedItem && selectedItem?.id) {
      var addDto = {
        projectId: projectId,
        categoryId: selectedItem.id,
      } as RelatedProjectCategoryRelationDto;
      call(addDto);
    }
  };

  useEffect(() => {
    if (status === "finished") {
      ToastsStore.success("Powiązano");
      handleConfirm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className="related-projects-categories-add-modal">
      {projectCategoriesList ? (
        <>
          <div className="list-wrapper">
            <GenericList
              status={listStatus}
              data={projectCategoriesList.list}
              cols={[
                // [
                //   "Podgląd",
                //   "img",
                //   2,
                //   (x) => (
                //     <div
                //       style={{
                //         backgroundColor: "#f7f7f7",
                //         backgroundImage: `url(${apiURL + x})`,
                //         width: 48,
                //         height: 32,
                //         backgroundPosition: "center",
                //         backgroundSize: "contain",
                //         backgroundRepeat: "no-repeat",
                //       }}
                //     />
                //   ),
                // ],
                ["Id", "id", 2],
                ["Tytuł", "title", 8],
              ]}
              inputProps={{
                multiselect: false,
                onChange: (x: any) => setSelectedItem(x),
              }}
            />
          </div>
        </>
      ) : null}

      <div className="ml-auto pt-3">
        <LoadingBtn onClick={() => onSubmit()} status={status} className="ml-auto">
          Dodaj
        </LoadingBtn>
      </div>
    </div>
  );
};

export default RelatedProjectCategoriesAddModal;
