import Client, { ArrayResult } from "../client";
import { BasicColumnFilterDto } from "../../libs/filters/filters.dto";
import {
  ProjectCategoryDto,
  ProjectCategoryContentDto,
  RelatedProjectCategoryRelationDto,
  RelatedCategoryToProjectDto,
  RelatedProjectToCategoryDto,
} from "./projectCategory.dto";
import { IdNameDto, MediaAddDto, MediaDeleteDto, MediaUpdateDto } from "../shared/System.dto";

class ProjectCategory {
  static async Get(id: number): Promise<ProjectCategoryDto> {
    return Client.get("ProjectCategory/Get", { id: id.toString() });
  }
  static async ListNames(): Promise<Array<IdNameDto>> {
    return Client.get("ProjectCategory/ListNames");
  }
  static async List(filters?: BasicColumnFilterDto): Promise<ArrayResult<ProjectCategoryDto>> {
    return Client.get("ProjectCategory/List", { ...filters });
  }
  static async Add(dto: ProjectCategoryDto): Promise<void> {
    return Client.post("ProjectCategory/Add", dto);
  }
  static async Update(dto: ProjectCategoryDto): Promise<void> {
    return Client.post("ProjectCategory/Update", dto);
  }
  static async UpdateProjectCategoryDescriptionBottom(dto: ProjectCategoryContentDto): Promise<void> {
    return Client.post("ProjectCategory/UpdateProjectCategoryDescriptionBottom", dto);
  }
  static async Delete(id: number): Promise<void> {
    return Client.delete("ProjectCategory/Delete", id.toString());
  }
  static async AddProjectRelationCategory(dto: RelatedProjectCategoryRelationDto): Promise<void> {
    return Client.post("ProjectCategory/AddProjectRelationCategory", {}, { ...dto });
  }
  static async DeleteProjectRelationCategory(dto: RelatedProjectCategoryRelationDto): Promise<void> {
    return Client.delete("ProjectCategory/DeleteProjectRelationCategory", { ...dto });
  }
  static async ListRelatedCategoriesByProject(projectId: number): Promise<Array<RelatedCategoryToProjectDto>> {
    return Client.get("ProjectCategory/ListRelatedCategoriesByProject", { projectId: projectId });
  }
  static async ListRelatedProjectsByCategory({
    categoryId,
    filters,
  }: {
    categoryId: number;
    filters: BasicColumnFilterDto;
  }): Promise<ArrayResult<RelatedProjectToCategoryDto>> {
    return Client.get("ProjectCategory/ListRelatedProjectsByCategory", { categoryId: categoryId, ...filters });
  }
  static async UpdateMedia(dto: MediaUpdateDto): Promise<void> {
    return Client.upload("ProjectCategory/UpdateMedia", dto);
  }
  static async AddMedia(dto: MediaAddDto): Promise<void> {
    return Client.upload("ProjectCategory/AddMedia", dto);
  }
  static async DeleteMedia(dto: MediaDeleteDto): Promise<void> {
    return Client.post("ProjectCategory/DeleteMedia", dto);
  }
}

export default ProjectCategory;
