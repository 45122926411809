import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { OrderDto } from "../../../../../../../../api/order/order.dto";
import { OfficeDto } from "../../../../../../../../api/office/office.dto";
import Row from "../../../../../../../../libs/grid/row";
import Col from "../../../../../../../../libs/grid/col";

interface ProjectInfoFormProps {
  order: OrderDto;
}

interface ProjectOrderInfo {
  id?: number;
  office: string;
  nazwa: string;
  opis: string;
  cena__raw_brutto: number;
  cena__promotion_brutto: number | null;
  side_variant: string;
}

const ProjectInfoForm: React.FC<ProjectInfoFormProps> = (props) => {
  const { order } = props;
  const { data: office, call: getOffice } = useQuery<OfficeDto, string>("order/get", [Api.Office.Get, order.projectCopy?.officeId?.toString()], {
    callOnLoad: true,
  });

  const { register, reset } = useForm<ProjectOrderInfo>({ defaultValues: {} });

  useEffect(() => {
    var project = order.projectCopy;
    reset({
      id: project.id ?? -1,
      office: office?.name ?? "officeId:" + project.officeId.toString(),
      nazwa: project.nazwa,
      side_variant: order.side_variant === "0" ? "NIE" : "TAK",
      cena__raw_brutto: project.cena__raw_brutto,
      cena__promotion_brutto: project.cena__promotion_brutto,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, office]);

  useEffect(() => {
    if (order && order.projectCopy && order.projectCopy.officeId) {
      getOffice(order.projectCopy.officeId.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <>
      <Form>
        <Row gap={0}>
          <Col size={6}>
            <TextInput
              id="id"
              label="Id projektu"
              inputProps={{
                ...register("id"),
                disabled: true,
              }}
            />
            <TextInput
              id="nazwa"
              label="Nazwa projektu"
              inputProps={{
                ...register("nazwa"),
                disabled: true,
              }}
            />
            <TextInput
              id="office"
              label="Biuro"
              inputProps={{
                ...register("office"),
                disabled: true,
              }}
            />
          </Col>
          <Col size={6}>
            <TextInput
              id="side_variant"
              label="Odbicie lustrzane?"
              inputProps={{
                ...register("side_variant"),
                disabled: true,
              }}
            />
            {order?.projectCopy?.cena__raw_brutto ? (
              <TextInput
                id="cena__raw_brutto"
                label="Cena brutto"
                inputProps={{
                  ...register("cena__raw_brutto"),
                  disabled: true,
                }}
              />
            ) : null}
            {order?.projectCopy?.cena__promotion_brutto ? (
              <TextInput
                id="cena__promotion_brutto"
                label="Cena brutto w promocji"
                inputProps={{
                  ...register("cena__promotion_brutto"),
                  disabled: true,
                }}
              />
            ) : null}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ProjectInfoForm;
