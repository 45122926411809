import React from "react";
import { generatePath } from "react-router-dom";
import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../components/Tile/Tile";
import OfficeAddForm from "./add-form";

const OfficeAddPage: React.FC = () => {
  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_OFFICES_LIST} title="Lista" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_OFFICES_ADD)} title={`Dodawanie`} />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Dodawanie pracowni</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={[4, 6, 6, 12]}>
            <Tile>
              <h2 className="title">Parametry podstawowe</h2>
              <OfficeAddForm />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div >
  );
};

export default OfficeAddPage;
