import React, { useEffect } from "react";
import Api from "../../../../../../../api/Api";
import { useQuery } from "../../../../../../../api/useQuerySlice";
import { OfficeDto } from "../../../../../../../api/office/office.dto";
import GenericList from "../../../../../../../libs/generic-list/generic-list";
import Menu from "../../../../../../components/Menu/menu";
import { Confirm } from "../../../../../../../features/confirm";
import { BenefitDto } from "../../../../../../../api/benefit/benefit.dto";
import Modal from "../../../../../../components/Modal/Modal";
import BenefitAddModal from "./benefit-addEdit-modal";
import BenefitAddEditModal from "./benefit-addEdit-modal";
import { SystemScope_Enum } from "../../../../../../../api/shared/System.dto";
import { useTrigger } from "../../../../../../components/GlobalTrigger/GlobalTriggerSlice";

interface BenefitsListProps {
  data: OfficeDto;
  status: "default" | "loading" | "finished" | "failed";
}

const BenefitsList: React.FC<BenefitsListProps> = (props) => {
  const { data } = props;
  const { setTrigger, triggerId } = useTrigger();

  const {
    call,
    status,
    data: benefitsData,
  } = useQuery<Array<BenefitDto>, number>("benefits/listFromOffice", [Api.Benefit.ListFromOffice, data.id], { callOnLoad: true });
  const { call: deleteBenefit, status: deleteStatus } = useQuery<void, number>("benefits/delete", [Api.Benefit.Delete], { callOnLoad: false });

  useEffect(() => {
    if (deleteStatus === "finished") call(data.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  return (
    <>
      <div className="d-flex pb-3">
        <h2 className="title mr-auto">Pakiet korzyści</h2>
        <Modal
          title={`Dodaj nowy pakiet korzyści`}
          disableStandardButtons={true}
          button={{
            title: `+ Dodaj nowy`,
            variant: "like-input-primary",
          }}
          confirm={() => call(data.id)}
        >
          <div style={{ maxWidth: "1000px" }}>
            <BenefitAddModal officeId={data.id} scope={SystemScope_Enum.Office} />
          </div>
        </Modal>
      </div>
      <hr />
      <GenericList
        status={status}
        data={benefitsData}
        cols={[
          ["Id", "id", 0],
          ["Nazwa", "name", 3],
          ["Opis", "description", 8],
          [
            "",
            "",
            0,
            (x) => {
              return (
                <Modal
                  title={`Edycja korzyści`}
                  button={{
                    hidden: true,
                  }}
                  disableStandardButtons={true}
                  openTrigger={triggerId === "benefits_addEdit_modal--" + x.id}
                  confirm={() => call(data.id)}
                >
                  <div style={{ maxWidth: "1000px" }}>
                    <BenefitAddEditModal officeId={data.id} item={x} scope={SystemScope_Enum.Office} />
                  </div>
                </Modal>
              );
            },
          ],
        ]}
        actions={[
          "",
          "",
          1,
          (item: BenefitDto) => (
            <Menu
              header="Opcje"
              items={[
                {
                  label: "Zarządzaj",
                  onAction: () => setTrigger("benefits_addEdit_modal--" + item.id),
                },
                { label: "Usuń", onAction: () => Confirm(`Czy na pewno chcesz usunąć ${item.name}?`, () => deleteBenefit(item.id)) },
              ]}
            />
          ),
        ]}
      />
    </>
  );
};

export default BenefitsList;
