import * as React from "react";
import { Route, Routes, RoutesProps } from "react-router-dom";
import { AuthProvider, AuthRoute, NoAuthProvider, NoAuthRoute } from "./AuthProvider";

interface Props extends RoutesProps {
  children?: React.ReactNode;
  location?: Partial<Location> | string;
}

const AuthRoutes: React.FC<Props> = (props) => {
  var authRouteStandardModel = (<AuthRoute path={""} />).type.name;
  var noAuthRouteStandardModel = (<NoAuthRoute path={""} />).type.name;

  const mapChildrenRoutes = (children?: React.ReactNode) => {
    return (
      <>
        {React.Children.map(children, (child) => {
          if (!child) return <></>;
          const item = child as any;

          if (item.type.name === authRouteStandardModel) {
            return (
              <Route {...item.props.routeProps} path={item.props.path} element={<AuthProvider roles={item.props.roles}>{item.props.element}</AuthProvider>}>
                {item.props.children ? mapChildrenRoutes(item.props.children) : null}
              </Route>
            );
          } else if (item.type.name === noAuthRouteStandardModel) {
            return (
              <Route {...item.props.routeProps} path={item.props.path} element={<NoAuthProvider>{item.props.element}</NoAuthProvider>}>
                {item.props.children ? mapChildrenRoutes(item.props.children) : null}
              </Route>
            );
          } else return <Route {...item.props}>{item.props.children ? mapChildrenRoutes(item.props.children) : null}</Route>;
        })}
      </>
    );
  };
  return <Routes>{mapChildrenRoutes(props.children)}</Routes>;
};

export default AuthRoutes;
