import { useEffect, useState } from "react";
import Api from "../../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../../features/confirm";
import GenericList from "../../../../../../../../../libs/generic-list/generic-list";
import Menu from "../../../../../../../../components/Menu/menu";
import Modal from "../../../../../../../../components/Modal/Modal";
import { ToastsStore } from "react-toasts";
import { apiURL, ArrayResult } from "../../../../../../../../../api/client";
import { RelatedProjectCategoryRelationDto } from "../../../../../../../../../api/projectCategory/projectCategory.dto";
import { BasicColumnFilterDto } from "../../../../../../../../../libs/filters/filters.dto";
import Pagination from "../../../../../../../../components/Pagination/Pagination";
import { PaginationFilterDto } from "../../../../../../../../components/Pagination/pagination.dto";
import { Bud__RodzajZabudowy__Enum, Bud__RodzajZabudowy__Enum_Parser, SafeProjectDto } from "../../../../../../../../../api/project/project.dto";
import RelatedProjectsAddModal from "./related-projects-add-modal";
import { OfficeNameDto } from "../../../../../../../../../api/office/office.dto";
import Filters from "../../../../../../../../../libs/filters/FiltersForm";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import { mapEnum } from "../../../../../../../../../features/enumMap";

interface Props {
  categoryId: number;
}

const RelatedProjects = (props: Props) => {
  const { categoryId } = props;
  const [pagination, setPagination] = useState<PaginationFilterDto>({ take: 30, skip: 0 });
  const [filters, setFilters] = useState<BasicColumnFilterDto>({
    otherFilters: [
      {
        searchByColumn: "categoryId",
        searchId: categoryId,
      },
    ],
  });
  const {
    data: officesNames = [], //
  } = useQuery<Array<OfficeNameDto>>("office/listNames", [Api.Office.ListNames], { callOnLoad: true });
  const {
    data: projectsList, //
    call: callList,
    status: listStatus,
  } = useQuery<ArrayResult<SafeProjectDto>, BasicColumnFilterDto>("Project/List-all-in-category", [Api.Project.List, { ...filters, ...pagination }]);

  const {
    call: callDeleteRelatedTag, //
    status: deleteRelatedTagStatus,
  } = useQuery<void, RelatedProjectCategoryRelationDto>("ProjectCategory/DeleteProjectRelationCategory", [Api.ProjectCategory.DeleteProjectRelationCategory], {
    callOnLoad: false,
  });

  useEffect(() => {
    if (deleteRelatedTagStatus === "finished") {
      callList({
        ...filters,
        ...pagination,
        otherFilters: [
          {
            searchByColumn: "categoryId",
            searchId: categoryId,
          },
        ],
      });
      ToastsStore.success("Usunięto");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRelatedTagStatus]);

  return (
    <>
      <div className="d-flex pb-3">
        <div className="mr-auto">
          <h2 className="title pr-4">🔗 Powiązane projekty</h2>
        </div>
        <Modal
          title={`Dodaj nowe powiązanie`}
          disableStandardButtons={true}
          button={{
            title: `+ Dodaj nowy`,
            variant: "like-input-primary",
          }}
          maxWidth={1200}
          confirm={() =>
            callList({
              ...filters,
              ...pagination,
              otherFilters: [
                {
                  searchByColumn: "categoryId",
                  searchId: categoryId,
                },
              ],
            })
          }
          className={"RelatedTagAddEditModal"}
        >
          <RelatedProjectsAddModal categoryId={categoryId} />
        </Modal>
      </div>
      <hr />
      <Filters
        onSubmit={setFilters}
        setPagination={setPagination}
        status={listStatus}
        fields={[
          {
            field: "searchByColumn",
            component: "SelectBox",
            label: "Kolumna",
            options: [
              { id: "nazwa", label: "Nazwa" },
              { id: "bud__rodz_zabudowy", label: "Rodzaj zabudowy" },
              { id: "officeId", label: "Pracownia" },
            ],
            conditionalFields: [
              {
                basedOn: "searchByColumn",
                condition: (x: any) => x === "nazwa",
                fields: [{ field: "searchText", component: "TextBox", placeholder: "Wyszukaj", label: "Zawartość kolumny", validators: Validators.required }],
              },
              {
                basedOn: "searchByColumn",
                condition: (x: any) => x === "bud__rodz_zabudowy",
                fields: [
                  {
                    field: "searchEnum",
                    component: "SelectBox",
                    label: "Wyszukaj",
                    validators: Validators.required,
                    options: mapEnum(Bud__RodzajZabudowy__Enum, (key: any) => {
                      return {
                        id: key,
                        label: Bud__RodzajZabudowy__Enum_Parser(key),
                      };
                    }),
                  },
                ],
              },
              {
                basedOn: "searchByColumn",
                condition: (x: any) => x === "officeId",
                fields: [
                  {
                    field: "searchId",
                    component: "SelectBox",
                    label: "Wyszukaj",
                    validators: Validators.required,
                    options: officesNames.map((x) => {
                      return {
                        id: x.id,
                        label: x.name,
                      };
                    }),
                  },
                ],
              },
            ],
          },
        ]}
      />
      <GenericList
        status={listStatus}
        data={projectsList?.list}
        cols={[
          [
            "Podgląd",
            "img",
            2,
            (x) => (
              <div
                style={{
                  backgroundColor: "#f7f7f7",
                  backgroundImage: `url(${apiURL + x})`,
                  width: 48,
                  height: 32,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              />
            ),
          ],
          ["Id", "id", 2],
          ["Nazwa", "nazwa", 7],
        ]}
        actions={[
          "",
          "",
          1,
          (item: SafeProjectDto) => (
            <Menu
              header="Opcje"
              items={[
                {
                  label: "Usuń relację",
                  onAction: () =>
                    Confirm(`Czy na pewno chcesz usunąć powiązanie z ${item.nazwa}?`, () =>
                      callDeleteRelatedTag({ projectId: item.id!, categoryId: categoryId })
                    ),
                },
              ]}
            />
          ),
        ]}
      />
      <Pagination
        disableScroll
        totalCount={projectsList?.totalCount}
        itemsPerPage={10}
        take={pagination.take!}
        skip={pagination.skip}
        setNewSkip={(newSkip: number) => setPagination({ ...pagination, skip: newSkip })}
      />

      {/* <GenericList
        status={status}
        data={data}
        cols={[
          ["Obraz", "image", 2, (x) => <GenericListIcon img={process.env.REACT_APP_API_URL + x?.path} size="md" openOnClick={true} />],
          ["Id", "tagId", 2],
          ["Nazwa", "name", 7],
        ]}
        actions={[
          "",
          "",
          1,
          (item: ArticleRelationTagDto) => (
            <Menu
              header="Opcje"
              items={[
                {
                  label: "Usuń relację",
                  onAction: () =>
                    Confirm(`Czy na pewno chcesz usunąć powiązanie z ${item.name}?`, () =>
                      callDeleteRelatedTag({ articleId: item.parentArticleId, relatedTagId: item.tagId })
                    ),
                },
              ]}
            />
          ),
        ]}
      /> */}
    </>
  );
};

export default RelatedProjects;
