import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../libs/forms/validators/validators";
import Api from "../../../../../../../api/Api";
import { useQuery, useQueryRefetch } from "../../../../../../../api/useQuerySlice";
import { ToastsStore } from "react-toasts";
import { OfficeDto } from "../../../../../../../api/office/office.dto";
interface OfficeEditFormProps {
  data: OfficeDto;
  status: "default" | "loading" | "finished" | "failed";
}

const OfficeEditForm: React.FC<OfficeEditFormProps> = (props) => {
  const { data } = props;
  const { call, status: updateStatus } = useQuery<void, OfficeDto>('office/update', [Api.Office.Update], { callOnLoad: false });
  const { register, handleSubmit, formState: { errors }, reset } = useForm<OfficeDto>({ defaultValues: data });
  const { refetch } = useQueryRefetch();

  const onSubmit = async (dto: OfficeDto) => {
    call({ ...data, ...dto });
  };

  useEffect(() => {
    reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (updateStatus === 'finished') {
      ToastsStore.success("Dane zostały zmienione");
      refetch("office/get");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus])


  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} direction="row" cols={2}>
        <TextInput
          id="name"
          label="Nazwa"
          validation_msg={errors?.name?.message}
          inputProps={{
            ...register("name", Validators.required)
          }}
        />
        <TextInput
          id="description"
          label="Opis"
          validation_msg={errors?.description?.message}
          inputProps={{
            type: "textarea",
            rows: 14,
            ...register("description", Validators.required)
          }}
        />
        <br />
        <div className="ml-auto">
          <LoadingBtn type="submit" status={updateStatus} className="ml-auto">Zapisz</LoadingBtn>
        </div>
      </Form>
    </>
  );
};

export default OfficeEditForm;
