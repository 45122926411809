import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import Api from "../../../../../../../api/Api";
import { BenefitDto } from "../../../../../../../api/benefit/benefit.dto";
import { SystemScope_Enum } from "../../../../../../../api/shared/System.dto";
import { useQuery } from "../../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../libs/forms/validators/validators";
import { ModalContext } from "../../../../../../components/Modal/ModalContext";

interface BenefitAddModalProps {
  officeId?: number;
  projectId?: number;
  scope: SystemScope_Enum;
  item?: BenefitDto;
}

const BenefitAddModal: React.FC<BenefitAddModalProps> = (props) => {
  const { call, status } = useQuery<void, BenefitDto>("benefit/add", [Api.Benefit.Add], { callOnLoad: false });
  const { call: editCall, status: editStatus } = useQuery<void, BenefitDto>("benefit/edit", [Api.Benefit.Update], { callOnLoad: false });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<BenefitDto>({ defaultValues: {} });
  const { handleConfirm } = useContext(ModalContext);
  const { officeId, projectId, scope, item } = props;

  const onSubmit = (data: BenefitDto) => {
    if (scope === SystemScope_Enum.Project && projectId) {
      if (item) editCall({ ...data, projectId: projectId, scope: scope });
      else call({ ...data, projectId: projectId, scope: scope });
    } else if (scope === SystemScope_Enum.Office && officeId) {
      if (item) editCall({ ...data, officeId: officeId, scope: scope });
      else call({ ...data, officeId: officeId, scope: scope });
    }
  };

  useEffect(() => {
    if (status === "finished" || editStatus === "finished") handleConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, editStatus]);

  useEffect(() => {
    if (item !== null) {
      reset({ ...item });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} cols={[1, 1, 1, 1]} direction="row">
      <TextInput
        id="name"
        label="Nazwa"
        validation_msg={errors?.name?.message}
        required
        inputProps={{
          ...register("name", Validators.required),
        }}
      />
      <TextInput
        id="description"
        label="Opis"
        validation_msg={errors?.description?.message}
        inputProps={{
          type: "textarea",
          ...register("description"),
        }}
      />
      <br />
      <div className="ml-auto">
        {item ? (
          <LoadingBtn type="submit" status={editStatus} className="ml-auto">
            Zapisz
          </LoadingBtn>
        ) : (
          <LoadingBtn type="submit" status={status} className="ml-auto">
            Dodaj
          </LoadingBtn>
        )}
      </div>
    </Form>
  );
};

export default BenefitAddModal;
