import React, { useState } from "react";
import { generatePath } from "react-router-dom";

import Api from "../../../../../../../../api/Api";
import { apiURL, ArrayResult, publicURL } from "../../../../../../../../api/client";
import { OfficeNameDto } from "../../../../../../../../api/office/office.dto";
import { Bud__RodzajZabudowy__Enum, Bud__RodzajZabudowy__Enum_Parser, SafeProjectDto } from "../../../../../../../../api/project/project.dto";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../features/confirm";
import { mapEnum } from "../../../../../../../../features/enumMap";
import useEffectAfterMounted from "../../../../../../../../features/useEffectAfterMounted";
import { BasicFilterDto, StandardFilterDto } from "../../../../../../../../libs/filters/filters.dto";
import Filters from "../../../../../../../../libs/filters/FiltersForm";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import GenericList from "../../../../../../../../libs/generic-list/generic-list";
import { RoutePath } from "../../../../../../../../route-paths";
import Menu from "../../../../../../../components/Menu/menu";
import Pagination from "../../../../../../../components/Pagination/Pagination";
import { PaginationFilterDto } from "../../../../../../../components/Pagination/pagination.dto";
import TopLink from "../../../../../../../components/top-link/top-link";

interface ProjectsListProps {}

const ProjectsList: React.FC<ProjectsListProps> = (props) => {
  const [filters, setFilters] = useState<StandardFilterDto>({});
  const [pagination, setPagination] = useState<PaginationFilterDto>({ take: 10, skip: 0 });
  // const defaultSettings = useAppSelector(selectProjectsListSettings);

  const { data, status, call } = useQuery<ArrayResult<SafeProjectDto>, BasicFilterDto>("project/list", [Api.Project.List, { ...filters, ...pagination }]);

  const { data: officesNames = [] } = useQuery<Array<OfficeNameDto>>("office/listNames", [Api.Office.ListNames], { callOnLoad: true });

  const { call: callToggleActive, status: toggleActiveStatus } = useQuery<void, number>("project/toggleActive", [Api.Project.ToggleActive], {
    callOnLoad: false,
  });

  const { call: callToggleDelete, status: toggleDeleteStatus } = useQuery<void, number>("project/toggleDelete", [Api.Project.ToggleDelete], {
    callOnLoad: false,
  });

  const { call: callPermDelete, status: permDeleteStatus } = useQuery<void, number>("project/permDelete", [Api.Project.PermDelete], {
    callOnLoad: false,
  });

  useEffectAfterMounted(() => {
    // dispatch(saveSettings({ pagination: pagination, filters: filters }));
    call({ ...filters, ...pagination });
  }, [filters, pagination]);

  useEffectAfterMounted(() => {
    if (toggleActiveStatus === "finished" || toggleDeleteStatus === "finished" || permDeleteStatus === "finished") {
      call({ ...filters, ...pagination });
    }
  }, [toggleActiveStatus, toggleDeleteStatus, permDeleteStatus]);

  // useEffect(() => {
  //   console.log(defaultSettings);
  //   if (defaultSettings.pagination) setPagination(defaultSettings.pagination);
  //   if (defaultSettings.filters) setFilters(defaultSettings.filters);

  //   return () => {
  //     console.log(pagination, filters)
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      <Filters
        onSubmit={setFilters}
        setPagination={setPagination}
        status={status}
        fields={[
          {
            field: "searchByColumn",
            component: "SelectBox",
            label: "Kolumna",
            options: [
              { id: "nazwa", label: "Nazwa" },
              { id: "bud__rodz_zabudowy", label: "Rodzaj zabudowy" },
              { id: "officeId", label: "Pracownia" },
            ],
            conditionalFields: [
              {
                basedOn: "searchByColumn",
                condition: (x: any) => x === "nazwa",
                fields: [{ field: "searchText", component: "TextBox", placeholder: "Wyszukaj", label: "Zawartość kolumny", validators: Validators.required }],
              },
              {
                basedOn: "searchByColumn",
                condition: (x: any) => x === "bud__rodz_zabudowy",
                fields: [
                  {
                    field: "searchEnum",
                    component: "SelectBox",
                    label: "Wyszukaj",
                    validators: Validators.required,
                    options: mapEnum(Bud__RodzajZabudowy__Enum, (key: any) => {
                      return {
                        id: key,
                        label: Bud__RodzajZabudowy__Enum_Parser(key),
                      };
                    }),
                  },
                ],
              },
              {
                basedOn: "searchByColumn",
                condition: (x: any) => x === "officeId",
                fields: [
                  {
                    field: "searchId",
                    component: "SelectBox",
                    label: "Wyszukaj",
                    validators: Validators.required,
                    options: officesNames.map((x) => {
                      return {
                        id: x.id,
                        label: x.name,
                      };
                    }),
                  },
                ],
              },
            ],
          },
        ]}
      />
      <GenericList
        status={status}
        data={data?.list}
        rowClassName={(x: SafeProjectDto) => (x.isActive && !x.deletedDate ? "" : "inactive")}
        cols={[
          [
            "Podgląd",
            "img",
            1,
            (x) => (
              <div
                style={{
                  backgroundColor: "#f7f7f7",
                  backgroundImage: `url(${apiURL + x})`,
                  width: 48,
                  height: 32,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              />
            ),
          ],
          [
            "",
            "",
            1,
            (x: SafeProjectDto) => (
              <a href={`${publicURL}gotowe-projekty/wszystkie/${x.id}`} target="_blank" rel="noreferrer" className="d-inline-block clickable p-1 ml-1">
                🔍
              </a>
            ),
          ],
          ["Id", "id", 1],
          [
            "Nazwa",
            "",
            3,
            (project) => (
              <TopLink to={generatePath(RoutePath.PANEL_PROJECTS_EDIT, { projectId: project.id?.toString() })} className="primary medium-weight">
                {project.nazwa}
              </TopLink>
            ),
          ],
          ["Cena", "cena__raw_brutto", 2, (price) => (price ? price.toFixed(2) + "zł" : "-")],
          ["Cena prom", "cena__promotion_brutto", 2, (price) => (price ? price.toFixed(2) + "zł" : "-")],

          // ["Opis", "opis", 1, (opis) => (opis ? opis.substring(0, 100) + "..." : "-")],
          // ["Czy aktywny?", "isActive", 6, (isActive) => <div className="ml-3">{isActive ? "🟢" : "⚫️"}</div>],
          [
            "Aktywny?",
            "",
            2,
            (project) => (
              <div
                className="ml-2  d-inline-block clickable p-1"
                onClick={() => callToggleActive(Number(project.id))}
                title={project.isActive ? "Aktywny" : "Nieaktywny"}
              >
                {project.isActive ? "🟢" : "⚫️ Nieaktywny"}
              </div>
            ),
          ],
          [
            "Dostępność",
            "",
            3,
            (project) => (
              <div
                className="ml-2 d-inline-block clickable p-1"
                onClick={() => callToggleDelete(Number(project.id))}
                title={!project.deletedDate ? "Dostępny" : "Niedostępny"}
              >
                {!project.deletedDate ? "🟢" : "🔴 Niedostępny"}
              </div>
            ),
          ],
        ]}
        actions={[
          "",
          "",
          1,
          (project: SafeProjectDto) => (
            <Menu
              header="Opcje"
              items={[
                // {
                //   customRender: (
                //     <div
                //       className="select-menu__modal__item"
                //       onClick={() => dispatch(showSlideOverlay(generatePath(RoutePath.PANEL_PROJECTS_EDIT, { projectId: id })))}
                //       onMouseDown={(event) => {
                //         var projPath = generatePath(RoutePath.PANEL_PROJECTS_EDIT, { projectId: id });
                //         if (event.button === 1) window.open(projPath);
                //         else return null;
                //       }}
                //     >
                //       <div>Edycja</div>
                //     </div>
                //   ),
                // },
                {
                  customRender: (
                    <TopLink to={generatePath(RoutePath.PANEL_PROJECTS_EDIT, { projectId: project.id?.toString() })}>
                      <div className="select-menu__modal__item">
                        <div>Edycja</div>
                      </div>
                    </TopLink>
                  ),
                },
                {
                  label: "Usuń",
                  onAction: () => Confirm("Czy na pewno chcesz usunąć projekt " + project.nazwa + "?", () => callPermDelete(Number(project.id))),
                },
              ]}
            />
          ),
        ]}
      />
      <Pagination
        disableScroll
        totalCount={data?.totalCount}
        itemsPerPage={10}
        take={pagination.take!}
        skip={pagination.skip}
        setNewSkip={(newSkip: number) => setPagination({ ...pagination, skip: newSkip })}
      />
    </>
  );
};

export default ProjectsList;
