import React, { useEffect } from "react";
import LoadingBtn from "../../../../../../../libs/buttons/Button/LoadingBtn";
import Api from "../../../../../../../api/Api";
import { useQuery, useQueryRefetch } from "../../../../../../../api/useQuerySlice";
import { ToastsStore } from "react-toasts";
import { OfficeDto } from "../../../../../../../api/office/office.dto";
import TextBlock from "../../../../../../../libs/text-block/text-block";
interface OfficeEditFormProps {
  data: OfficeDto;
  status: "default" | "loading" | "finished" | "failed";
}

const MassOperations: React.FC<OfficeEditFormProps> = (props) => {
  const { data } = props;
  const { call: call_ToggleLikedAll, status: status_ToggleLikeAll } = useQuery<void, { id: string; like: boolean }>(
    "office/ToggleLikedAll",
    [Api.Office.ToggleLikedAll],
    {
      callOnLoad: false,
    }
  );
  const { call: call_UpdatePriorityAll, status: status_UpdatePriorityAll } = useQuery<void, { id: string; count: number }>(
    "office/UpdatePriorityAll",
    [Api.Office.UpdatePriorityAll],
    {
      callOnLoad: false,
    }
  );

  const { refetch } = useQueryRefetch();

  const toggleLikeAll = (id: number, like: boolean) => {
    call_ToggleLikedAll({ id: id.toString(), like: like });
  };

  const updatePriorityAll = (id: number, count: number) => {
    call_UpdatePriorityAll({ id: id.toString(), count: count });
  };

  useEffect(() => {
    if (status_UpdatePriorityAll === "finished") {
      ToastsStore.success("Dane zostały zmienione");
      refetch("office/get");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status_UpdatePriorityAll]);

  useEffect(() => {
    if (status_ToggleLikeAll === "finished") {
      ToastsStore.success("Dane zostały zmienione");
      refetch("office/get");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status_ToggleLikeAll]);

  return (
    <>
      <div className="d-flex pb-3">
        <h2 className="title mr-auto">Operacje masowe</h2>
      </div>
      <hr />

      <div className="py-3">
        <div className="flex row center-y">
          <TextBlock className="pr-4">
            <h3>Modyfikacja parametru: Lubiany</h3>
            <p>Ustaw wszystkie projekty z tej pracowni jako lubiane</p>
          </TextBlock>
          <LoadingBtn
            style={{ minWidth: 100 }}
            variant="like-input-primary"
            status={status_ToggleLikeAll}
            className="ml-auto"
            invert
            onClick={() => toggleLikeAll(data.id, false)}
          >
            NIE
          </LoadingBtn>
          <LoadingBtn style={{ minWidth: 100 }} status={status_ToggleLikeAll} className="ml-2" onClick={() => toggleLikeAll(data.id, true)}>
            TAK
          </LoadingBtn>
        </div>
      </div>
      <hr />
      <div className="py-3">
        <div className="flex row center-y">
          <TextBlock className="pr-4">
            <h3>Modyfikacja parametru: Priorytet</h3>
            <p>Zmiania priorytet wszystkich projektów z tej pracowni</p>
          </TextBlock>
          <LoadingBtn
            style={{ minWidth: 100 }}
            variant="like-input-primary"
            status={status_UpdatePriorityAll}
            className="ml-auto"
            invert
            onClick={() => updatePriorityAll(data.id, -1)}
          >
            -1
          </LoadingBtn>
          <LoadingBtn style={{ minWidth: 100 }} status={status_UpdatePriorityAll} className="ml-2" onClick={() => updatePriorityAll(data.id, 1)}>
            +1
          </LoadingBtn>
        </div>
      </div>
    </>
  );
};

export default MassOperations;
