import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { ToastsStore } from "react-toasts";
import { AdminChangePasswordDto } from "../../../../../../../../api/user/user.dto";
import { useParams } from "react-router-dom";

interface UserEditPasswordFormProps { }

interface FormDto {
  password: string,
  repeatPassword: string;
}

const UserEditPasswordForm: React.FC<UserEditPasswordFormProps> = (props) => {
  const { id = "" } = useParams<{ id: string }>();
  const { call, status: updateStatus } = useQuery<void, AdminChangePasswordDto>('user/update', [Api.User.AdminChangePassword], { callOnLoad: false });
  const { register, watch, handleSubmit, formState: { errors }, reset } = useForm<FormDto>({ defaultValues: {} });
  const watch_password = watch("password");

  const onSubmit = async (form: FormDto) => {
    call({ id: id, password: form.repeatPassword } as AdminChangePasswordDto);
  };

  useEffect(() => {
    if (updateStatus === 'finished') {
      ToastsStore.success("Hasło zostało zmienione");
      reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus])

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
        <TextInput
          id="password"
          label="Nowa hasło"
          validation_msg={errors?.password?.message}
          inputProps={{
            ...register("password", Validators.password),
            type: "password",
          }}
        />
        <TextInput
          id="repeatPassword"
          label="Powtórz hasło"
          validation_msg={errors?.repeatPassword?.message}
          inputProps={{
            ...register("repeatPassword", Validators.repeatPassword(watch_password)),
            type: "password",
            autoComplete: "new-password"
          }}
        />
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">Zmień</LoadingBtn>
      </Form>
    </>
  );
};

export default UserEditPasswordForm;
