import Client from "../../client";
import { UploadImageDto } from "./blogImage.dto";

class Image {
  static async Delete(id: number): Promise<void> {
    return Client.delete("Image/DeleteImage", id.toString());
  }
  static async AddImage(dto: UploadImageDto): Promise<void> {
    return Client.upload("Image/AddImage", dto);
  }
  static async UpdateImage(dto: UploadImageDto): Promise<void> {
    return Client.upload("Image/UpdateImage", dto);
  }
  static async UploadFile(formData: any): Promise<any> {
    return Client.upload("Image/UploadFile", formData);
  }
}

export default Image;
