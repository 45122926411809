import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { ToastsStore } from "react-toasts";
import { useParams } from "react-router-dom";
import {
  Bud__RodzajZabudowy__Enum,
  Bud__RodzajZabudowy__Enum_Parser,
  Bud__Styl__Enum,
  Bud__Styl__Enum_Parser,
  Bud__Technologia__Enum,
  Bud__Technologia__Enum_Parser,
  Dach__Rodzaj__Enum,
  Dach__Rodzaj__Enum_Parser,
  Garaz__Rodzaj__Enum,
  Garaz__Rodzaj__Enum_Parser,
  Ogrzewanie__Rodzaj__Enum,
  Ogrzewanie__Rodzaj__Enum_Parser,
  ProjectBasicInfoDto,
  SafeProjectDto,
  Wentylacja__Rodzaj__Enum,
  Wentylacja__Rodzaj__Enum_Parser,
  Wnetrze__Rodzaj_Poddasza__Enum,
  Wnetrze__Rodzaj_Poddasza__Enum_Parser,
} from "../../../../../../../../api/project/project.dto";
import { mapEnum } from "../../../../../../../../features/enumMap";
import SelectBox, { SelectBoxOption } from "../../../../../../../../libs/forms/SelectBox/SelectBox";
import { OfficeNameDto } from "../../../../../../../../api/office/office.dto";
import CheckBoxInput from "../../../../../../../../libs/forms/CheckBox/CheckBox";
import InputPolicyController, { frstLettUp } from "../../../../../../../components/InputPolicyController/inputPolicyController";
import { IdNameDto } from "../../../../../../../../api/shared/System.dto";

interface ProjectBasicInfoEditFormProps {}

interface ProjectBasicInfoDtoForm extends Omit<ProjectBasicInfoDto, "nowosc_wybproj"> {
  nowosc_wybproj: boolean;
  isActive: boolean;
  deletedDate: Date;
}

const ProjectBasicInfoEditForm: React.FC<ProjectBasicInfoEditFormProps> = (props) => {
  const { projectId = "" } = useParams<{ projectId: string }>();
  const { data: officesNames = [] } = useQuery<Array<OfficeNameDto>>("office/listNames", [Api.Office.ListNames], { callOnLoad: true });
  const { data: projectCategoriesNames = [] } = useQuery<Array<IdNameDto>>("ProjectCategory/listNames", [Api.ProjectCategory.ListNames], { callOnLoad: true });

  const { call: refetch, data } = useQuery<SafeProjectDto, string>("project/get", [Api.Project.Get, projectId], { statusResetDelay: 1000 });
  const { call, status: updateStatus } = useQuery<void, ProjectBasicInfoDto>("project/updateBasicInfo", [Api.Project.UpdateBasicInfo], { callOnLoad: false });
  const [unverifiedProps, setUnverifiedProps] = useState<Array<string>>([]);
  const [overwritten_params, setOverwritten_params] = useState<Array<string>>([]);
  const [id_fromXml, setId_fromXml] = useState<string>("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ProjectBasicInfoDtoForm>({ defaultValues: {} });

  const onSubmit = async (dto: ProjectBasicInfoDtoForm) => {
    call({
      ...data,
      ...dto,
      dach__powierzch: (dto.dach__powierzch as any) === "" ? (null as any) : dto.dach__powierzch,
      nowosc_wybproj: dto.nowosc_wybproj ? new Date(Date.now()) : undefined,
      unverifiedProps: unverifiedProps,
      overwritten_params: overwritten_params,
    });
  };

  useEffect(() => {
    reset({ ...data, nowosc_wybproj: data?.nowosc_wybproj ? true : false });
    setId_fromXml(data?.id_fromXml ?? "");
    setUnverifiedProps(data?.unverifiedProps ?? []);
    setOverwritten_params(data?.overwritten_params ?? []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Dane zostały zmienione");
      refetch(projectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} cols={[3, 3, 2, 1]} direction={"row"}>
        <div className="form-child form-child-wrapper">
          <h3 className="title form-child">Informacje elementarne</h3>
          <TextInput
            id="id"
            label="Id"
            validation_msg={errors?.id?.message}
            inputProps={{
              ...register("id"),
              disabled: true,
            }}
          />
          <TextInput
            id="orderPriority"
            label="Wartość priorytetu"
            required={true}
            validation_msg={errors?.orderPriority?.message}
            inputProps={{
              ...register("orderPriority", Validators.required),
            }}
          />
          <SelectBox
            id="officeId"
            label="Pracownia"
            validation_msg={errors?.officeId?.message}
            options={
              officesNames.map((x) => {
                return {
                  id: x.id,
                  label: x.name,
                };
              }) as any
            }
            maxHeight
            inputProps={{
              placeholder: "Pracownia",
              ...register("officeId", Validators.required),
              disabled: true,
            }}
          />
          <SelectBox
            id="canonicalCategoryId"
            label="Kategoria kanoniczna"
            validation_msg={errors?.canonicalCategoryId?.message}
            options={projectCategoriesNames.map((x) => {
              return { id: x.id, label: x.name } as SelectBoxOption;
            })}
            maxHeight
            required
            inputProps={{
              placeholder: "Kategoria kanoniczna",
              ...register("canonicalCategoryId", Validators.required),
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Nazwa"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <TextInput
            id="nazwa"
            label="Nazwa"
            required={true}
            validation_msg={errors?.nazwa?.message}
            inputProps={{
              ...register("nazwa", Validators.required),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Nazwa")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Url"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <TextInput
            id="url"
            label="Url"
            required={true}
            validation_msg={errors?.url?.message}
            inputProps={{
              ...register("url", Validators.required),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Url")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Cena__raw_brutto"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <TextInput
            id="cena__raw_brutto"
            label="Cena brutto [zł]"
            inputProps={{
              type: "number",
              step: "0.01",
              ...register("cena__raw_brutto"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Cena__raw_brutto")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Bud__rodz_zabudowy"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <SelectBox
            id="bud__rodz_zabudowy"
            label="Rodzaj zabudowy"
            validation_msg={errors?.bud__rodz_zabudowy?.message}
            options={mapEnum(Bud__RodzajZabudowy__Enum, (x: Bud__RodzajZabudowy__Enum) => {
              return { id: x, label: Bud__RodzajZabudowy__Enum_Parser(x) };
            })}
            required={true}
            maxHeight
            inputProps={{
              placeholder: "Rodzaj zabudowy",
              ...register("bud__rodz_zabudowy", Validators.required),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Bud__rodz_zabudowy")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Bud__styl"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <SelectBox
            id="bud__styl"
            label="Styl"
            validation_msg={errors?.bud__styl?.message}
            options={mapEnum(Bud__Styl__Enum, (x: Bud__Styl__Enum) => {
              return { id: x, label: Bud__Styl__Enum_Parser(x) };
            })}
            maxHeight
            inputProps={{
              placeholder: "Styl",
              ...register("bud__styl"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Bud__styl")) === -1,
            }}
          />
          <h3 className="title form-child pt-3">Informacje dodatkowe</h3>
          {id_fromXml ? (
            <InputPolicyController
              propName="Antresola"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <CheckBoxInput
            id="antresola"
            label="Antresola"
            description="Antresola"
            validation_msg={errors?.antresola?.message}
            inputProps={{
              ...register("antresola"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Antresola")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Energooszczedny"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <CheckBoxInput
            id="energooszczedny"
            label="Energooszczedny"
            description="Energooszczedny"
            validation_msg={errors?.energooszczedny?.message}
            inputProps={{
              ...register("energooszczedny"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Energooszczedny")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Kominek"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <CheckBoxInput
            id="kominek"
            label="Kominek"
            description="Kominek"
            validation_msg={errors?.kominek?.message}
            inputProps={{
              ...register("kominek"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Kominek")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Taras"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <CheckBoxInput
            id="taras"
            label="Taras"
            description="Taras"
            validation_msg={errors?.taras?.message}
            inputProps={{
              ...register("taras"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Taras")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Tani_w_budowie"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <CheckBoxInput
            id="tani_w_budowie"
            label="Tani w budowie"
            description="Tani w budowie"
            validation_msg={errors?.tani_w_budowie?.message}
            inputProps={{
              ...register("tani_w_budowie"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Tani_w_budowie")) === -1,
            }}
          />
          <CheckBoxInput
            id="lubiany_wybproj"
            label="Lubiany"
            description="Lubiany"
            validation_msg={errors?.lubiany_wybproj?.message}
            inputProps={{
              ...register("lubiany_wybproj"),
            }}
          />
          <CheckBoxInput
            id="sauna"
            label="Sauna w środku"
            description="Sauna w środku"
            validation_msg={errors?.sauna?.message}
            inputProps={{
              ...register("sauna"),
            }}
          />
          <CheckBoxInput
            id="nowosc_wybproj"
            label="Nowość"
            description="Nowość"
            validation_msg={errors?.nowosc_wybproj?.message}
            inputProps={{
              ...register("nowosc_wybproj"),
            }}
          />
        </div>
        <div className="form-child form-child-wrapper">
          <h3 className="title form-child">Wymiary budynku</h3>
          {id_fromXml ? (
            <InputPolicyController
              propName="Bud__powierzch_uzytk"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <TextInput
            id="bud__powierzch_uzytk"
            label="Powierzchnia użytkowa [m2]"
            required={true}
            validation_msg={errors?.bud__powierzch_uzytk?.message}
            inputProps={{
              type: "number",
              step: "0.01",
              min: "0",
              ...register("bud__powierzch_uzytk"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Bud__powierzch_uzytk")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Bud__powierzch_zabud"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <TextInput
            id="bud__powierzch_zabud"
            label="Powierzchnia zabudowy [m2]"
            validation_msg={errors?.bud__powierzch_zabud?.message}
            inputProps={{
              type: "number",
              step: "0.01",
              min: "0",
              ...register("bud__powierzch_zabud"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Bud__powierzch_zabud")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Bud__wysokosc"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <TextInput
            id="bud__wysokosc"
            label="Wysokość [m]"
            validation_msg={errors?.bud__wysokosc?.message}
            inputProps={{
              type: "number",
              step: "0.01",
              min: "0",
              ...register("bud__wysokosc"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Bud__wysokosc")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Bud__kubatura"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <TextInput
            id="bud__kubatura"
            label="Kubatura [m³]"
            validation_msg={errors?.bud__kubatura?.message}
            inputProps={{
              type: "number",
              step: "0.01",
              min: "0",
              ...register("bud__kubatura"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Bud__kubatura")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Bud__li_kondygnacji"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <TextInput
            id="bud__li_kondygnacji"
            label="Liczba kondygnacji"
            validation_msg={errors?.bud__li_kondygnacji?.message}
            inputProps={{
              type: "number",
              step: "1",
              min: "0",
              ...register("bud__li_kondygnacji"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Bud__li_kondygnacji")) === -1,
            }}
          />
          <h3 className="title form-child pt-3">Pomieszczenia</h3>
          {id_fromXml ? (
            <InputPolicyController
              propName="Wnetrz__li_pokoi"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <TextInput
            id="wnetrz__li_pokoi"
            label="Liczba pokoi"
            validation_msg={errors?.wnetrz__li_pokoi?.message}
            inputProps={{
              type: "number",
              step: "1",
              min: "0",
              ...register("wnetrz__li_pokoi"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Wnetrz__li_pokoi")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Wnetrz__li_lazienek"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <TextInput
            id="wnetrz__li_lazienek"
            label="Liczba łazienek"
            validation_msg={errors?.wnetrz__li_lazienek?.message}
            inputProps={{
              type: "number",
              step: "1",
              min: "0",
              ...register("wnetrz__li_lazienek"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Wnetrz__li_lazienek")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Garaz__rodz"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <SelectBox
            id="garaz__rodz"
            label="Rodzaj garażu"
            validation_msg={errors?.garaz__rodz?.message}
            options={mapEnum(Garaz__Rodzaj__Enum, (x: Garaz__Rodzaj__Enum) => {
              return { id: x, label: Garaz__Rodzaj__Enum_Parser(x) };
            })}
            maxHeight
            inputProps={{
              placeholder: "Rodzaj garażu",
              ...register("garaz__rodz"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Garaz__rodz")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Garaz__powierzch"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <TextInput
            id="garaz__powierzch"
            label="Powierzchnia garażu [m]"
            validation_msg={errors?.garaz__powierzch?.message}
            inputProps={{
              type: "number",
              step: "0.01",
              min: "0",
              ...register("garaz__powierzch"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Garaz__powierzch")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Wnetrz__piwnica"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <CheckBoxInput
            id="wnetrz__piwnica"
            label="Piwnica"
            description="Piwnica"
            validation_msg={errors?.wnetrz__piwnica?.message}
            inputProps={{
              ...register("wnetrz__piwnica"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Wnetrz__piwnica")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Wnetrz__strych"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <CheckBoxInput
            id="wnetrz__strych"
            label="Strych"
            description="Strych"
            validation_msg={errors?.wnetrz__strych?.message}
            inputProps={{
              ...register("wnetrz__strych"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Wnetrz__strych")) === -1,
            }}
          />
          <h3 className="title form-child pt-3">Informacje techniczne</h3>
          {id_fromXml ? (
            <InputPolicyController
              propName="Bud__technologia"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <SelectBox
            id="bud__technologia"
            label="Technologia konstrukcji"
            validation_msg={errors?.bud__technologia?.message}
            options={mapEnum(Bud__Technologia__Enum, (x: Bud__Technologia__Enum) => {
              return { id: x, label: Bud__Technologia__Enum_Parser(x) };
            })}
            maxHeight
            inputProps={{
              placeholder: "Technologia konstrukcji",
              ...register("bud__technologia"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Bud__technologia")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Rodz_ogrzewania"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <SelectBox
            id="rodz_ogrzewania"
            label="Rodzaj ogrzewania"
            validation_msg={errors?.rodz_ogrzewania?.message}
            options={mapEnum(Ogrzewanie__Rodzaj__Enum, (x: Ogrzewanie__Rodzaj__Enum) => {
              return { id: x, label: Ogrzewanie__Rodzaj__Enum_Parser(x) };
            })}
            maxHeight
            inputProps={{
              placeholder: "Rodzaj ogrzewania",
              ...register("rodz_ogrzewania"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Rodz_ogrzewania")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Rodz_wentylacji"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <SelectBox
            id="rodz_wentylacji"
            label="Rodzaj wentylacji"
            validation_msg={errors?.rodz_wentylacji?.message}
            options={mapEnum(Wentylacja__Rodzaj__Enum, (x: Wentylacja__Rodzaj__Enum) => {
              return { id: x, label: Wentylacja__Rodzaj__Enum_Parser(x) };
            })}
            maxHeight
            inputProps={{
              placeholder: "Rodzaj wentylacji",
              ...register("rodz_wentylacji"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Rodz_wentylacji")) === -1,
            }}
          />
        </div>
        <div className="form-child form-child-wrapper">
          <h3 className="title form-child">Działka</h3>
          {id_fromXml ? (
            <InputPolicyController
              propName="Dzialka__min_dlugosc"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <TextInput
            id="dzialka__min_dlugosc"
            label="Minimalna długość"
            required={true}
            validation_msg={errors?.dzialka__min_dlugosc?.message}
            inputProps={{
              type: "number",
              step: "0.01",
              min: "0",
              ...register("dzialka__min_dlugosc", Validators.required),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Dzialka__min_dlugosc")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Dzialka__min_szerokosc"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <TextInput
            id="dzialka__min_szerokosc"
            label="Minimalna szerokość"
            required={true}
            validation_msg={errors?.dzialka__min_szerokosc?.message}
            inputProps={{
              type: "number",
              step: "0.01",
              min: "0",
              ...register("dzialka__min_szerokosc", Validators.required),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Dzialka__min_szerokosc")) === -1,
            }}
          />
          <h3 className="title form-child pt-3">Dach</h3>
          {id_fromXml ? (
            <InputPolicyController
              propName="Dach__rodz"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <SelectBox
            id="dach__rodz"
            label="Rodzaj dachu"
            validation_msg={errors?.dach__rodz?.message}
            options={mapEnum(Dach__Rodzaj__Enum, (x: Dach__Rodzaj__Enum) => {
              return { id: x, label: Dach__Rodzaj__Enum_Parser(x) };
            })}
            maxHeight
            inputProps={{
              placeholder: "Rodzaj dachu",
              ...register("dach__rodz"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Dach__rodz")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Dach__kat_nachyl"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <TextInput
            id="dach__kat_nachyl"
            label="Kąt nachylenia głównego dachu (&#176;)"
            validation_msg={errors?.dach__kat_nachyl?.message}
            inputProps={{
              type: "number",
              step: "0.01",
              min: "0",
              ...register("dach__kat_nachyl"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Dach__kat_nachyl")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Dach__powierzch"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <TextInput
            id="dach__powierzch"
            label="Powierzchnia dachu"
            validation_msg={errors?.dach__powierzch?.message}
            inputProps={{
              type: "number",
              step: "0.01",
              min: "0",
              ...register("dach__powierzch"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Dach__powierzch")) === -1,
            }}
          />
          {id_fromXml ? (
            <InputPolicyController
              propName="Wnetrz__rodz_poddasza"
              setOverwritten_params={setOverwritten_params}
              setUnverifiedProps={setUnverifiedProps}
              overwritten_params={overwritten_params}
              unverifiedProps={unverifiedProps}
            />
          ) : null}
          <SelectBox
            id="wnetrz__rodz_poddasza"
            label="Rodzaj poddasza"
            validation_msg={errors?.wnetrz__rodz_poddasza?.message}
            options={mapEnum(Wnetrze__Rodzaj_Poddasza__Enum, (x: Wnetrze__Rodzaj_Poddasza__Enum) => {
              return { id: x, label: Wnetrze__Rodzaj_Poddasza__Enum_Parser(x) };
            })}
            maxHeight
            inputProps={{
              placeholder: "Rodzaj poddasza",
              ...register("wnetrz__rodz_poddasza"),
              disabled: overwritten_params.findIndex((x) => x === frstLettUp("Wnetrz__rodz_poddasza")) === -1,
            }}
          />
        </div>
        <br />
        <div className="ml-auto">
          <LoadingBtn type="submit" status={updateStatus}>
            Zapisz
          </LoadingBtn>
        </div>
      </Form>
    </>
  );
};

export default ProjectBasicInfoEditForm;
