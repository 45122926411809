import React, { useEffect } from "react";
import { generatePath, useParams } from "react-router-dom";
import Api from "../../../../../../../api/Api";
import { PromotionDto, PromotionValueType_Enum } from "../../../../../../../api/office/office.dto";
import { OrderDto } from "../../../../../../../api/order/order.dto";
import { useQuery } from "../../../../../../../api/useQuerySlice";
import GenericList from "../../../../../../../libs/generic-list/generic-list";
import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import LoadingSpinner from "../../../../../../components/LoadingSpinner/LoadingSpinner";
import Tile from "../../../../../../components/Tile/Tile";
import NotFoundPage from "../../not-found-page";
import BasicInfoForm from "./order-manage-form/BasicInfoForm";
import CustomerForm from "./order-manage-form/CustomerForm";
import NotepadManageForm from "./order-manage-form/NotepadManageForm";
import OrderCopy from "./order-manage-form/OrderCopy";
import ProjectInfoForm from "./order-manage-form/ProjectInfoForm";
import StatusManageForm from "./order-manage-form/StatusManageForm";

const OrdersManagePage: React.FC = () => {
  const { orderId = "" } = useParams<{ orderId: string }>();
  const { data, status } = useQuery<OrderDto, string>("order/get", [Api.Order.Get, orderId], { callOnLoad: true });

  useEffect(() => {
    console.log(data);
  }, [data])
  
  return !(orderId && !Number.isNaN(orderId) && Number(orderId) > 0) ? (
    <NotFoundPage />
  ) : (
    <div>
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_ORDERS_LIST)} title="Zamówienia" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_ORDERS_MANAGE, { orderId: orderId })} title="Zarządzanie" isNotLink/>
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Szczegóły zamówienia - {orderId}</h1>
            </Tile>
          </Col>
        </PanelRow>
        {status === "loading" ? (
          <div className="text-center">
            <LoadingSpinner active={status === "loading"} />
          </div>
        ) : null}
        {data ? (
          <PanelRow>
            <Col size={6}>
              <Tile>
                <h2 className="title">Informacje podstawowe</h2>
                <hr />
                <BasicInfoForm order={data} />
              </Tile>
              <br />
              <Tile>
                <h2 className="title">Informacje o projekcie</h2>
                <hr />
                <ProjectInfoForm order={data} />
              </Tile>
              <br />
              <Tile>
                <h2 className="title">Lista dodatków</h2>
                <hr />
                <GenericList
                  status={status}
                  data={data?.addonsCopy}
                  cols={[
                    ["Id", "id", 2],
                    ["Nazwa", "name", 4],
                    ["Cena brutto", "cena__raw_brutto", 3, (x) => (x ? x.toFixed(2) + "zł" : "-")],
                    ["Promocja", "cena__promotion_brutto", 3, (x) => (x ? x.toFixed(2) + "zł" : "-")],
                  ]}
                />
              </Tile>
              <br />
              <Tile>
                <h2 className="title">Lista aktywnych promocji w chwili zamówienia</h2>
                <hr />
                <GenericList
                  status={status}
                  data={data?.promotionsCopy}
                  cols={[
                    ["Id", "id", 2],
                    ["Nazwa", "name", 4],
                    ["Opis", "description", 3],
                    ["Zmiana ceny", "", 3, ((x: PromotionDto)=> <>{x.value}{x.valueType === PromotionValueType_Enum.Percent ? "%":"zł"}</>)]
                  ]}
                />
              </Tile>
            </Col>
            <Col size={6}>
              <Tile>
                <h2 className="title">Zarządzanie statusem</h2>
                <hr />
                <StatusManageForm order={data} />
              </Tile>
              <br />
              <Tile>
                <h2 className="title">Notatka</h2>
                <hr />
                <NotepadManageForm order={data} />
              </Tile>
              <br />
              <Tile>
                <h2 className="title">Dane klienta</h2>
                <hr />
                <CustomerForm />
              </Tile>
            </Col>
            <Col size={12}>
              <Tile>
                <h2 className="title">Kopia obiektu zamówienia</h2>
                <hr />
                <OrderCopy order={data} />
              </Tile>
            </Col>
          </PanelRow>
        ) : null}
      </PanelGrid>
    </div>
  );
};

export default OrdersManagePage;
